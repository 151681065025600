import { useStateStorage, StorageKeys } from '@reward-platform/utils'
import { useCallback } from 'react'
import * as uuid from 'uuid'
import { z } from 'zod'

type CorrelationIdHook = () => {
  correlationId: string | undefined
  resetCorrelationId: () => void
  removeCorrelationId: () => void
}

const useCorrelationId: CorrelationIdHook = () => {
  const {
    storage: correlationId,
    setStorage: setCorrelationId,
    removeStorage: removeCorrelationId,
  } = useStateStorage<string>({
    key: StorageKeys.CORRELATION_ID,
    parser: (value) => {
      const res = z.string().uuid().safeParse(value)
      if (process.env.NODE_ENV === 'development' && !res.success) {
        // eslint-disable-next-line no-console
        console.error(`Failed to parse ${value} as UUID`)
      }
      return res.success ? res.data : undefined
    },
  })

  const resetCorrelationId = useCallback(() => {
    setCorrelationId(uuid.v4())
  }, [setCorrelationId])

  return {
    correlationId,
    resetCorrelationId,
    removeCorrelationId,
  }
}
export default useCorrelationId
