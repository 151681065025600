import { Partner } from '~/services/partnerService'

export const getPartnerLogoUrl = (partner: Partner) => {
  const partnerName = partner.name.toLocaleLowerCase().replace(' ', '-')
  const filename = `${partnerName}-logo.svg`
  return `/assets/${filename}`
}

export const getPartnerIconUrl = (partner?: Partner) =>
  partner ? `/assets/favicon/${partner.slug}.svg` : undefined
