import { ComponentMultiStyleConfig } from '@chakra-ui/react'

export const Switch: ComponentMultiStyleConfig = {
  parts: ['switchButton', 'switchOffValue', 'switchOnValue', 'switchIcon'],
  baseStyle: {
    switchButton: {
      display: 'grid',
      gap: '2',
      gridTemplateColumns: '1fr auto 1fr',
      alignItems: 'center',
      border: 'none',
      bgColor: 'transparent',
      borderRadius: '1',
      transition: 'transparent 0.25s ease',
      cursor: 'pointer',
      padding: 1,
    },
    switchOffValue: {
      whiteSpace: 'nowrap',
    },
    switchOnValue: {
      whiteSpace: 'nowrap',
    },
    switchIcon: {
      position: 'relative',
      width: '8',
      height: '4',
      overflow: 'hidden',
      borderRadius: '40',
      _before: {
        content: '""',
        position: 'absolute',
        inset: 0,
        transition: '0.25s ease',
      },
      _after: {
        content: '""',
        position: 'absolute',
        top: '0.5',
        left: '0.5',
        height: '3',
        width: '3',
        borderRadius: '50%',
        bgColor: 'white',
        transition: '0.25s white ease, 0.25s transform ease',
      },
    },
  },
  variants: {
    isActive: {
      switchIcon: {
        _before: { transform: 'translateX(0)' },
        _after: { transform: 'translateX(1rem)' },
      },
    },
    notActive: {
      switchIcon: {
        _before: { transform: 'translateX(-100%)' },
        _after: { transform: 'translateX(0)' },
      },
    },
  },
}
