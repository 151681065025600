import z from 'zod'
import { PackageRulesSchema } from '@reward-platform/shared-schemas'
import { CarHirePolicySchema, MonetaryAmountSchema } from '../common'
import { OrderItemTypeSchema } from './order'

export const ReshopOrderParamsSchema = z.object({
  id: z.string(),
  bundleRefs: z.array(z.string()).min(0),
})

export const ReshopTotalSchema = z.object({
  cash: MonetaryAmountSchema.optional(),
  avios: z.number().optional(),
})
export type ReshopTotal = z.infer<typeof ReshopTotalSchema>

export const ReshopBreakdownSchema = ReshopTotalSchema.extend({
  cancellationFee: MonetaryAmountSchema.optional(),
})

export type ReshopBreakdown = z.infer<typeof ReshopBreakdownSchema>

const BaseReshopDifferentialItemSchema = z.object({
  refundPrice: ReshopTotalSchema.optional(),
  oldPrice: ReshopTotalSchema.extend({ priceId: z.string() }).optional(),
  bundleRefs: z.array(z.string()),
  cancellationCharge: MonetaryAmountSchema.optional(),
})

export const HotelReshopDifferentialItemSchema = BaseReshopDifferentialItemSchema.extend({
  type: z.literal(OrderItemTypeSchema.Enum.HOTEL),
  packageRules: PackageRulesSchema.optional(),
})

export const CarHireReshopDifferentialItemSchema = BaseReshopDifferentialItemSchema.extend({
  type: z.literal(OrderItemTypeSchema.Enum.CAR_HIRE),
  locationPolicy: CarHirePolicySchema,
  rentalPolicy: CarHirePolicySchema,
})

export const FlightReshopDifferentialItemSchema = BaseReshopDifferentialItemSchema.extend({
  type: z.literal(OrderItemTypeSchema.Enum.FLIGHT),
})

export const ExperienceReshopDifferentialItemSchema = BaseReshopDifferentialItemSchema.extend({
  type: z.literal(OrderItemTypeSchema.Enum.EXPERIENCE),
})

const ReshopDifferentialItemSchema = z.discriminatedUnion('type', [
  HotelReshopDifferentialItemSchema,
  CarHireReshopDifferentialItemSchema,
  FlightReshopDifferentialItemSchema,
  ExperienceReshopDifferentialItemSchema,
])

export const ReshopOrderSchema = z.object({
  recommendationId: z.string(),
  bundleId: z.string(),
  total: ReshopTotalSchema,
  differential: z.array(ReshopDifferentialItemSchema),
})
export type ReshopOrder = z.infer<typeof ReshopOrderSchema>
