import countriesJSON from './countries/countries.json'
import usStatesJSON from './statesOrProvinces/US.json'
import caProvincesJSON from './statesOrProvinces/CA.json'

/**
 * These types live here due to a circular dependency between the utils and order-schemas libs, we
 * might want to refactor this some time.
 */
export type Country = { label: string; value: string }
export type Countries = Country[]

export type StateOrProvince = { label: string; value: string }
export type StatesOrProvinces = StateOrProvince[]
export type StateOrProvinceMap = Record<Country['value'], StatesOrProvinces>

export const countries: Countries = countriesJSON

export const stateOrProvinceMap: StateOrProvinceMap = {
  US: usStatesJSON,
  CA: caProvincesJSON,
}

export * from './forms/validation'
export * from './stateStorage/useStateStorage'
export * from './stateStorage/storageKeys'
export * from './isClientSide/isClientSide'
export * from './flights/flights'
export * from './exceptions/ErrorCodes'
