import { helpers } from './base'

export const PricingSlider = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    container: {},
    sliderFilledTrack: {
      bg: 'brand.primary.blue',
    },
  }),
})
