import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'accordion',
  'bulletPoint',
  'button',
  'icon',
  'title',
  'infoTitle',
  'infoText',
  'contentTitle',
  'contentBody',
])

export const RouteInfo = helpers.defineMultiStyleConfig({
  baseStyle: {
    bulletPoint: {
      color: 'black',
      margin: 'auto 0',
      marginRight: '0.5rem',
    },
    button: {
      whiteSpace: 'nowrap',
      pt: '45px',
      px: 0,
      pb: '45px',
      _expanded: {
        pb: '20px',
      },
    },
    icon: {
      fontSize: '38px',
    },
    title: {
      px: '8px',
      py: '10px',
      bg: 'black',
      color: 'white',
      borderRadius: '4px',
      fontWeight: '400',
      width: 'fit-content',
      fontSize: { base: '1rem', xl: '1.125rem' },
    },
    infoTitle: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 1,
      fontWeight: 500,
      fontSize: { base: '1rem', xl: '1.125rem' },
    },
    infoText: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 1,
      fontWeight: 400,
      ml: '5px',
    },
    contentTitle: {
      fontSize: { base: '1.25rem', md: '1.375rem' },
    },
    contentBody: {
      py: '26px',
      lineHeight: 1.5,
      fontSize: { base: '1rem', md: '1.125rem' },
    },
  },
})
