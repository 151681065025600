import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'travelerQuantityContainer',
  'maxNumberOfTravelers',
  'comboBoxContainer',
  'isVisible',
  'notVisible',
])

const listBoxDisplayCount = 6

export const TravelerQuantityPickerComboBox = helpers.defineMultiStyleConfig({
  baseStyle: {
    travelerQuantityContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      '> *': {
        maxWidth: '21.875rem',
      },
    },
    maxNumberOfTravelers: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 'base',
      width: 'full',
      fontWeight: 'normal',
    },
    comboBoxContainer: {
      mt: 1.5,
      padding: 2.5,
      gridArea: 'combobox',
      backgroundColor: 'white',
      zIndex: 1,
      width: 'full',
      borderRadius: {
        base: 0,
        md: '5px',
      },
      position: 'absolute',
      minHeight: 'min-content',
      maxHeight: {
        base: 'calc(100vh - 150px)',
        md: `calc(3.5rem * ${listBoxDisplayCount})`,
      },
      overflow: 'auto',
      boxShadow: {
        base: 'unset',
        md: '0 6px 9px 0 rgba(0, 0, 0, 0.1)',
      },
    },
    isVisible: {
      visibility: 'visible',
      opacity: 1,
      transform: 'translateY(0)',
      transition: '0.25s opacity ease, 0.25s visibility step-start, 0.25s transform ease',
    },
    notVisible: {
      visibility: 'hidden',
      opacity: 0,
      transform: 'translateY(-10px)',
      transition: '0.25s opacity ease, 0.25s visibility step-end, 0.25s transform ease',
    },
  },
})
