import { formHelper, inputPaddingBottom, inputPaddingTop } from './base'

const midnightBlue = 'brand.primary.midnightBlue'

const hoverInputStyles = {
  borderBottom: 'var(--chakra-borders-1px)',
  borderColor: 'brand.highlight.blue',
  color: midnightBlue,
}
const focusInputStyles = {
  boxShadow: '0px 1.5px 0px 0px var(--chakra-colors-brand-highlight-blue) !important',
}

const baseGray100 = 'brand.baseGray.100'

const alertPink = 'brand.alertPink'

const errorStyle = {
  color: alertPink,
}

export const Form = formHelper.defineMultiStyleConfig({
  variants: {
    floating: {
      container: {
        _focusWithin: {
          label: {
            color: midnightBlue,
          },
        },
        '.flex-microform-focused ~ label': {
          color: midnightBlue,
        },
        label: {
          color: baseGray100,
          span: {
            color: baseGray100,
          },
          paddingLeft: 0,
          paddingRight: 0,
        },
        button: {
          _focusVisible: {
            outline: 'none',
          },
        },
        'input, select, textarea, .flex-microform, button': {
          paddingLeft: 0,
          paddingRight: 0,
          border: 'none',
          borderBottom: 'var(--chakra-borders-1px)',
          borderColor: baseGray100,
          borderRadius: 0,
          color: midnightBlue,
          _invalid: {
            boxShadow: 'none',
            borderColor: alertPink,
            '~label': {
              ...errorStyle,
            },
            '~ div svg': {
              ...errorStyle,
            },
            _focus: {
              borderColor: baseGray100,
              '~label[data-invalid]': {
                ...errorStyle,
              },
            },
          },
          _hover: {
            ...hoverInputStyles,
          },
          _focus: {
            border: 'none',
            ...hoverInputStyles,
            ...focusInputStyles,
          },
          '&.flex-microform-focused': {
            ...hoverInputStyles,
            ...focusInputStyles,
          },
          _disabled: {
            opacity: 1, // chakra makes 0.4 opaque when disabled
            borderColor: 'rgba(var(--chakra-colors-rgb-gray100), 0.75)',
            color: 'rgba(var(--chakra-colors-rgb-midnightBlue), 0.75)',
            '~label': {
              color: 'rgba(var(--chakra-colors-rgb-gray100), 0.8)',
            },
          },
        },
        'label[data-invalid], label[data-invalid] span, .flex-microform-invalid ~ label, .flex-microform-field-invalid ~ label, .flex-microform-field-invalid ~ label span':
          { ...errorStyle },
      },
      icon: {
        paddingTop: inputPaddingTop,
        paddingBottom: inputPaddingBottom,
        alignItems: 'baseline',
      },
      comboBox: {
        'div > svg': {
          color: 'brand.primary.midnightBlue',
        },
      },
      errorDot: {
        display: 'inline-block',
        position: 'absolute',
        backgroundColor: alertPink,
        borderRadius: 'lg',
        width: '6px',
        height: '6px',
        top: '38px',
        left: -3,
      },
    },
  },
})
