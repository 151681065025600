import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'container',
  'optionIndicator',
  'soldOutOptionIndicator',
  'optionInfoContainer',
  'priceContainer',
  'inlineText',
  'boldText',
  'semiboldText',
  'optionContainerSelected',
  'optionContainerUnselected',
  'optionInfoBox',
  'infoButton',
  'hidableIcon',
])
const optionContainer = {
  display: 'grid',
  gridTemplate: {
    base: `
        'optionInfo optionIndicator' 1fr
        'prices optionIndicator' auto / 1fr 62px`,
    sm: `'optionInfo prices optionIndicator' auto / 1.2fr 0.8fr 62px`,
  },
  width: 'full',
  borderRadius: '5px',
  marginBottom: 4,
  padding: 4,
  cursor: 'pointer',
  border: '1px solid',
  _disabled: {
    cursor: 'unset',
  },
}

export const RoomRateSelector = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      border: 0,
      p: 0,
      m: 0,
      minWidth: 0,
    },
    optionIndicator: {
      gridArea: 'optionIndicator',
      textAlign: 'center',
      display: 'grid',
      alignItems: 'center',
      alignSelf: 'stretch',
      m: '-1rem -1rem -1rem 0',
    },
    soldOutOptionIndicator: {
      gridArea: 'optionIndicator',
      textAlign: 'center',
      display: 'grid',
      alignItems: 'center',
      alignSelf: 'stretch',
      m: '-1rem -1rem -1rem 0',
    },
    optionInfoContainer: {
      display: { base: '', sm: 'grid' },
      gridArea: 'optionInfo',
      paddingBottom: { base: 2, sm: 0 },
      paddingRight: 8,
    },
    priceContainer: {
      display: 'grid',
      gridArea: 'prices',
      gridTemplate: {
        base: `
               'aviosprice or price' minmax(min-content, max-content)
               'aviospricelabel . priceLabel' minmax(min-content, max-content) / auto auto auto`,
        md: `'aviosprice or price' auto
                'aviospricelabel . priceLabel' auto / minmax(min-content, max-content) minmax(
                  min-content,
                  max-content
                )
                auto;`,
      },
      paddingRight: 6,
      gridColumnGap: 4,
      justifyContent: { base: 'end', md: 'unset' },
      alignContent: 'center',
      lineHeight: 5,
      fontSize: 'sm',
    },
    boldText: {
      fontWeight: 'semibold',
    },
    semiboldText: {
      fontWeight: 'medium',
    },
    optionContainerSelected: {
      ...optionContainer,
    },
    optionContainerUnselected: {
      ...optionContainer,
    },
    optionInfoBox: {
      alignSelf: 'center',
      paddingBottom: 1,
    },
    infoButton: {
      border: 0,
      padding: 0,
      cursor: 'pointer',
      backgroundColor: 'unset',
      marginLeft: 1,
    },
    hidableIcon: {
      display: { base: 'none', sm: 'unset' },
      visibility: { base: 'hidden', sm: 'visible' },
      width: { base: '1px', sm: 'unset' },
      marginRight: { base: '', sm: 4 },
    },
  },
})
