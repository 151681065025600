import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'container',
  'description',
  'form',
  'destination',
  'directionColumn',
  'directionRow',
  'spinner',
])

export const SearchExperienceForm = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      display: 'grid',
      gap: 4,
      gridTemplate: {
        base: `'destination' 'dates' 'search'`,
        md: `'destination dates dates search'`,
      },
      gridTemplateColumns: { base: '1fr', md: `'1fr 1fr 1fr'` },
      gridTemplateRows: 'auto',
    },
    destination: {
      borderRadius: 'lg',
      boxShadow: { base: 'none', md: '0 6px 9px 0 rgba(0, 0, 0, 0.1)' },
      padding: '6',
      color: 'rgba(99, 102, 106, 1)',
      cursor: 'default',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: 'full',
    },
    directionRow: {
      flexDirection: {
        base: 'unset',
        md: 'row',
      },

      '> *:nth-child(1), > *:nth-child(3)': {
        flex: {
          base: 'unset',
          md: 1,
        },
      },

      '> *:nth-child(2)': {
        flex: {
          base: 'unset',
          md: 2,
        },
      },
    },
    directionColumn: {
      flexDirection: 'column',
    },
  },
})
