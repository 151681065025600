import { css } from '@emotion/react'

export const aerLingusFontFaceStr = `
@font-face {
  font-family: 'Diodrum';
  src: local('Diodrum Regular'), local('Diodrum-Regular'),
    url('/fonts/diodrum/Diodrum-Regular.woff2') format('woff2'),
    url('/fonts/diodrum/Diodrum-Regular.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Diodrum';
  src: local('Diodrum Medium'), local('Diodrum-Medium'),
    url('/fonts/diodrum/Diodrum-Medium.woff2') format('woff2'),
    url('/fonts/diodrum/Diodrum-Medium.woff') format('woff');
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Diodrum';
  src: local('Diodrum Semibold'), local('Diodrum-Semibold'),
    url('/fonts/diodrum/Diodrum-Semibold.woff2') format('woff2'),
    url('/fonts//diodrum/Diodrum-Semibold.woff') format('woff');
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Diodrum Light';
  src: local('Diodrum Light'), local('Diodrum-Light'),
    url('/fonts/diodrum/Diodrum-Light.woff2') format('woff2'),
    url('/fonts//diodrum/Diodrum-Light.woff') format('woff');
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Nunito Sans';
  src: local('nunito-sans-regular'), local(''),
    url('/fonts/nunito-sans/nunito-sans-regular.woff2') format('woff2'),
    url('/fonts/nunito-sans/nunito-sans-regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: local('nunito-sans-600'), local(''),
    url('/fonts/nunito-sans/nunito-sans-600.woff2') format('woff2'),
    url('/fonts/nunito-sans/nunito-sans-600.woff') format('woff');
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: local(''), local(''), url('/fonts/nunito-sans/nunito-sans-700.woff2') format('woff2'),
    url('/fonts/nunito-sans/nunito-sans-700.woff') format('woff');
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: local(''), local(''), url('/fonts/nunito-sans/nunito-sans-800') format('woff2'),
    url('/fonts/nunito-sans/nunito-sans-800') format('woff');
  font-style: normal;
  font-weight: 800;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: local('nunito-sans-300'), local(''),
    url('/fonts/nunito-sans/nunito-sans-300.woff2') format('woff2'),
    url('/fonts/nunito-sans/nunito-sans-300.woff') format('woff');
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  font-display: swap;
}
`

const fonts = css`
  ${aerLingusFontFaceStr}
`

export default fonts
