import { z } from 'zod'
import { PackageRulesSchema } from '@reward-platform/shared-schemas'
import { isEmpty, isObject } from 'lodash'
import {
  AddressSchema,
  CarHireItemType,
  CarHireLocationSchema,
  DateSchema,
  ExperienceItemType,
  HotelItemType,
  FlightItemType,
  MonetaryAmountSchema,
  RoomSchema,
  VehicleSchema,
  OrderLineStatusSchema,
  FlightDetailsSchema,
  ISODateStringSchema,
  TaxDetailsSchema,
  CarHirePolicySchema,
  ExperienceCancellationPolicySchema,
  ExperienceDurationSchema,
  ExperienceLocationSchema,
  TravelerGroupSchema,
} from '../common'
import {
  createExtendedPaymentInformationSchema,
  CreatePaymentInformationFormSchemaParams,
  createAdditionalDetailsSchema,
  AdditionalDetailsParams,
  createSelectedPaymentOptionsSchema,
  CreateSelectedPaymentOptionsSchemaParams,
  safeCreateTravelerInformationSchemaByBasket,
} from '../form-data'

type CreateOrderSchemaParams = CreatePaymentInformationFormSchemaParams &
  AdditionalDetailsParams &
  CreateSelectedPaymentOptionsSchemaParams

// TODO: Add validation logic to check that travelers in basket match with the ones
// in traveler and hotel information etc.
export const createOrderSchema = (params: CreateOrderSchemaParams) => {
  const travelerInformationSchema = safeCreateTravelerInformationSchemaByBasket(params)

  return z.object({
    travelerInformation: travelerInformationSchema ?? z.undefined().optional(),
    paymentInformation: createExtendedPaymentInformationSchema(params).optional(),
    additionalDetails: createAdditionalDetailsSchema(params),
    selectedPaymentOptions: createSelectedPaymentOptionsSchema(params),
  })
}

export type CreateOrder = z.infer<ReturnType<typeof createOrderSchema>>

export const RetrieveOrderIdSchema = z.object({
  id: z.string(),
})

export const OrderStatusSchema = z.enum([
  'OPENED',
  'CONFIRMED',
  'HELD',
  'FAILED',
  'CANCELLED',
  'CLOSED',
  'SUSPENDED',
])

export const SecureInstructionSchema = z.object({
  jwt: z.string(),
  redirectUrl: z.string(),
  terminationUrl: z.string(),
})

export const PaymentStatusCodeSchema = z.enum([
  'INITIALIZED',
  'SUSPENDED',
  'AUTHORIZED',
  'REFUNDED',
  'FAILED',
  'CLOSED',
])
export type PaymentStatusCode = z.infer<typeof PaymentStatusCodeSchema>

export const PaymentTypeCode = z.enum(['RW', 'CC'])

export const OrderItemTypeSchema = z.enum(['HOTEL', 'EXPERIENCE', 'CAR_HIRE', 'FLIGHT'])
export type OrderItemType = z.infer<typeof OrderItemTypeSchema>

export const OrderSurchargesBreakdown = z.object({
  feeCode: z.string().optional(),
  feeName: z.string().optional(),
  amount: MonetaryAmountSchema,
})

export const OrderSurchargesBreakdownAndTotalSchema = z.object({
  total: MonetaryAmountSchema,
  breakdown: z.array(OrderSurchargesBreakdown),
})

export type OrderSurchargesBreakdownAndTotal = z.infer<
  typeof OrderSurchargesBreakdownAndTotalSchema
>

export const OrderPriceBreakdownSchema = z.object({
  cashSpent: MonetaryAmountSchema.optional(),
  aviosSpent: z.number().optional(),
  aviosCollect: z.number().optional(),
  taxes: MonetaryAmountSchema.optional(),
  taxDetails: z.array(TaxDetailsSchema).optional(),
  isSalesTax: z.boolean(),
  surcharges: OrderSurchargesBreakdownAndTotalSchema.optional(),
  totalCashIncludingTax: MonetaryAmountSchema.optional(),
})

export type OrderPriceBreakdown = z.infer<typeof OrderPriceBreakdownSchema>

export const BaseOrderItemSchema = z.object({
  id: z.string().min(1),
  type: OrderItemTypeSchema,
  status: OrderLineStatusSchema.optional(),
  bundleIds: z.string().array(),
  orderPriceBreakdown: OrderPriceBreakdownSchema.optional(),
})

export type BaseOrderItem = z.infer<typeof BaseOrderItemSchema>

export const HotelOrderItemSchema = BaseOrderItemSchema.extend({
  type: z.literal(OrderItemTypeSchema.Enum.HOTEL),
  name: z.string().min(1),
  room: RoomSchema,
  address: AddressSchema,
  startDate: ISODateStringSchema,
  endDate: ISODateStringSchema,
  packageRules: PackageRulesSchema,
})

export type HotelOrderItem = z.infer<typeof HotelOrderItemSchema>

const CarHireExtraSchema = z.object({
  serviceRef: z.string(),
  serviceSubType: z.string(),
  name: z.string(),
  serviceCategory: z.string().optional(),
  description: z.string().optional(),
  selectedQuantity: z.number(),
  maxAllowedQuantity: z.number(),
})

export type CarHireExtra = z.infer<typeof CarHireExtraSchema>

export const CarHireOrderItemSchema = BaseOrderItemSchema.extend({
  type: z.literal(OrderItemTypeSchema.Enum.CAR_HIRE),
  vehicle: VehicleSchema,
  pickUpLocation: CarHireLocationSchema,
  dropOffLocation: CarHireLocationSchema,
  extras: z.array(CarHireExtraSchema),
  startDate: ISODateStringSchema,
  endDate: ISODateStringSchema,
  locationPolicy: CarHirePolicySchema,
  rentalPolicy: CarHirePolicySchema,
})
export type CarHireOrderItem = z.infer<typeof CarHireOrderItemSchema>

export const ExperienceOrderItemSchema = BaseOrderItemSchema.extend({
  type: z.literal(OrderItemTypeSchema.Enum.EXPERIENCE),
  name: z.string().min(1),
  travelers: TravelerGroupSchema.array(),
  startLocations: ExperienceLocationSchema.array().optional(),
  days: z.number().optional(),
  experienceType: z.string().optional(),
  endLocations: ExperienceLocationSchema.array().optional(),
  duration: ExperienceDurationSchema.optional(),
  status: OrderLineStatusSchema.optional(),
  additionalInfo: z.string().array().optional(),
  cancellationPolicy: ExperienceCancellationPolicySchema,
  startDate: ISODateStringSchema.optional(),
  startTime: z.string().optional(),
})

export type ExperienceOrderItem = z.infer<typeof ExperienceOrderItemSchema>

export const FlightOrderItemSchema = BaseOrderItemSchema.extend({
  type: z.literal(OrderItemTypeSchema.Enum.FLIGHT),
  inboundFlightDetails: FlightDetailsSchema.optional(),
  outboundFlightDetails: FlightDetailsSchema,
})
export type FlightOrderItem = z.infer<typeof FlightOrderItemSchema>

export const OrderItemSchema = z.union([
  HotelOrderItemSchema,
  ExperienceOrderItemSchema,
  CarHireOrderItemSchema,
  FlightOrderItemSchema,
])

export type OrderItem = z.infer<typeof OrderItemSchema>

export const OrderItemsSchema = z.object({
  [HotelItemType]: z.array(HotelOrderItemSchema),
  [ExperienceItemType]: z.array(ExperienceOrderItemSchema),
  [CarHireItemType]: z.array(CarHireOrderItemSchema),
  [FlightItemType]: z.array(FlightOrderItemSchema),
})

export type OrderItems = z.infer<typeof OrderItemsSchema>

export const OrderPaymentBaseSchema = z.object({
  paymentId: z.string().min(1),
  statusCode: PaymentStatusCodeSchema.optional(),
})
export type OrderPaymentBase = z.infer<typeof OrderPaymentBaseSchema>

export const OrderPaymentCCSchema = OrderPaymentBaseSchema.extend({
  typeCode: z.literal(PaymentTypeCode.enum.CC),
  secureInstruction: z.preprocess(
    (val) => (isObject(val) && isEmpty(val) ? undefined : val),
    SecureInstructionSchema.optional()
  ),
  stepUpWindowDimensions: z.string().optional(),
})
export type OrderPaymentCC = z.infer<typeof OrderPaymentCCSchema>

export const OrderPaymentRWSchema = OrderPaymentBaseSchema.extend({
  typeCode: z.literal(PaymentTypeCode.enum.RW),
})
export type OrderPaymentRW = z.infer<typeof OrderPaymentRWSchema>

export const OrderPaymentSchema = z.discriminatedUnion('typeCode', [
  OrderPaymentCCSchema,
  OrderPaymentRWSchema,
])
export type OrderPayment = z.infer<typeof OrderPaymentSchema>

export const OrderSchema = z.object({
  correlationId: z.string(),
  orderId: z.string(),
  status: OrderStatusSchema.optional(),
  creationDate: DateSchema,
  payments: z.array(OrderPaymentSchema),
  items: OrderItemsSchema.optional(),
  totalCashSpent: MonetaryAmountSchema.optional(),
  totalAviosSpent: z.number().optional(),
  totalAviosCollected: z.number().optional(),
  isAtolEligible: z.boolean().optional(),
})

export type Order = z.infer<typeof OrderSchema>

export const CanceledOrderSchema = OrderSchema.extend({
  originPaymentId: z.string().optional(),
})
export type CanceledOrder = z.infer<typeof CanceledOrderSchema>
