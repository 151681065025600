import { Global, css } from '@emotion/react'
import { reset } from './reset/reset.css'
import { base } from './base/base.css'
import fonts from './fonts/fonts.css'

const GlobalStyles = () => (
  <Global
    styles={css`
      ${fonts}
      ${reset}
      ${base}
    `}
  />
)

export default GlobalStyles
