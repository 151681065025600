/* eslint-disable dot-notation */
import { z } from 'zod'

const Auth0PartnerCodeSchema = z.union([z.literal('EI'), z.literal('BA'), z.literal('VY')])
export type Auth0PartnerCode = z.infer<typeof Auth0PartnerCodeSchema>

const Auth0PartnerConfigSchema = z.object({
  domain: z.string().optional(),
  clientId: z.string().optional(),
  audience: z.string().optional(),
  scope: z.string().optional(),
  cacheLocation: z.string().optional(),
})
type Auth0PartnerConfig = z.infer<typeof Auth0PartnerConfigSchema>

export const partnerAuthConfigs: Record<Auth0PartnerCode, Auth0PartnerConfig> = {
  VY: { domain: '', clientId: '', audience: '', scope: '', cacheLocation: '' },
  EI: {
    domain: process.env['NEXT_PUBLIC_AUTH0_EI_DOMAIN'],
    clientId: process.env['NEXT_PUBLIC_AUTH0_EI_CLIENT_ID'],
    audience: process.env['NEXT_PUBLIC_AUTH0_EI_AUDIENCE'],
    scope: process.env['NEXT_PUBLIC_AUTH0_EI_SCOPE'],
    cacheLocation: process.env['NEXT_PUBLIC_AUTH0_EI_CACHE_LOCATION'],
  },
  BA: {
    domain: process.env['NEXT_PUBLIC_AUTH0_BA_DOMAIN'],
    clientId: process.env['NEXT_PUBLIC_AUTH0_BA_CLIENT_ID'],
    audience: process.env['NEXT_PUBLIC_AUTH0_BA_AUDIENCE'],
    scope: process.env['NEXT_PUBLIC_AUTH0_BA_SCOPE'],
    cacheLocation: process.env['NEXT_PUBLIC_AUTH0_BA_CACHE_LOCATION'],
  },
}
