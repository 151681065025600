import { ComponentMultiStyleConfig } from '@chakra-ui/react'
import merge from 'lodash/merge'
import { LocationDetailsModalWrapper as base } from './base'

export const LocationDetailsModalWrapper: ComponentMultiStyleConfig = merge({}, base, {
  baseStyle: {
    locationDetails: {
      h1: {
        fontSize: { base: 'xl', md: '3xl' },
      },
      h2: {
        fontSize: { base: 'lg', md: 'xl' },
      },
      h3: {
        fontSize: 'md',
      },
      'p, address, th, td': {
        color: 'brand.coolgrey10',
      },
      phoneNumber: {
        color: 'brand.black',
      },
    },
  },
})
