import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['wrapper', 'header'])

export const TravelerInformationForm = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      'fieldset > div:nth-of-type(2)': {
        mt: 5,
      },

      'button[type=submit]': {
        base: {
          mt: 10,
          mb: 10,
          alignSelf: 'flex-end',
          w: '100%',
          px: 12,
          display: 'none',
        },
        md: {
          display: 'flex',
        },
        lg: {
          w: 'fit-content',
        },
      },
    },
    header: {
      mb: 6,
    },
  }),
})
