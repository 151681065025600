import { ComponentSingleStyleConfig } from '@chakra-ui/react'

export const Title: ComponentSingleStyleConfig = {
  baseStyle: {
    h1: {
      fontSize: { base: '4xl', md: '5xl' },
      fontWeight: 'light',
    },
  },
}
