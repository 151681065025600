const headingFontFaceStr = `
@font-face {
  font-family: 'Mylius Modern';
  src:
    url('/fonts/mylius-modern/MyliusModern-ExtraLight.woff2') format('woff2'),
    url('/fonts/mylius-modern/MyliusModern-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Mylius Modern';
  src:
    url('/fonts/mylius-modern/MyliusModern-Light.woff2') format('woff2'),
    url('/fonts/mylius-modern/MyliusModern-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Mylius Modern';
  src:
    url('/fonts/mylius-modern/MyliusModern-Regular.woff2') format('woff2'),
    url('/fonts/mylius-modern/MyliusModern-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Mylius Modern';
  src:
    url('/fonts/mylius-modern/MyliusModern-Bold.woff2') format('woff2'),
    url('/fonts/mylius-modern/MyliusModern-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
`

const bodyFontFaceStr = `
@font-face {
  font-family: 'Open Sans';
  src:
    url('/fonts/open-sans/OpenSans-Light.woff2?v=1.1.0') format('woff2'),
    url('/fonts/open-sans/OpenSans-Light.woff?v=1.1.0') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('/fonts/open-sans/OpenSans-LightItalic.woff2?v=1.1.0') format('woff2'),
    url('/fonts/open-sans/OpenSans-LightItalic.woff?v=1.1.0') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('/fonts/open-sans/OpenSans-Regular.woff2?v=1.1.0') format('woff2'),
    url('/fonts/open-sans/OpenSans-Regular.woff?v=1.1.0') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('/fonts/open-sans/OpenSans-Italic.woff2?v=1.1.0') format('woff2'),
    url('/fonts/open-sans/OpenSans-Italic.woff?v=1.1.0') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('/fonts/open-sans/OpenSans-Semibold.woff2?v=1.1.0') format('woff2'),
    url('/fonts/open-sans/OpenSans-Semibold.woff?v=1.1.0') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('/fonts/open-sans/OpenSans-SemiboldItalic.woff2?v=1.1.0') format('woff2'),
    url('/fonts/open-sans/OpenSans-SemiboldItalic.woff?v=1.1.0') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('/fonts/open-sans/OpenSans-Bold.woff2?v=1.1.0') format('woff2'),
    url('/fonts/open-sans/OpenSans-Bold.woff?v=1.1.0') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('/fonts/open-sans/OpenSans-BoldItalic.woff2?v=1.1.0') format('woff2'),
    url('/fonts/open-sans/OpenSans-BoldItalic.woff?v=1.1.0') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('/fonts/open-sans/OpenSans-ExtraBold.woff2?v=1.1.0') format('woff2'),
    url('/fonts/open-sans/OpenSans-ExtraBold.woff?v=1.1.0') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('/fonts/open-sans/OpenSans-ExtraBoldItalic.woff2?v=1.1.0') format('woff2'),
    url('/fonts/open-sans/OpenSans-ExtraBoldItalic.woff?v=1.1.0') format('woff');
  font-weight: 800;
  font-style: italic;
}`

const britishAirwaysFontFaceStr = `
  ${headingFontFaceStr}
  ${bodyFontFaceStr}
`

export default britishAirwaysFontFaceStr
