import { ReactElement, ReactNode, useEffect } from 'react'
import type { NextPage } from 'next'
import type { AppContext, AppProps } from 'next/app'
import App from 'next/app'
import { ChakraProvider } from '@chakra-ui/react'
import {
  AuthenticatedOnly,
  AuthProvider,
  parseAuth0Config,
  UnauthenticatedPage,
} from '@reward-platform/auth'
import ReactModal from 'react-modal'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Usercentrics } from '~/components/shared/Scripts'
import Layout from '~/components/shared/Layout'
import { UnauthenticatedLayout } from '~/components/shared/Layout/Layout'
import { NotificationProvider } from '~/components/shared/Notifications'
import SpinnerOverlay from '~/components/shared/SpinnerOverlay'
import { LocalisationProvider } from '~/context/localisation'
import { PartnerProvider } from '~/context/partner'
import { UserProvider } from '~/context/user'
import { PromotionProvider } from '~/context/promotion'
import { DevCycleProvider, IdentifyDevCycleUser } from '~/context/devcycle'
import useCorrelationId from '~/hooks/useCorrelationId/useCorrelationId'
import useHotjar from '~/hooks/useHotjar/useHotjar'
import useLastKnownUser from '~/hooks/useLastKnownUser/useLastKnownUser'
import { getPartner, Partner } from '~/services/partnerService'
import EmotionFonts from '~/styles/fonts/emotionFonts'
import { useRouter } from 'next/router'
import { AppState } from '@auth0/auth0-react'
import { isClient } from '~/utils/envChecks'
import { chakraThemeMap } from '~/themes/chakra'
import useRemoveExpiredBasketItems from '~/hooks/useRemoveExpiredBasketItems'
import { ErrorBoundary } from '~/components/shared/ErrorBoundary'
import { MaintenanceModeBoundary } from '../components/shared/MaintenanceModeBoundary'
import {
  setAuthorizationToken,
  setCorrelationId as setBFFCorrelationId,
  setPartner,
} from '../services/clients/bffClient'
import { initDatadogLogging, initDatadogRum } from '../utils/datadog'
import GlobalCSS from '../styles/global.css'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  },
})
ReactModal.setAppElement('#__next')

if (isClient()) {
  initDatadogRum()
  initDatadogLogging()
}

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

interface MyAppProps extends AppProps {
  partner: Partner
  ip?: string
  Component: NextPageWithLayout
}

const DEFAULT_MESSAGE_CLUSTER = ['reward-platform']
const initialClusters =
  process.env.NEXT_PUBLIC_MESSAGE_CLUSTERS?.split(',') ?? DEFAULT_MESSAGE_CLUSTER

const ClearExpiredBasketItems = () => {
  useRemoveExpiredBasketItems()

  return null
}

function MyApp(props: MyAppProps): JSX.Element {
  const { Component, pageProps, partner } = props
  const registerUser = useLastKnownUser()
  const { correlationId, resetCorrelationId } = useCorrelationId()

  useHotjar()

  const auth0Config = parseAuth0Config(partner.code)

  useEffect(() => {
    setPartner(partner.code)
  }, [partner])

  useEffect(() => {
    if (correlationId == null) {
      resetCorrelationId()
      return
    }
    setBFFCorrelationId(correlationId)
  }, [correlationId, resetCorrelationId])

  const { replace } = useRouter()

  const onRedirectCallback = (appState?: AppState) => {
    replace(appState?.returnTo ?? '/')
  }

  const onTokenReceived = (token: string) => {
    registerUser(token)
    setAuthorizationToken(token)
  }

  const getLayout = Component.getLayout ?? ((page) => <Layout variant="search">{page}</Layout>)

  const getUnauthenticatedLayout =
    Component.getLayout ?? ((page) => <UnauthenticatedLayout>{page}</UnauthenticatedLayout>)

  return (
    <ErrorBoundary partner={partner}>
      <PartnerProvider partner={partner}>
        <DevCycleProvider partner={partner}>
          {({ isInitialized: isDevCycleInitialized }) => (
            <MaintenanceModeBoundary partner={partner}>
              <ChakraProvider theme={chakraThemeMap[partner.theme]} resetCSS={false}>
                <EmotionFonts partner={partner.theme} />
                <QueryClientProvider client={queryClient}>
                  <IdentifyDevCycleUser />
                  <ClearExpiredBasketItems />
                  <Usercentrics partnerCode={partner.code} />
                  <LocalisationProvider initialClusterKeys={initialClusters}>
                    <AuthProvider {...auth0Config} onRedirectCallback={onRedirectCallback}>
                      {/* disabled in prod by default */}
                      <ReactQueryDevtools />
                      <GlobalCSS />
                      <AuthenticatedOnly
                        spinner={<SpinnerOverlay />}
                        onTokenReceived={onTokenReceived}
                        isLoading={!isDevCycleInitialized}
                      >
                        <NotificationProvider>
                          <UserProvider>
                            <PromotionProvider>
                              {getLayout(<Component {...pageProps} />)}
                            </PromotionProvider>
                          </UserProvider>
                        </NotificationProvider>
                      </AuthenticatedOnly>
                      <UnauthenticatedPage spinner={<SpinnerOverlay />}>
                        <NotificationProvider>
                          {getUnauthenticatedLayout(<Component {...pageProps} />)}
                        </NotificationProvider>
                      </UnauthenticatedPage>
                    </AuthProvider>
                  </LocalisationProvider>
                </QueryClientProvider>
              </ChakraProvider>
            </MaintenanceModeBoundary>
          )}
        </DevCycleProvider>
      </PartnerProvider>
    </ErrorBoundary>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext)
  // nextjs's internal error page
  const { headers, socket } = appContext.ctx?.req ?? {}
  const partner = getPartner(headers)
  const ipAddresses =
    headers?.['x-real-ip'] ?? headers?.['x-forwarded-for'] ?? socket?.remoteAddress
  const ip = Array.isArray(ipAddresses) ? ipAddresses.join() : ipAddresses

  // Handle error response
  const props = { ...appProps, partner, ip }
  if (appContext.router.pathname.includes('_error')) {
    return { ...props }
  }

  return { ...props }
}

export default MyApp
