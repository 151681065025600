import React from 'react'
import Script from 'next/script'
import { Partner } from '~/services/partnerService'
import { isProduction } from '~/utils/envChecks'

const Usercentrics = ({ partnerCode }: { partnerCode: Partner['code'] }) => {
  const usercentricsDataVersion = isProduction() ? undefined : 'preview'

  const getCookieBanner = (code: Partner['code']) => {
    switch (code) {
      case 'BA':
        return 'iMO9-XcLWV5uu5'
      case 'EI':
        return 'okXP7mPh3airbn'
      default:
        return 'iMO9-XcLWV5uu5'
    }
  }

  return (
    <Script
      id="usercentrics-cmp"
      src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
      data-settings-id={getCookieBanner(partnerCode)}
      async
      data-version={usercentricsDataVersion}
    />
  )
}

export default Usercentrics
