import { AxiosError } from 'axios'
import { BffErrorResponseSchema } from '@reward-platform/shared-schemas'
import { GenericClientError } from './GenericClientError'
import { ERROR_CODES } from './clientErrors'

export class BFFRequestError extends GenericClientError {
  constructor(error: AxiosError) {
    super(ERROR_CODES.BFF_CLIENT, error)

    this.httpStatus = error.response?.status
    this.originalError = error

    const parsedResponseData = BffErrorResponseSchema.safeParse(error.response?.data)
    if (parsedResponseData?.success) {
      this.developerMessage = parsedResponseData.data.developerMessage
      this.businessMessage = parsedResponseData.data.businessMessage
      this.code = parsedResponseData.data.code
      this.validationErrors = parsedResponseData.data.validationErrors
      this.fatal = parsedResponseData.data.unrecoverable
    }
  }
}
