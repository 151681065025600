import React, { createContext, useContext, useMemo, useCallback } from 'react'
import { useQuery } from 'react-query'
import isWithinInterval from 'date-fns/isWithinInterval'
import { ContentfulPromotionComponents } from '@reward-platform/shared-schemas'
import { getPromotionsContent } from '~/services/contentService'
import { useFeatureFlag } from '~/hooks/useFeatureFlag'
import { CurrencyCodes } from '@reward-platform/ancillaries-schemas/country-currency-codes'
import { compareDates } from '~/utils/datetime'
import { promotionAnalyticsDetails } from '~/utils/googleTagManager/googleTagManagerHelperFunctions'
import { usePartner } from '../partner'
import { useUser } from '../user'
import {
  ProductType,
  PromotionData,
  PromotionContextValue,
  Promotion,
} from './PromotionContext.types'
import {
  createItemValidatorForPromotionType,
  createVariableMapForPromotionType,
  getPromotionByData,
  mapPromotionsToProduct,
} from './Promotion.utils'

const DEFAULT_CONTEXT: PromotionContextValue = {
  isEnabled: () => false,
  getProductPromotion: () => undefined,
  isLoading: false,
}

export const PromotionContext = createContext<PromotionContextValue>(DEFAULT_CONTEXT)

// NOTE: to be replaced by request to Promotion Service
export const getActivePromotionData = (
  promotionResponse: ContentfulPromotionComponents[] = []
): PromotionData[] => {
  const isPromotionActive = (promo: PromotionData) =>
    isWithinInterval(Date.now(), {
      start: new Date(promo.startDate),
      end: new Date(promo.endDate),
    })
  return [...promotionResponse]
    ?.sort((a, b) =>
      compareDates(new Date(a.promotionData.endDate), new Date(b.promotionData.endDate))
    )
    ?.map((item) => ({ id: item.promotionId, ...item.promotionData }))
    .filter(isPromotionActive)
}

export const PromotionProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const isPromotionsEnabled = useFeatureFlag('promotions')
  const partner = usePartner()
  const { user, userLocale } = useUser()

  const userCurrencyCode = useMemo(
    () => (user?.person?.currencyCode as CurrencyCodes) ?? 'GBP',
    [user?.person?.currencyCode]
  )
  const shouldRunQueries = !!user && !!isPromotionsEnabled
  const { data: promotionContentResponse, isFetched: isContentFetched } = useQuery(
    ['promotions'],
    () => getPromotionsContent(partner.theme, userLocale ?? 'en-GB'),
    { enabled: shouldRunQueries }
  )

  const { data: activePromotionData, isFetched: isDataFetched } = useQuery(
    ['promotionData'],
    () => getActivePromotionData(promotionContentResponse),
    { enabled: shouldRunQueries && !!promotionContentResponse }
  )

  const productPromotionMap = useMemo(
    () => mapPromotionsToProduct(activePromotionData, promotionContentResponse),
    [activePromotionData, promotionContentResponse]
  )

  const getFallbackPromotion = useCallback(
    () => getPromotionByData(activePromotionData?.[0], promotionContentResponse),
    [activePromotionData, promotionContentResponse]
  )

  const getActivePromotion = useCallback(
    (product?: ProductType): Promotion | undefined =>
      product ? productPromotionMap[product] : getFallbackPromotion(),
    [getFallbackPromotion, productPromotionMap]
  )

  const isEnabled = useCallback(
    (product?: ProductType): boolean => Boolean(isPromotionsEnabled && getActivePromotion(product)),
    [getActivePromotion, isPromotionsEnabled]
  )

  const getProductPromotion: PromotionContextValue['getProductPromotion'] = useCallback(
    (product) => {
      const activePromotion = getActivePromotion(product)

      if (!activePromotion) {
        return undefined
      }

      const variableMap = createVariableMapForPromotionType(
        activePromotion.data,
        userCurrencyCode,
        userLocale
      )
      const isItemValidForPromotion = createItemValidatorForPromotionType(activePromotion.data)

      return { ...activePromotion, variableMap, isItemValidForPromotion }
    },
    [getActivePromotion, userCurrencyCode, userLocale]
  )

  const isLoading = useMemo(
    () => !promotionContentResponse && !isContentFetched && !isDataFetched,
    [promotionContentResponse, isContentFetched, isDataFetched]
  )

  const contextValue = useMemo<PromotionContextValue>(
    () => ({ isEnabled, getProductPromotion, isLoading }),
    [isEnabled, getProductPromotion, isLoading]
  )

  promotionAnalyticsDetails(productPromotionMap)

  return <PromotionContext.Provider value={contextValue}>{children}</PromotionContext.Provider>
}

export const usePromotionContext = (): PromotionContextValue => {
  const context = useContext(PromotionContext)
  const isPromotionsEnabled = useFeatureFlag('promotions')
  if (!context || !isPromotionsEnabled) {
    return DEFAULT_CONTEXT
  }
  return context
}
