import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const carBasketItemCardHelpers = createMultiStyleConfigHelpers([
  'content',
  'carFeatures',
  'vehicleImage',
  'iconWrapper',
])

export const CarBasketItemCard = carBasketItemCardHelpers.defineMultiStyleConfig({
  baseStyle: {
    content: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      rowGap: 6,
    },
    carFeatures: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      mt: 6,
      columnGap: 4,
      rowGap: 2,
      fontSize: 'xs',
    },
    iconWrapper: {
      width: 6,
      height: 6,
      background: 'black',
      padding: 1,
      borderRadius: '50%',
      color: 'white',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 2,
      fontSize: { base: 'sm', lg: 'md' },
      fontWeight: 'medium',
    },
  },
})
