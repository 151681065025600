import { css } from '@emotion/react'

export const base = css`
  html {
    font-size: 100%;

    &[lang^='ar'],
    &[lang^='he'],
    &[lang^='ur'] {
      direction: rtl;
    }
  }

  hr {
    margin: 2.5rem 0;
    border: none;
    height: 0;
    border-top: 1px solid grey;
  }
`

export default base
