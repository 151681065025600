import { useEffect, useRef } from 'react'
import { Basket, BasketItem } from '@reward-platform/order-schemas/basket'
import { removeItemFromBasket } from '~/services/basketService'
import { useIsAuthenticatedUser } from '~/context/user'
import { getIsItemExpired } from '@reward-platform/order-schemas/util'
import { useBasketQuery } from '../useBasket/useBasket'

const removeItemFromBasketIfExpired = async (item: BasketItem) => {
  const { id } = item
  const isItemExpired = getIsItemExpired(item)
  const removeItem = !!id && isItemExpired
  if (removeItem) {
    await removeItemFromBasket(id)
  }

  return { wasItemRemoved: Boolean(removeItem) }
}

type OnBasketUpdatedParams = {
  basket: Basket | undefined
  invalidateBasket: () => Promise<void>
}

const onBasketUpdated = async ({ basket, invalidateBasket }: OnBasketUpdatedParams) => {
  if (!basket) {
    return
  }

  const allBasketItems = [
    ...basket.items.HOTEL,
    ...basket.items.CAR_HIRE,
    ...basket.items.EXPERIENCE,
    ...basket.items.FLIGHT,
  ]

  const results = await Promise.all(
    allBasketItems.map((item) => removeItemFromBasketIfExpired(item))
  )

  // If any item was removed we should invalidate the basket
  if (results.some(({ wasItemRemoved }) => wasItemRemoved)) {
    await invalidateBasket()
  }
}

export const useRemoveExpiredBasketItems = () => {
  const userIsAuthenticated = useIsAuthenticatedUser()
  const basket = useBasketQuery({
    isEnabled: !!userIsAuthenticated,
    onError: () => {
      // no op
    },
  })
  const previousDataUpdatedAtRef = useRef<number>()

  const { data, dataUpdatedAt, refetch } = basket

  useEffect(() => {
    const isBasketUpdated = previousDataUpdatedAtRef.current !== dataUpdatedAt && data
    previousDataUpdatedAtRef.current = dataUpdatedAt
    if (isBasketUpdated) {
      onBasketUpdated({
        basket: data,
        invalidateBasket: async () => {
          await refetch()
        },
      })
    }
  }, [data, dataUpdatedAt, refetch])
}
