import React from 'react'
import { usePromotionContext } from '~/context/promotion'
import { PromotionProps } from './Promotion.types'
import Hero from '../shared/Hero'
import { DynamicVariableRichText } from '../shared/DynamicVariableRichText'

const PromotionStickyBanner = ({ product }: PromotionProps) => {
  const { isEnabled, getProductPromotion } = usePromotionContext()
  const { content, variableMap } = getProductPromotion(product) ?? {}

  const { searchPageHero } = content || {}

  if (!isEnabled(product) || !searchPageHero?.body) {
    return null
  }

  return (
    <Hero src={searchPageHero.image?.file?.url ?? ''} alt={searchPageHero.image?.description ?? ''}>
      <Hero.Headline>{searchPageHero.heading}</Hero.Headline>
      <Hero.Tagline>
        <DynamicVariableRichText document={searchPageHero.body} variableMap={variableMap} />
      </Hero.Tagline>
    </Hero>
  )
}

export default PromotionStickyBanner
