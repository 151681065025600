import { Box, chakra, Link, useMultiStyleConfig, Icon, useMediaQuery } from '@chakra-ui/react'
import { IconName } from '@reward-platform/ancillaries-schemas/common'
import { Image } from '@reward-platform/lift/components'
import { IconSizes } from '@reward-platform/lift/theme/iconSize'
import { deviceSizes } from '@reward-platform/lift/theme/deviceSizes'
import NextLink from 'next/link'
import { usePartner } from '~/context/partner'
import { useBasketItemsCount } from '~/hooks/useBasket/useBasket'
import useIntl from '~/hooks/useIntl/useIntl'
import { convertIconSetToChakraSVG } from '~/utils/Icon.utils'
import formatPrimaryDriversName from '~/utils/formatPrimaryDriversName'
import HeaderMenu from '../HeaderMenu'
import { MenuOption } from '../HeaderMenu/HeaderMenuItem'

export type HeaderProps = {
  user?: {
    title?: string
    firstName: string
    familyName: string
  }
  accountBalanceAmount?: number
  isLoading?: boolean
  menuOptions: (MenuOption & { isVisible: boolean })[]
}
const Header = ({ user, accountBalanceAmount, isLoading, menuOptions }: HeaderProps) => {
  const intl = useIntl()
  const partner = usePartner()
  const [isTabletOrSmaller] = useMediaQuery(`(max-width: ${deviceSizes.medium})`)
  const basketItemsCount = useBasketItemsCount()

  const t = {
    welcomeBack: intl.formatMessage({
      id: 'header-welcome-back',
      defaultMessage: 'Welcome back',
    }),
    aviosBalance: intl.formatMessage({
      id: 'header-avios-balance',
      defaultMessage: 'Avios Balance',
    }),
  }

  const getLogoSrc = (): string => {
    const shouldUseMobileLogo = partner.code === 'BA' && isTabletOrSmaller
    const filenameSuffix = shouldUseMobileLogo ? 'logo-mobile' : 'logo'
    return `/assets/${partner.theme.replace('_', '-')}-${filenameSuffix}.svg`
  }

  const isLoggedIn = Boolean(user)
  const styles = useMultiStyleConfig('Header', {})
  const redirectHref = partner.name === 'Aer Lingus' ? '/?tab=flights' : '/'

  const visibleMenuItems = menuOptions.filter(({ isVisible }) => isVisible)

  return (
    <Box __css={styles.headerWrapper} role="banner">
      <Box __css={styles.header} sx={visibleMenuItems.length === 1 ? styles.smallGap : undefined}>
        <Link as={NextLink} href={redirectHref} tabIndex={0} aria-label="home link">
          <Image
            __css={styles.logo}
            src={getLogoSrc()}
            alt={`${partner.name} Logo`}
            layout="fill"
            objectFit="contain"
            objectPosition="left"
          />
        </Link>
        {isLoggedIn && (
          <>
            <Box __css={styles.userDetails} data-testid="user-details">
              <chakra.span>{t.welcomeBack}</chakra.span>
              <chakra.span>
                {user ? formatPrimaryDriversName(user.title, user.firstName, user.familyName) : '—'}
              </chakra.span>
            </Box>
            <Box __css={styles.aviosDetails} data-testid="avios-details">
              <chakra.span>{t.aviosBalance}</chakra.span>
              <chakra.span>
                {accountBalanceAmount != null && Number.isInteger(accountBalanceAmount)
                  ? intl.formatNumber(accountBalanceAmount, {
                      maximumFractionDigits: 0,
                    })
                  : '—'}
              </chakra.span>
            </Box>
          </>
        )}
        {!isLoading && (
          <>
            <Link
              as={NextLink}
              href="/orders"
              aria-label={
                basketItemsCount > 0 ? `view basket (${basketItemsCount})` : 'view basket'
              }
              tabIndex={0}
            >
              <Box __css={styles.cartWrapper} data-testid="view-basket-cart">
                {basketItemsCount > 0 ? (
                  <>
                    <Icon
                      as={convertIconSetToChakraSVG(IconName.Enum['filled-cart'])}
                      boxSize={IconSizes.xl}
                      fill="currentColor"
                    />
                    <chakra.p data-testid="basket-items-count" __css={styles.basketItemsCount}>
                      {basketItemsCount}
                    </chakra.p>
                  </>
                ) : (
                  <Icon
                    as={convertIconSetToChakraSVG(IconName.Enum['empty-cart'])}
                    boxSize={IconSizes.xl}
                    fill="currentColor"
                  />
                )}
              </Box>
            </Link>
            <HeaderMenu>{visibleMenuItems}</HeaderMenu>
          </>
        )}
      </Box>
    </Box>
  )
}

export default Header
