import {
  CanceledOrder,
  CanceledOrderSchema,
  CancelOrderParams,
  CancelOrderPaymentInformation,
  CreateOrder,
  Order,
  OrderList,
  OrderListSchema,
  OrderSchema,
  ReshopOrder,
  ReshopOrderSchema,
} from '@reward-platform/order-schemas/order'
import { bffClient } from './clients/bffClient'
import { createServiceSchemaValidator } from './utils'

const validateOrder = createServiceSchemaValidator(OrderSchema)
const validateOrderList = createServiceSchemaValidator(OrderListSchema)
const validateReshopOrder = createServiceSchemaValidator(ReshopOrderSchema)
const validateCanceledOrder = createServiceSchemaValidator(CanceledOrderSchema)

export async function getCaptureContext(): Promise<{
  clientLibrary: string
  captureContext: string
}> {
  const { data } = await bffClient.get(`/orders/payment/capture-context`)
  return data
}

// TODO: return type should be Promise<OrderResponse>
export async function createOrder(order: CreateOrder): Promise<Order> {
  const { data } = await bffClient.post(`/orders`, order)
  return validateOrder(data)
}

export async function getOrder(orderId: string, resolveLocations = false): Promise<Order> {
  const urlSearchParams = new URLSearchParams()

  if (resolveLocations) {
    urlSearchParams.append('resolveLocations', resolveLocations.toString())
  }

  const query = urlSearchParams.toString()
  const queryString = query.length ? `?${query}` : ''

  const { data } = await bffClient.get<Order>(`/orders/${orderId}${queryString}`)
  return validateOrder(data)
}

export async function getOrderList(pageNumber: number, pageSize: number): Promise<OrderList> {
  const { data } = await bffClient.get<Order>(`/orders`, { params: { pageNumber, pageSize } })

  return validateOrderList(data)
}

export const updateOrder = async (
  orderId: string,
  paymentId: string,
  is3DS: boolean
): Promise<void> => {
  await bffClient.put(`/orders/${orderId}`, { paymentId, is3DS })
}

export async function reShopOrder(orderId: string, bundleRefs: string[]): Promise<ReshopOrder> {
  const { data } = await bffClient.post<Order>(`/orders/${orderId}/reshop`, {
    bundleRefs,
  })
  return validateReshopOrder(data)
}

export async function cancelOrder(
  orderId: string,
  recommendationId: string,
  bundleId: string,
  bundleRefs: string[],
  paymentInformation?: CancelOrderPaymentInformation
) {
  const params: CancelOrderParams = {
    bundleId,
    bundleRefs,
    orderId,
    recommendationId,
    paymentInformation,
  }

  const { data } = await bffClient.post<CanceledOrder>(`/orders/${orderId}/cancel`, params)
  return validateCanceledOrder(data)
}
