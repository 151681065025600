import { useUser } from '~/context/user'
import { isProduction } from '~/utils/envChecks'
import { usePartner } from '../partner'
import { useChannel } from './useChannel'

type NewPageViewParams = {
  page_location: string
  channel?: string
  memberID: string
  booking_currency: string
  currencyCode: string
  balance: number | string
  domain_environment: 'prod' | 'non_prod'
  programmeCode: string
}

export const useNewPageViewParams = (): NewPageViewParams | undefined => {
  const { user, account } = useUser()
  const channel = useChannel()
  const partner = usePartner()

  const memberID = user?.memberIdentifier?.identifier ?? 'not_applicable'
  const bookingCurrency = user?.person?.currencyCode
  const currencyCode = user?.person?.currencyCode
  const balance = account?.balance.amount ?? 'not_applicable'
  const domainEnvironment = isProduction() ? 'prod' : 'non_prod'
  const programmeCode = partner.slug

  if (memberID && bookingCurrency && currencyCode && balance != null) {
    return {
      page_location: window.location.href,
      channel,
      memberID,
      booking_currency: bookingCurrency,
      currencyCode,
      balance,
      domain_environment: domainEnvironment,
      programmeCode,
    }
  }

  return undefined
}
