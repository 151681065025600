import React, { ReactElement } from 'react'
import { chakra, useMultiStyleConfig } from '@chakra-ui/react'
import Image from '../ChakraNextImage/index'
import { IImageCard } from './ImageCard.interface'

function ImageCard({ children, href, ...props }: IImageCard): ReactElement {
  const styles = useMultiStyleConfig('ImageCard', {})

  return (
    <chakra.figure __css={styles.figure}>
      {href ? (
        <chakra.a __css={styles.link} href={href}>
          <Image {...props} />
          <chakra.figcaption>{children}</chakra.figcaption>
        </chakra.a>
      ) : (
        <div>
          <Image {...props} />
          <chakra.figcaption __css={styles.caption}>{children}</chakra.figcaption>
        </div>
      )}
    </chakra.figure>
  )
}

export default ImageCard
