import { helpers } from './base'

export const CancelOrderModal = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    orderDetailsContainer: {
      p: {
        _first: {
          fontWeight: 'normal',
          lineHeight: 'normal',
          fontSize: 'md',
        },
        _last: {
          lineHeight: 'normal',
          fontSize: 'sm',
        },
        'span:first-child': {
          fontWeight: 'bold',
        },
      },
    },
    label: {
      fontWeight: 'light',
      textTransform: 'none',
    },
    breakdownWrapper: {
      bg: 'brand.baseGray.5',
      borderRadius: 'sm',
    },
    rowItem: {
      bg: 'brand.baseGray.5',
      borderRadius: 'sm',
    },
    totalIncludingChargesWrapper: {
      '> p': {
        _first: {
          fontSize: '2xl',
          pb: 2,
        },
        _last: {
          fontSize: 'sm',
        },
      },
    },
  }),
  variants: {
    heading: {
      rowItem: {
        'div:first-child': { fontSize: '2xl' },
        bg: 'none',
      },
    },
    rowAccented: {
      rowItem: {
        bg: 'brand.alpha.blue',
        p: {
          fontWeight: 'semibold',
        },
      },
    },
  },
})
