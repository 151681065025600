import { chakra, Icon, MenuItem } from '@chakra-ui/react'
import Link from 'next/link'
import { IconName, IconNameType } from '@reward-platform/ancillaries-schemas/common'
import { convertIconSetToChakraSVG } from '~/utils/Icon.utils'
import { IconSizes } from '@reward-platform/lift/theme/iconSize'

export type MenuOption = {
  icon: IconNameType
  label: string
  href: string
  opensInNewTab: boolean
}

export type HeaderMenuItemProps = {
  children: MenuOption
  onClick: () => void
}

const HeaderMenuItem = ({
  children: { icon, label, href, opensInNewTab },
  onClick,
}: HeaderMenuItemProps) => {
  return (
    <Link passHref href={href} legacyBehavior>
      <MenuItem as="a" target={opensInNewTab ? '_blank' : undefined} onClick={onClick}>
        <Icon as={convertIconSetToChakraSVG(IconName.Enum[icon])} boxSize={IconSizes.lg} />
        <chakra.span mr="auto">{label}</chakra.span>
        {opensInNewTab && (
          <Icon
            as={convertIconSetToChakraSVG(IconName.Enum['external-link'])}
            boxSize={IconSizes.xxs}
          />
        )}
      </MenuItem>
    </Link>
  )
}

export default HeaderMenuItem
