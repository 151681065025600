import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react'
import {
  DataLayerObject,
  pushEventToDataLayer as gtmPushEventToDataLayer,
  resetAndPushNewPageView,
  compareAndPushEventInDataLayer,
  defaultBaseDataLayerObject,
} from '~/utils/googleTagManager'
import { useNewPageViewParams } from './useNewPageViewParams'
import { TagManagerContext, PushEventToDataLayer } from './TagManagerContext'
import { useUser } from '../user'

type Event = {
  eventName: string
  data?: DataLayerObject
}

export const TagManagerProvider = ({
  programmeCode,
  children,
}: {
  programmeCode: string
  children?: React.ReactNode
}) => {
  const router = useRouter()
  const newPageViewParams = useNewPageViewParams()
  const [isPageViewEventSent, setIsPageViewEventSent] = useState(false)
  const isInitialEventSentRef = useRef(false)
  const [bufferedEvents, setBufferedEvents] = useState<Event[]>([])
  const { user, account } = useUser()
  const channel = ((router.query.searchType ?? router.query.tab) as string) ?? undefined

  // If the page view event has not been sent, add the event to the buffer
  const pushEventToDataLayer = useCallback<PushEventToDataLayer>(
    (eventName, data) => {
      if (isPageViewEventSent) {
        compareAndPushEventInDataLayer(eventName, data)
      } else {
        setBufferedEvents((oldEventBuffer) => {
          return [...oldEventBuffer, { eventName, data }]
        })
      }
    },
    [isPageViewEventSent]
  )
  // If the page view  event has not been sent, add the event to the buffer
  const onRouteChangeComplete = useCallback(() => {
    resetAndPushNewPageView({ page_location: window.location.href, ...newPageViewParams })
    compareAndPushEventInDataLayer('initial_setup', {
      ...defaultBaseDataLayerObject,
      channel,
      programmeCode,
      pageLocation: router.route,
    })
    gtmPushEventToDataLayer('initial_setup_2', {
      memberID: user?.memberIdentifier?.identifier ?? 'not_applicable',
      booking_currency: user?.person?.currencyCode,
      currency: user?.person?.currencyCode,
      balance: account?.balance.amount ?? 'not_applicable',
      channel: router.query.tab as string,
    })
    setIsPageViewEventSent(true)
  }, [account, channel, newPageViewParams, programmeCode, router.query.tab, router.route, user])

  // Send the initial new page view event on page refresh (and make sure it's sent only once)
  useEffect(() => {
    if (newPageViewParams && !isInitialEventSentRef.current) {
      isInitialEventSentRef.current = true
      onRouteChangeComplete()
    }
  }, [newPageViewParams, onRouteChangeComplete])

  const pushBufferedEvents = useCallback(() => {
    bufferedEvents.forEach(({ eventName, data }) => compareAndPushEventInDataLayer(eventName, data))
    setBufferedEvents([])
  }, [bufferedEvents])

  // Once the page view event has been sent, send the buffered events
  useEffect(() => {
    if (bufferedEvents.length > 0 && isPageViewEventSent) {
      pushBufferedEvents()
    }
  }, [bufferedEvents.length, isPageViewEventSent, pushBufferedEvents])

  useEffect(() => {
    const onRouteChangeStart = () => {
      setIsPageViewEventSent(false)
    }

    router.events.on('routeChangeStart', onRouteChangeStart)
    router.events.on('routeChangeComplete', onRouteChangeComplete)
    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart)
      router.events.off('routeChangeComplete', onRouteChangeComplete)
    }
  }, [onRouteChangeComplete, router.events])

  const context = useMemo(() => ({ pushEventToDataLayer }), [pushEventToDataLayer])

  return <TagManagerContext.Provider value={context}>{children}</TagManagerContext.Provider>
}
