import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['container', 'destination'])

export const EditSearchResultsForm = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      display: 'grid',
      gap: { base: 4, md: 6, lg: 4 },
      width: 'full',
      height: { base: 'min-content', md: 'auto', lg: 'min-content' },
      backgroundColor: 'brand.white',
      gridTemplate: {
        base: `
        'destination'
        'dates'
        'travellers'
        'search'`,
        md: `
       'destination travellers'
       'dates search'`,
        lg: `'destination dates travellers search'`,
      },
      gridTemplateColumns: {
        base: `1fr`,
        lg: `2fr 2fr 1fr 1fr`,
      },
      gridTemplateRows: 'auto',

      '> *': {
        position: 'relative',
        zIndex: 1,
      },

      '> div:nth-of-type(1),  > div:nth-of-type(4)': {
        zIndex: 2,
      },
    },
    destination: {
      borderRadius: 'lg',
      boxShadow: { base: 'none', md: '0 6px 9px 0 rgba(0, 0, 0, 0.1)' },
      padding: '6',
      color: 'rgba(99, 102, 106, 1)',
      cursor: 'default',
    },
  },
})
