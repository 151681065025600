export const getBrowserFromUserAgent = (userAgent: string) => {
  if (userAgent.includes('Firefox')) {
    return 'Firefox'
  }
  if (userAgent.includes('Edg')) {
    return 'Edge'
  }
  if (userAgent.includes('Trident')) {
    return 'IE'
  }
  if (userAgent.includes('Chrome')) {
    return 'Chrome'
  }
  if (userAgent.includes('Safari')) {
    return 'Safari'
  }
  return undefined
}

export const getPlatformFromUserAgent = (userAgent: string) => {
  if (userAgent.includes('iPhone')) {
    return 'iOS'
  }
  if (userAgent.includes('Android')) {
    return 'Android'
  }
  if (userAgent.includes('Mac')) {
    return 'MacOS'
  }
  if (userAgent.includes('Windows')) {
    return 'Windows'
  }
  return undefined
}

export const isUserAgentMobileOrTablet = (userAgent: string) => {
  const platform = getPlatformFromUserAgent(userAgent)
  return platform === 'Android' || platform === 'iOS'
}

export const getPageTitle = (pageURL: string) => {
  const pageTitleMap: Record<string, string> = {
    'tab=hotels': 'Hotels Search',
    'tab=flights': 'Flights Search',
    'tab=carhire': 'Car Hire Search',
    'tab=experiences': 'Experiences Search',
    'cars/results': 'Car Hire Results',
    'hotels/results': 'Hotel Results',
    'experiences/results': 'Experience Results',
    'flights/results': 'Flights Results',
    'flights/details': 'Flights Details',
    'cars/details': 'Car Hire Details',
    'hotels/details': 'Hotel Details',
    'experiences/details': 'Experience Details',
    'orders/payment-options': 'Payment Options',
    'orders/traveller-details': 'Traveller Details',
    'orders/additional-details': 'Additional Details',
    'orders/payment': 'Payment',
    'orders/view-booking': 'View Booking',
    'order-history': 'Order History',
    'contact-us': 'Contact Us',
    accessibility: 'Accessibility',
    'terms-and-conditions': 'Terms and Conditions',
    'cookie-policy': 'Cookie Policy',
  }

  const matchedKey = Object.keys(pageTitleMap).find((url) => pageURL.includes(url))
  return matchedKey ? pageTitleMap[matchedKey] : document.title
}

export const getPageAncillaryType = (pageURL: string) => {
  if (pageURL.includes('hotel')) {
    return 'HOTEL'
  }
  if (pageURL.includes('experience')) {
    return 'EXPERIENCE'
  }
  if (pageURL.includes('flight')) {
    return 'FLIGHT'
  }
  if (pageURL.includes('car')) {
    return 'CAR_HIRE'
  }
  return null
}
