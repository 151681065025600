import { useEffect } from 'react'
import {
  Button,
  Box,
  Center,
  useMultiStyleConfig,
  List,
  ListItem,
  Text,
  Icon,
  VStack,
  Link,
} from '@chakra-ui/react'
import { SkipNavContent, SkipNavLink } from '@chakra-ui/skip-nav'
import Head from 'next/head'
import Footer from '~/components/shared/Footer/Footer'
import Header, { HeaderProps } from '~/components/shared/Header'
import { NotificationBar } from '~/components/shared/Notifications'
import SpinnerOverlay from '~/components/shared/SpinnerOverlay'
import { usePartner } from '~/context/partner'
import { useAccountQuery, useUser, useUserQuery, useIsAuthenticatedUser } from '~/context/user'
import { TagManagerProvider } from '~/context/analytics'
import { useFeatureFlag } from '~/hooks/useFeatureFlag/useFeatureFlag'
import { selectTotalAccount } from '~/services/accountsService'
import { PromotionStickyBanner } from '~/components/promotions'
import { Image, StickyBanner } from '@reward-platform/lift/components'
import { convertIconSetToChakraSVG } from '~/utils/Icon.utils'
import { IconName } from '@reward-platform/ancillaries-schemas/common'
import useIntl from '~/hooks/useIntl/useIntl'
import { useDisruptionMessages } from '~/hooks/useDisruptionMessages'
import PromotionFirstVisitModal from '~/components/promotions/PromotionFirstVisitModal'
import { LayoutProps } from './Layout.types'
import ChatWidgetMock from '../ChatWidgetMock'

const SKIP_TO_CONTENT_ID = 'skip-to-content'
const SKIP_TO_FOOTER_ID = 'skip-to-footer'

type BaseLayoutProps = LayoutProps & {
  shouldLoadUser?: boolean
  isLoading?: boolean
}

const BaseLayout = ({
  children,
  title,
  sparse = false,
  shouldLoadUser = true,
  variant = 'base',
  isLoading,
}: BaseLayoutProps): JSX.Element => {
  const { data: user, isLoading: isLoadingUser, failureCount: failureCountUser } = useUserQuery()
  const { data: accountsResponse, isLoading: isLoadingAccount } = useAccountQuery()
  const intl = useIntl()
  const orderHistoryEnabled = useFeatureFlag('order-history')

  const partner = usePartner()

  const totalAccount = accountsResponse && selectTotalAccount(accountsResponse)

  const isLoadingUserData = (isLoadingUser || isLoadingAccount) && failureCountUser <= 1

  const userIsAuthenticated = useIsAuthenticatedUser()
  useEffect(() => {
    sessionStorage.setItem('authenticated_user', userIsAuthenticated ? 'true' : 'false')
  }, [userIsAuthenticated])

  const headerMenuOptions: HeaderProps['menuOptions'] = [
    {
      icon: 'file-text',
      label: intl.formatMessage({
        id: 'order-history',
        defaultMessage: 'Order History',
      }),
      href: '/order-history',
      opensInNewTab: false,
      isVisible: Boolean(user) && Boolean(orderHistoryEnabled),
    },
    {
      icon: 'log-out',
      label: intl.formatMessage({
        id: 'log-out',
        defaultMessage: 'Log out',
      }),
      href: '/auth/logout',
      opensInNewTab: false,
      isVisible: Boolean(user),
    },
    {
      icon: 'person',
      label: intl.formatMessage({
        id: 'login',
        defaultMessage: 'Login',
      }),
      href: '/auth/login',
      opensInNewTab: false,
      isVisible: !user,
    },
  ]
  const t = {
    skipToContentLabel: intl.formatMessage({
      id: 'app-skip-to-content-link',
      defaultMessage: 'Skip to main content',
    }),
    skipToFooterLabel: intl.formatMessage({
      id: 'app-skip-to-footer-link',
      defaultMessage: 'Skip to footer',
    }),
  }

  const styles = useMultiStyleConfig('Layout', { variant })

  const pageTitle = title ? `${partner.name} - ${title}` : `Avios - ${partner.name}`

  const promotionComponents = shouldLoadUser ? (
    <>
      <PromotionStickyBanner />
      <PromotionFirstVisitModal />
    </>
  ) : null

  return (
    <TagManagerProvider programmeCode={partner.slug}>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="Turn your everyday spending into exciting travel rewards - discover more &amp; shop with Avios."
        />
      </Head>
      <StickyBanner.StickyContainer>
        {promotionComponents}
        <NotificationBar />
      </StickyBanner.StickyContainer>
      <Box __css={styles.wrapper}>
        <SkipNavLink id={SKIP_TO_CONTENT_ID} sx={styles.skipLink}>
          <Button variant="link" tabIndex={-1}>
            {t.skipToContentLabel}
          </Button>
        </SkipNavLink>
        {sparse || (
          <SkipNavLink id={SKIP_TO_FOOTER_ID} sx={styles.skipLink}>
            <Button variant="link" tabIndex={-1}>
              {t.skipToFooterLabel}
            </Button>
          </SkipNavLink>
        )}
        <>
          {sparse || (
            <Header
              user={
                user && {
                  title: user.person.personName.title,
                  firstName: user.person.personName.firstName,
                  familyName: user.person.personName.familyName,
                }
              }
              accountBalanceAmount={totalAccount?.balance.amount}
              isLoading={isLoadingUserData}
              menuOptions={headerMenuOptions}
            />
          )}
          <Box as="main" id="content" __css={styles.content}>
            <SkipNavContent id={SKIP_TO_CONTENT_ID} />
            {isLoading || (user === undefined && shouldLoadUser) ? <SpinnerOverlay /> : children}
          </Box>
          {!userIsAuthenticated ? <ChatWidgetMock /> : null}
          {sparse || (
            <>
              <SkipNavContent id={SKIP_TO_FOOTER_ID} />
              <Footer />
            </>
          )}
        </>
      </Box>
    </TagManagerProvider>
  )
}

const BARCMemberLayout = () => {
  const styles = useMultiStyleConfig('Layout')
  const intl = useIntl()
  const t = {
    upgradeText: intl.formatMessage({
      id: 'upgrade-text',
      defaultMessage: 'Upgrade to the Executive Club <bold> for free </bold> to continue',
    }),
    upgradeText2: intl.formatMessage({
      id: 'upgrade-text2',
      defaultMessage: 'Executive Club members get access to our reward platform where you can:',
    }),
    upgradeBenefit1: intl.formatMessage({
      id: 'upgrade-benefit1',
      defaultMessage: 'Collect Avios when you spend cash',
    }),
    upgradeBenefit2: intl.formatMessage({
      id: 'upgrade-benefit2',
      defaultMessage: 'Spend your Avios on fantastic rewards',
    }),
    upgradeBenefit3: intl.formatMessage({
      id: 'upgrade-benefit3',
      defaultMessage: 'Track and manage your Avios balance',
    }),
    upgradeCta: intl.formatMessage({
      id: 'upgrade-cta',
      defaultMessage: 'Join our Executive Club now',
    }),
    redirectUrl: intl.formatMessage({
      id: 'redirect-url',
      defaultMessage: 'https://www.britishairways.com/travel/EXECENROL/public/en_gb',
    }),
    backToLogin: intl.formatMessage({
      id: 'back-to-login',
      defaultMessage: 'Back to login',
    }),
  }
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>British Airways, upgrade to Executive Club</title>
      </Head>
      <Center h="100vh" p="6" flexDir="column" __css={styles.barcLayoutWrapper}>
        <VStack spacing={6} maxW="23rem" alignItems="flex-start">
          <Box alignSelf="center">
            <Image
              src="/assets/british-airways-executive-club.svg"
              alt="British Airways Executive Club Logo"
              height={80}
              width={300}
            />
          </Box>
          <Box __css={styles.textBg} p={4}>
            <Text fontSize="2xl" fontFamily="Mylius Modern">
              {t.upgradeText}
            </Text>
          </Box>
          <Box>
            <Text fontSize="xl" fontFamily="Mylius Modern">
              {t.upgradeText2}
            </Text>
          </Box>
          <List spacing={2}>
            <ListItem>
              <Icon as={convertIconSetToChakraSVG(IconName.Enum['collect-avios'])} />
              {t.upgradeBenefit1}
            </ListItem>
            <ListItem>
              <Icon as={convertIconSetToChakraSVG(IconName.Enum['spend-avios'])} />
              {t.upgradeBenefit2}
            </ListItem>
            <ListItem>
              <Icon as={convertIconSetToChakraSVG(IconName.Enum.track)} />
              {t.upgradeBenefit3}
            </ListItem>
          </List>
          <Button as="a" target="_blank" href={t.redirectUrl} width="100%" textDecoration="none">
            {t.upgradeCta}
          </Button>
          <Link href="/auth/logout" textAlign="center" width="100%">
            {t.backToLogin}
          </Link>
        </VStack>
      </Center>
    </>
  )
}

export const UnauthenticatedLayout = (props: LayoutProps) => (
  <BaseLayout {...props} shouldLoadUser={false} isLoading={false} />
)

const Layout = (props: LayoutProps) => {
  const { user } = useUser()

  useDisruptionMessages()

  const userIsAuthenticated = useIsAuthenticatedUser()
  if (!userIsAuthenticated) {
    return <UnauthenticatedLayout {...props} />
  }

  const isBARCmember = user?.memberIdentifier?.identifier.startsWith('OWVE')

  if (isBARCmember) {
    return <BARCMemberLayout />
  }

  return <BaseLayout {...props} shouldLoadUser isLoading={false} />
}

export const AuthenticatedEmptyPageLayout = ({ children }: LayoutProps) => {
  const { user, userDataIsLoading } = useUser()
  const userIsLoaded = user && !userDataIsLoading

  return userIsLoaded ? (
    <Center h="100vh" p="6" flexDir="column">
      {children}
    </Center>
  ) : null
}

// By default we should be using the authenticated layout
export default Layout
