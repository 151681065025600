import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'container',
  'collectContainer',
  'collectLabel',
  'toolTip',
  'sliderContainer',
  'sliderArrow',
  'sliderDot',
  'sliderTrack',
  'sliderFilledTrack',
])

export const PricingSlider = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      background: 'brand.white',
      borderRadius: 'md',
      border: '1px solid',
      padding: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingLeft: 8,
      paddingRight: 8,
      minHeight: { base: 32, md: 'initial' },
    },
    collectContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      height: 5,
      width: 'full',
      fontSize: 'xs',
    },
    collectLabel: {
      alignItems: 'baseline',

      '>button': {
        height: 4,
        width: 4,
      },
    },
    toolTip: {
      ml: 1,
    },
    sliderContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr auto 1fr',
      alignItems: 'center',
    },
    sliderArrow: {
      fontSize: '3xl',
    },
    sliderTrack: {
      height: 2,
    },
    sliderDot: {
      border: 'solid 3px',
      boxSize: 5,
    },
  },
})
