import React from 'react'
import { chakra, Box, useMultiStyleConfig, SystemStyleObject, Text, Link } from '@chakra-ui/react'
import {
  documentToReactComponents,
  Options,
  RenderNode,
} from '@contentful/rich-text-react-renderer'
import { INLINES, MARKS, BLOCKS, Node } from '@contentful/rich-text-types'

import { IRichTextProps } from './ChakraRichText.types'

const defaultOptions: Options = {
  renderMark: {
    [MARKS.BOLD]: (text: React.ReactNode) => <chakra.strong>{text}</chakra.strong>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => children, // TODO: once other static content is refactored to use contentful sdk, this render function needs implementing to display inline assets from contentful rich text
  },
}

const getHeadingNodes = (headingStyle: SystemStyleObject) => {
  const getProps = (children: React.ReactNode) => ({ children, sx: headingStyle })
  const options: Options['renderNode'] = {
    [BLOCKS.HEADING_1]: (node, children) => <chakra.h1 {...getProps(children)} />,
    [BLOCKS.HEADING_2]: (node, children) => <chakra.h2 {...getProps(children)} />,
    [BLOCKS.HEADING_3]: (node, children) => <chakra.h3 {...getProps(children)} />,
    [BLOCKS.HEADING_4]: (node, children) => <chakra.h4 {...getProps(children)} />,
  }
  return options
}

const getLinkNode = (linkStyle: SystemStyleObject, openLinksInNewTab?: boolean) => ({
  [INLINES.HYPERLINK]: (node: Node, children: React.ReactNode) => {
    const { data } = node
    const { uri } = data
    const additionalProps = openLinksInNewTab
      ? { target: '_blank', rel: 'noopener noreferrer' }
      : null
    return (
      <Link sx={linkStyle} href={uri} {...additionalProps}>
        {children}
      </Link>
    )
  },
})

const getInlineEntryNodes = (
  inlineEntryRenderer?: IRichTextProps['inlineEntryRenderer']
): RenderNode => {
  const options: RenderNode = {
    [INLINES.EMBEDDED_ENTRY]: (node, children) =>
      inlineEntryRenderer?.(node.data.target) || children,
  }
  return options
}

const RichText: React.FC<IRichTextProps> = ({
  document,
  openLinksInNewTab,
  inlineEntryRenderer,
  inlineTextEntryRenderer,
}) => {
  const styles = useMultiStyleConfig('RichText', {})
  const options: Options = {
    renderMark: {
      ...defaultOptions.renderMark,
    },
    renderNode: {
      ...defaultOptions.renderNode,
      ...getHeadingNodes(styles.heading),
      ...getLinkNode(styles.hyperlink, openLinksInNewTab),
      ...getInlineEntryNodes(inlineEntryRenderer),
    },
    renderText: (text) => (inlineTextEntryRenderer ? inlineTextEntryRenderer(text) : text),
  }
  return <Box __css={styles.richText}>{documentToReactComponents(document, options)}</Box>
}

export default RichText
