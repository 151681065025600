import { ComponentMultiStyleConfig } from '@chakra-ui/react'

export const LocationDetailsModalWrapper: ComponentMultiStyleConfig = {
  parts: ['locationDetails', 'locationContact', 'mapContainer', 'phoneNumber'],

  baseStyle: {
    locationDetails: {
      display: 'grid',
      gridTemplateColumns: {
        base: '1fr',
        lg: '1fr 1fr',
      },
      gridTemplateRows: {
        base: 'auto',
      },
      gridTemplateAreas: {
        base: `'columnA' 'columnB'`,
        lg: `'columnA columnB'`,
      },
      'p, address, table': {
        marginBottom: 2,
      },
      p: {
        fontSize: 'md',
      },
      address: {
        fontStyle: 'normal',
        marginTop: 2,
      },
      table: {
        justifyContent: 'space-between',
        textAlign: 'left',
        width: '100%',
        fontSize: { base: 'md', md: 'lg' },
        pr: 8,
        tr: {
          th: {
            textAlign: 'left',
            fontWeight: 'light',
          },
          td: {
            textAlign: 'center',
          },
        },
      },
    },
    locationContact: {
      marginRight: { base: 0, lg: 10, xl: 20 },
    },
    mapContainer: {
      width: '100%',
      height: { base: '125px', md: '200px' },
      pt: { base: 3, md: 6 },
      pb: { base: 3, md: 6 },
    },
  },
}
