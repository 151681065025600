import { ComponentMultiStyleConfig } from '@chakra-ui/react'

export const PriceBreakdownContainer: ComponentMultiStyleConfig = {
  parts: ['priceBreakdownContainer', 'definitionGroup'],
  baseStyle: {
    priceBreakdownContainer: {
      dl: {
        mb: 3,
      },
    },
    definitionGroup: {
      p: {
        __first: {
          fontsize: 'sm',
        },
      },
    },
  },
}
