import { helpers } from './base'

const primaryTeal = 'brand.primaryTeal'
const darkCityGreen = 'brand.darkCityGreen'

export const PricingSlider = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    container: {
      border: 0,
      borderTop: '1px solid',
      borderColor: 'rgba(var(--chakra-colors-rgb-alDarkCityGreen), 0.2)',
      borderRadius: '0 0 0.5rem 0.5rem',
    },
    collectContainer: {
      color: primaryTeal,
    },
    collectLabel: {
      '>button': {
        color: primaryTeal,
      },
    },
    sliderArrow: {
      color: darkCityGreen,
    },
    sliderDot: {
      color: darkCityGreen,
    },
    sliderTrack: {
      borderRadius: 'lg',
    },
    sliderFilledTrack: {
      backgroundColor: 'rgba(var(--chakra-colors-rgb-alDarkCityGreen), 0.8)',
    },
  }),
})
