import { ComponentMultiStyleConfig } from '@chakra-ui/react'
import merge from 'lodash/merge'
import { ListBox as base } from './base'

export const ListBox: ComponentMultiStyleConfig = merge({}, base, {
  baseStyle: {
    icon: {
      color: 'brand.coastalJetty',
    },
    list: {
      _focusVisible: {
        outline: '1px solid',
        outlineColor: 'brand.coastalJetty',
      },
      border: 'none',
      borderRadius: '0.3125rem',
      boxShadow: '0 6px 9px 0 rgb(0 0 0 / 10%)',
    },
    item: {
      color: 'brand.black',
      _disabled: {
        color: 'rgba(var(--chakra-colors-rgb-alCoolGrey8), 0.7)',
      },
    },
  },
})
