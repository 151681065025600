import { Basket } from '@reward-platform/order-schemas/basket'
import { useQuery, useQueryClient, UseQueryResult } from 'react-query'
import { useNotification } from '~/components/shared/Notifications'
import { useIsAuthenticatedUser } from '~/context/user'
import {
  addCarHireToBasket,
  addHotelToBasket,
  addExperienceToBasket,
  clearItemsFromBasket,
  getBasket,
  removeItemFromBasket,
  addFlightToBasket,
} from '~/services/basketService'

export const BASKET_KEY = 'basket'

type UseBasketReturnType = {
  basket: UseQueryResult<Basket>
  addCarHire: typeof addCarHireToBasket
  addHotel: typeof addHotelToBasket
  addExperience: typeof addExperienceToBasket
  addFlight: typeof addFlightToBasket
  removeItem: typeof removeItemFromBasket
  clearItems: typeof clearItemsFromBasket
}

type UseBasketQueryParams = {
  onError?: (message: string) => void
  isEnabled?: boolean
}

export const useBasketQuery = ({ onError, isEnabled }: UseBasketQueryParams) =>
  useQuery({
    queryKey: [BASKET_KEY],
    queryFn: () => getBasket(),
    onError: (e: Error) => onError?.(e.message),
    staleTime: 0,
    refetchOnWindowFocus: true,
    refetchOnMount: false,
    enabled: !!isEnabled,
  })

type UseBasketParams = {
  isEnabled?: boolean
  onError?: (message: string) => void
}

const useBasket = ({ onError, isEnabled }: UseBasketParams = {}): UseBasketReturnType => {
  const userIsAuthenticated = useIsAuthenticatedUser()
  const enabled = isEnabled ?? !!userIsAuthenticated
  const { addNotification } = useNotification()
  const queryClient = useQueryClient()

  const invalidateBasketQuery = () => queryClient.invalidateQueries([BASKET_KEY])

  const basket = useBasketQuery({ onError: onError ?? addNotification, isEnabled: enabled })

  return {
    basket,
    addCarHire: async (offer) => {
      await addCarHireToBasket(offer)
      await invalidateBasketQuery()
    },
    addHotel: async (offer) => {
      await addHotelToBasket(offer)
      await invalidateBasketQuery()
    },
    addExperience: async (offer) => {
      await addExperienceToBasket(offer)
      await invalidateBasketQuery()
    },
    addFlight: async (offer) => {
      await addFlightToBasket(offer)
      await invalidateBasketQuery()
    },
    clearItems: async () => {
      await clearItemsFromBasket()
      await invalidateBasketQuery()
    },
    removeItem: async (recommendationId: string) => {
      await removeItemFromBasket(recommendationId)
      await invalidateBasketQuery()
    },
  }
}

export const useBasketItemsCount = (): number => {
  const userIsAuthenticated = useIsAuthenticatedUser()
  const {
    basket: { data: basket },
  } = useBasket({
    isEnabled: !!userIsAuthenticated,
    onError: () => {
      // no op
    },
  })
  const items = basket?.items

  if (items) {
    return Object.values(items).reduce((sum, itemCategory) => sum + itemCategory.length, 0)
  }

  return 0
}

export default useBasket
