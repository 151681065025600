import { useEffect, useState } from 'react'
import { isClient, isLocal } from '~/utils/envChecks'
import {
  getBrowserFromUserAgent,
  getPageTitle,
  getPlatformFromUserAgent,
} from '~/utils/windowNavigator'
import { useRouter } from 'next/router'
import { usePartner } from '~/context/partner'
import { useAuthToken } from '@reward-platform/auth'
import { MemberTypesPerson } from '@reward-platform/members-api-client'

const CHATBOT_CONTAINER_ID = 'chatbot'
const userAgent = isClient() ? window.navigator.userAgent : 'SSR'

const isChatWidgetEnabled = () => isClient() && !!window.AmazonCustomChatWidget
const useChatWidget = (membershipId?: string, person?: MemberTypesPerson) => {
  const [token, setToken] = useState<string>()
  const partner = usePartner()
  const router = useRouter()

  useAuthToken((authToken) => {
    setToken(authToken)
  })

  const widget = window.AmazonCustomChatWidget?.ChatInterface

  useEffect(() => {
    const url = process.env.NEXT_PUBLIC_CHAT_WIDGET_URL
    let chatWidget: any
    if (url && token && widget && isChatWidgetEnabled() && person && membershipId) {
      try {
        chatWidget = widget.init({
          containerId: CHATBOT_CONTAINER_ID,
          serviceProvider: partner.provider,
          membershipId,
          customerName: `${person.personName.firstName} ${person.personName.familyName}`,
          customerEmail: person.emailAddress?.email,
          authorizedToken: `Bearer ${token}`,
          additionalData: {
            channel: 'Webchat',
            platform: getPlatformFromUserAgent(userAgent),
            browser: getBrowserFromUserAgent(userAgent),
          },
          pageTitle: getPageTitle(router.asPath),
        })

        const openChat = window.sessionStorage.getItem('openChat') === 'true'
        if (openChat) {
          setTimeout(() => {
            const chatBot = document.getElementById('chatbot')?.childNodes[0] as HTMLButtonElement
            chatBot?.click()
          }, 0)
          window.sessionStorage.removeItem('openChat')
        }
      } catch (error) {
        if (!isLocal()) {
          // eslint-disable-next-line no-console
          console.warn('Chat widget is missing configuration!', error)
        }
      }
    }
    return () => {
      if (chatWidget?.detach) {
        chatWidget.detach()
      }
    }
  }, [token, partner, person, router.asPath, widget, membershipId, router])
}

const CHATBOT_QUERY_KEY = 'chatbot'
const CHATBOT_OPEN_VALUE = 'open'

export const useChatbotOpen = () => {
  const router = useRouter()
  return () =>
    router.push({
      pathname: router.pathname,
      query: { ...router.query, [CHATBOT_QUERY_KEY]: CHATBOT_OPEN_VALUE },
    })
}

export default useChatWidget
