import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const itineraryHelpers = createMultiStyleConfigHelpers([
  'container',
  'title',
  'property',
  'itemWrapper',
  'itemHeader',
  'roomSummary',
  'carHireExtrasSummary',
  'additionalInfo',
])

export const Itinerary = itineraryHelpers.defineMultiStyleConfig({
  baseStyle: itineraryHelpers.definePartsStyle({
    container: {
      paddingLeft: { base: 0, md: 6 },
    },
    property: {
      p: {
        m: '0',
        lineHeight: 'base',
      },
    },
    itemWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4',
    },
    itemHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      h3: {
        mt: '6',
      },
    },
    roomSummary: {
      display: 'flex',
      gap: 8,
      flexDirection: 'row',
    },
    carHireExtrasSummary: {
      display: 'flex',
      flexDirection: 'column',
    },
  }),
})
