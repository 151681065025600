import { helpers } from '~/components/hotels/details/themes/base'

export const HotelDetails = helpers.defineMultiStyleConfig({
  baseStyle: {},
  variants: {
    whatsNearby: {
      sectionCenter: {
        backgroundColor: 'brand.white',
        borderRadius: 'sm',
        boxShadow: 'brand.boxShadow',
        padding: 6,
      },
    },
  },
})
