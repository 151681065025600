import { useCallback, useMemo } from 'react'
import { pushEventToDataLayer, Navigation, DataLayerObject } from '~/utils/googleTagManager'
import { usePartner } from '~/context/partner'
import { isProduction } from '~/utils/envChecks'

type NavigationData = {
  navType: Navigation['nav_type']
  navAction: Navigation['nav_action']
  navElement?: Navigation['nav_element']
  domain_environment?: Navigation['domain_env']
  programmeCode?: Navigation['programmeCode']
  channel?: Navigation['channel']
  extra?: Record<string, unknown>
}

const useNavigationAnalytics = () => {
  const partner = usePartner()

  const onNavigate = useCallback(
    ({ navType, navAction, navElement, channel, extra }: NavigationData) => {
      try {
        pushEventToDataLayer('navigation', {
          nav_type: navType,
          nav_action: navAction,
          nav_element: navElement,
          domain_environment: isProduction() ? 'prod' : 'non_prod',
          programmeCode: partner.slug,
          channel,
          ...extra,
        } as DataLayerObject)
      } catch (error: unknown) {
        // No op
      }
    },
    [partner.slug]
  )

  return useMemo(
    () => ({
      onNavigate,
    }),
    [onNavigate]
  )
}

export default useNavigationAnalytics
