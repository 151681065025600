import { omit } from 'lodash'
import { isClient, isLocal, isProduction } from './envChecks'
import { gtmGA3, gtmGA4 } from './googleTagManager/googleTagManager.types'

export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID
export const NO_DATALAYER_VALUE = 'no_datalayer_value'

export const defaultBaseDataLayerObject = {
  domain_environment: isProduction() ? 'prod' : 'non_prod',
}

declare global {
  interface Window {
    google_tag_manager: {
      [CTM_ID: string]: {
        dataLayer: {
          reset: () => void
        }
      }
    }
  }
}

export type BaseDataLayerObject = {
  domain_environment?: string
  event?: string
  programmeCode?: string
  channel?: string // flights | hotels | car hire | experiences
  balance?: number | string
  pageLocation?: string
  booking_currency?: string
  currency?: string
  memberID?: string
}

type FlightSearchDataLayerObject = {
  currency?: string
  custom_timestamp?: number
  referrer?: string
  departure_date?: string
  arrival_date?: string
  booking_start_location?: string
  booking_end_location?: string
} & BaseDataLayerObject

type FlightResultsDataLayerObject = {
  origin: string
  destination: string
  departure_date: string
  arrival_date: string
  flightsNumber: number
  adults: number
  children: number
  childrenAges: Array<{ child: string; age: number }>
} & BaseDataLayerObject

type FlightResultsDataLayerObject2 = {
  booking_start_location: string
  from_date: string
  to_date?: string
  traveller_count: number
  search_state: string
  result_count: number
}

type FlightDetailsDataLayerObject = {
  flight_from: string
  flight_to: string
  flight_code: string
  flight_time: string
  flight_route: string
}

type FlightDetailsDataLayerObject2 = {
  collected_avios: number
  avios_percentage: number
  items: FlightDetailsItemDataLayerObject[]
}

type FlightSelectionDataLayerObject = {
  event: string
  currency: string
  items: FlightSelectionDataLayerObject2
}

type FlightSelectionDataLayerObject2 = {
  item_id: string
  item_name: string
  item_brand: string
  item_category: string
  avios_value: number
  cash_value: number
  avios_percentage: number
  quantity: number
}

type FlightContinueToBasketDataLayerObject = {
  event: string
  currency?: string
  value: number
  avios_value: number
  cash_value: number
  avios_percentage: number
  items: FlightContinueToBasketDataLayerItemObject[]
}

type FlightContinueToBasketDataLayerItemObject = {
  item_id: string
  item_name: string
  item_brand?: string
  item_category: string
  avios_value: number
  cash_value: number
  collected_avios?: number
  price: number
  avios_percentage: number
  quantity: number
}

type FlightDetailsItemDataLayerObject = {
  index: number
  item_name: string
  item_id: string
  item_brand: string
  item_category: string
  item_list_name: string
  item_list_id: string
  price: number
  quantity: number
}

type HotelSearchDataLayerObject = {
  destination?: string
  checkInDate?: string
  checkOutDate?: string
  rooms?: Array<{ adults: number; children: number }>
  num_of_adults?: number
  num_of_children?: number
  num_of_rooms?: number
  shownResults?: number
  totalResults?: number
  totalResultPages?: number
}
type HotelSearchDataLayerObject2 = {
  booking_start_location: string
  booking_end_location?: string
  from_date: string
  to_date: string
  traveller_count: number
  search_state: 'initial' | 'refined'
  result_count: number
}
export type HotelShareDataLayerObject = {
  channel: string
  nav_element: string
  nav_type: string
  nav_action?: string
  pageLocation: string
  hotel_name: string
  traveller_count: number
  checkInDate: string
  checkOutDate: string
  guest_rating: string
  star_rating: string
  custom_timestamp: number
}
type HotelDataLayerObject = {
  name?: string
  propertyRating?: number
  guestRating?: number
  amenities?: Array<{ title: string; amenities?: Array<string> }>
}

export type SearchDataLayerObject = {
  search?: HotelSearchDataLayerObject
  hotel?: HotelDataLayerObject
  filters?: Record<string, string | string[]>
  filter_state?: string
  mapDisplayMode?: string
  shownResults?: number
  totalResults?: number
} & BaseDataLayerObject

type CarHireResultsDataLayerObject = {
  finalPickupLocation?: string
  finalDropLocation?: string
  pickUpDateTime?: string
  dropOffDateTime?: string
  u30Driver?: boolean
  diffDropLocation?: boolean
}

type CarHireDetailsDataLayerObject = {
  carHire?: {
    name?: string
    vendorName?: string
    seats?: string
    aircon?: string
    doors?: string
    vehicleClassName?: string
    vehicleTransmission?: string
  }
}

type CarHireSearchDataLayerObject = {
  search?: CarHireResultsDataLayerObject
}
type CarHireSearchDataLayerObject2 = {
  different_drop_off?: string
  driver_under_30?: string
  driver_dob?: string
  booking_start_location: string
  booking_end_location?: string
  from_date: string
  to_date: string
  traveller_count?: number
  search_state: 'initial' | 'refined'
  result_count?: number
}

type ExperienceSearchDataLayerObject = {
  destination: string
  dateCheckIn: string
  dateCheckOut: string
}

type ExperienceResultsDataLayerObject = {
  experience?: ExperienceSearchDataLayerObject
  shownResults?: number
}

type PurchaseItemGA4 = {
  item_id: string
  item_name: string
  item_variant: string
  quantity: string
  item_category: string
  price?: string
  cash_value?: number
  value?: number
  taxes_and_fees?: number
  collected_avios?: number
}

type HotelPurchaseItemGA4 = PurchaseItemGA4 & {
  room_capacity?: number
  bed_size?: string
}

type CarHirePurchaseItemGA4 = PurchaseItemGA4

type FlightPurchaseItemGA4 = PurchaseItemGA4 & {
  flight_route?: string
  filter_flight_type_outbound?: string
  filter_flight_type_inbound?: string
}

export type PurchaseConfirmationGA4 = {
  ecommerce: {
    transaction_id: string
    currency: string
    value?: number
    payment_type?: string
    items: (CarHirePurchaseItemGA4 | HotelPurchaseItemGA4 | FlightPurchaseItemGA4)[]
  }
}

export type HotelBasketAnalyticsItem = {
  free_wifi?: string
  front_desk_24h?: string
  express_checkout?: string
  laundry_service?: string
  spa?: string
  smoke_free?: string
  refundable?: string
  breakfast?: string
  bed_size?: number
  room_capacity?: string
  amenities?: string[]
  room_size?: number
}

export type CarHireBasketAnalyticsItem = {
  seats?: string
  transmission?: string
  mileage_allowance?: string
  door_count?: string
  air_con?: string
  luggage_capacity?: string
  insurance_cover?: string
  additional_driver?: string
  child_seat?: string
}

export type FlightBasketAnalyticsItem = {
  flight_route?: string
  filter_flight_type_outbound?: string
  filter_flight_type_inbound?: string
  adults?: string
  children?: string
}

export type BaseBasketAnalyticsItemGA4 = {
  item_id: string
  item_name: string
  item_variant?: string
  item_category?: string
  item_category2?: string
  item_category3?: string
  item_category4?: string
  item_brand?: string
  price?: number
  quantity?: number | string
  avios_value?: number
  cash_value?: number
  collected_avios?: number
  taxes_and_fees?: number
  value?: number
}

export type HotelBasketAnalyticsItemGA4 = BaseBasketAnalyticsItemGA4 & HotelBasketAnalyticsItem
export type CarHireBasketAnalyticsItemGA4 = BaseBasketAnalyticsItemGA4 & CarHireBasketAnalyticsItem
export type FlightBasketAnalyticsItemGA4 = BaseBasketAnalyticsItemGA4 & FlightBasketAnalyticsItem

export type BasketAnalyticsItemGA4 =
  | HotelBasketAnalyticsItemGA4
  | CarHireBasketAnalyticsItemGA4
  | FlightBasketAnalyticsItemGA4

export type BasketContentsGA4 = {
  currency?: string
  ecommerce: {
    value?: number
    avios_value?: number
    cash_value?: number
    collected_avios?: number
    items: (
      | HotelBasketAnalyticsItemGA4
      | CarHireBasketAnalyticsItemGA4
      | FlightBasketAnalyticsItemGA4
    )[]
  }
}

export type AddPaymentInfo = {
  ecommerce: {
    payment_type: string
    items: (HotelBasketAnalyticsItemGA4 | CarHireBasketAnalyticsItemGA4)[]
  }
}

type NewPageView = {
  page_location: string
  event?: string
  memberID?: string
  booking_currency?: string
  currency?: string
  balance?: number
  channel?: string
}

export type Navigation = {
  nav_type: 'cta' | 'text link' | 'notification' | 'footer link' | 'section link' | 'banner'
  nav_action: string
  nav_element?: string
  menu_level?: string
  cancellation_policy?: string
  page_location?: string
  referrer?: string
  domain_env?: string
  programmeCode?: string
  channel?: string
}

export type PopularDestinationNavigation = Navigation & {
  destination?: string
  departure_date?: string
  arrival_date?: string
  origin?: string
}

export type SecureFlightNavigation = Navigation & {
  gender_provided: string
  dob_provided: string
}

export type ViewTerms = {
  terms_type: string
}

export type SortResults = {
  sort_setting: string
  sort_state: string
}

export type Experiment = {
  experiment_id: string
  experiment_name: string
  experiment_variant: string
  experiment_variant_id?: string
  experiment_campaign?: string
}

export type CarFilter = {
  fuel_type?: string
  gearbox?: string
  vendor?: string
  budget_avios?: string
  budget_cash?: string
  size?: string
  filter_state?: string
}

export type HotelFilter = {
  property_type: string
  guest_rating: string
  star_rating: string
  filter_state: string
}

export type HotelVariant = {
  hotel_name: string
  variant_name: string
  variant_context: string
}

export type OrderHistory = {
  total_bookings: number
}

export type PromotionLayerObject = {
  promoCode: string
  promoName: string
  promoType: string
  promoProduct: string
}

export type DataLayerObject =
  | SearchDataLayerObject
  | gtmGA3
  | gtmGA4
  | FlightSearchDataLayerObject
  | FlightResultsDataLayerObject
  | FlightResultsDataLayerObject2
  | FlightSelectionDataLayerObject
  | FlightSelectionDataLayerObject2
  | FlightContinueToBasketDataLayerObject
  | FlightContinueToBasketDataLayerItemObject
  | FlightDetailsDataLayerObject
  | FlightDetailsDataLayerObject2
  | HotelSearchDataLayerObject
  | CarHireDetailsDataLayerObject
  | CarHireSearchDataLayerObject
  | PurchaseConfirmationGA4
  | ExperienceSearchDataLayerObject
  | ExperienceResultsDataLayerObject
  | BasketContentsGA4
  | AddPaymentInfo
  | Navigation
  | NewPageView
  | ViewTerms
  | SortResults
  | Experiment
  | CarFilter
  | HotelSearchDataLayerObject2
  | HotelShareDataLayerObject
  | CarHireSearchDataLayerObject2
  | HotelFilter
  | HotelVariant
  | OrderHistory
  | SecureFlightNavigation
  | PopularDestinationNavigation
  | PromotionLayerObject

if (!GTM_ID && !isLocal()) {
  // eslint-disable-next-line no-console
  console.warn('Google tag manager is missing configuration')
}

export const googleTagManagerScript = GTM_ID
  ? `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `
  : `(function(){});`

const createDataLayerObject = (data?: DataLayerObject) => {
  const userIsAuthenticated = sessionStorage.getItem('authenticated_user')
  return {
    ...data,
    custom_timestamp: Date.now(),
    isLoggedIn: userIsAuthenticated === 'true',
  }
}

export const pushToDataLayer = (data?: DataLayerObject): void => {
  if (isClient()) {
    window.dataLayer = window.dataLayer ?? []
    window.dataLayer.push(createDataLayerObject(data))
  }
}

export const pushEventToDataLayer = (eventName: string, data?: DataLayerObject): void => {
  if (isClient()) {
    window.dataLayer = window.dataLayer ?? []
    window.dataLayer.push({ event: eventName, ...createDataLayerObject(data) })
  }
}

export const updateEventInDataLayer = (eventName: string, data?: DataLayerObject): void => {
  if (!isClient) {
    return
  }
  window.dataLayer = window.dataLayer ?? []
  const eventIndex = window.dataLayer.findIndex((layer) => layer.event === eventName)
  if (eventIndex > -1) {
    const layer = window.dataLayer[eventIndex]
    window.dataLayer[eventIndex] = { ...layer, ...createDataLayerObject(data) }
  } else {
    pushEventToDataLayer(eventName, data)
  }
}

export const compareAndPushEventInDataLayer = (eventName: string, data?: DataLayerObject): void => {
  if (!isClient) {
    return
  }
  window.dataLayer = window.dataLayer ?? []

  const eventIndex = window.dataLayer.findIndex((layer) => layer.event === eventName)

  if (eventIndex > -1) {
    const layer = window.dataLayer[eventIndex] as DataLayerObject
    const compareLayer = omit(layer, [
      'custom_timestamp',
      'isLoggedIn',
      'gtm.uniqueEventId',
      'event',
    ])
    if (JSON.stringify(data) !== JSON.stringify(compareLayer)) {
      pushEventToDataLayer(eventName, data)
    }
  } else {
    pushEventToDataLayer(eventName, data)
  }
}

export const resetAndPushNewPageView = (data: DataLayerObject): void => {
  if (isClient()) {
    let event = 'new_page_view_reset_failed'
    window.dataLayer = window.dataLayer ?? []
    // reset the window datalayer to retrigger events on client side navigation
    // keeps the first 7 elements in the array, these are needed for the datalayer to work
    if (window.dataLayer.length > 0) {
      window.dataLayer.splice(7, window.dataLayer.length - 7)
    }
    if (GTM_ID && window.google_tag_manager?.[GTM_ID]?.dataLayer) {
      window.google_tag_manager[GTM_ID].dataLayer.reset()
      event = 'new_page_view'
    }

    pushEventToDataLayer(event, data)
  }
}
