export type DatadogLogErrorContext = {
  message: string
  stack?: string
  code?: string
  description: string
  httpStatus?: number
  [key: string]: any
}

export const DATADOG_LOG_META_KEY = 'reward_platform_microservice_request'

export type DatadogLogMetaContext = {
  partner: 'EI' | 'BA' | 'VY'
  correlationId?: string
  [key: string]: any
}

export const createDatadogMetaContext = (meta: DatadogLogMetaContext) => ({
  [DATADOG_LOG_META_KEY]: meta,
})
