import { itineraryHelpers } from './base'

const primaryTeal = 'var(--chakra-colors-brand-primaryTeal)'

export const Itinerary = itineraryHelpers.defineMultiStyleConfig({
  baseStyle: itineraryHelpers.definePartsStyle({
    property: {
      p: {
        fontSize: 'sm',
        color: 'brand.coolgrey10',
      },
    },
    title: {
      fontSize: 'xl',
    },
    itemHeader: {
      h3: {
        fontSize: 'lg',
      },
      button: {
        fontSize: 'sm',
        marginLeft: 'auto',
      },
    },
    roomSummary: {
      fontWeight: 'light',
      color: primaryTeal,
      fontSize: 'sm',
    },
    itemWrapper: {
      h4: {
        color: primaryTeal,
      },
    },
    additionalInfo: {
      borderRadius: 'lg',
      bg: 'brand.bgteal10',
      ul: {
        listStyle: 'disc',
        color: 'brand.primaryTeal',
      },
    },
  }),
})
