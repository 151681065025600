import { BaseOrderItem, Order, OrderItemType } from '@reward-platform/order-schemas/order'
import * as R from 'ramda'
import { useQuery, useQueryClient } from 'react-query'
import { getOrder } from '~/services/ordersService'

const QUERY_KEY = 'order'

// filter out failed items in the order
const filterFailedItems = (order: Order) => {
  const orderItems = order.items

  const filteredOrderItems =
    orderItems &&
    Object.keys(orderItems).reduce((acc, itemType) => {
      if (!acc) {
        return undefined
      }

      const orderItemType = itemType as OrderItemType
      const itemsOfType = orderItems[orderItemType] as unknown as BaseOrderItem[]
      return {
        ...acc,
        [orderItemType]: itemsOfType.filter((item) => item.status === 'READY_TO_PROCEED'),
      }
    }, {} as Order['items'])

  return R.assocPath(['items'], filteredOrderItems, order)
}

const useOrder = (orderId?: string, filterOutFailedItems?: boolean) => {
  const queryClient = useQueryClient()
  return {
    orderData: useQuery(
      [QUERY_KEY, orderId],
      () =>
        getOrder(orderId ?? '').then((data) => {
          if (filterOutFailedItems) {
            return filterFailedItems(data)
          }
          return data
        }),
      {
        staleTime: 0,
        cacheTime: 10,
        retry: 1,
        enabled: Boolean(orderId),
      }
    ),
    invalidate: () => queryClient.invalidateQueries([QUERY_KEY]),
  }
}

export default useOrder
