import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useNotification } from '~/components/shared/Notifications'
import { usePartner } from '~/context/partner'
import { useUser } from '~/context/user'
import { getDisruptionMessages } from '~/services/contentService'

const useDisruptionMessages = ({ enabled = true } = {}) => {
  const { userLocale } = useUser()
  const { theme: partnerTheme } = usePartner()
  const { addNotification } = useNotification()

  const { data: disruptionMessages, isFetched: disruptionMessageFetched } = useQuery(
    ['disruptionMessage'],
    () => getDisruptionMessages(partnerTheme, userLocale),
    { enabled }
  )

  useEffect(() => {
    if (disruptionMessageFetched && disruptionMessages) {
      disruptionMessages.forEach(({ message, notificationMessage }) => {
        if (message) {
          addNotification(message, 'INFO', undefined, notificationMessage.json)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disruptionMessageFetched, disruptionMessages])
}

export default useDisruptionMessages
