import { ExperimentFeatureFlagKey } from './devcycle-variables'

type ExperimentDetails = {
  key: ExperimentFeatureFlagKey
  name: string
  description?: string
}

export type ExperimentVariableValue = boolean | number // | string

export type ExperimentFlag<T extends ExperimentVariableValue = ExperimentVariableValue> =
  ExperimentDetails & {
    value: T
    enabled?: boolean
  }

export const EXPERIMENTS: Array<ExperimentDetails> = [
  {
    key: 'hotel-card-cta',
    name: 'Hotel Card CTA',
  },
  {
    key: 'hotel-results-banner',
    name: 'Hotel Results Banner',
  },
  {
    key: 'hotel-card-pricing-ladder',
    name: 'Hotel Card Pricing Ladder',
  },
  {
    key: 'car-hire-results-banner',
    name: 'Car Hire Results Banner',
  },
  {
    key: 'hotel-location-search-ordering',
    name: 'Hotel Location Search Ordering',
  },
]
