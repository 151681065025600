import { ComponentMultiStyleConfig } from '@chakra-ui/react'

export const ImageCard: ComponentMultiStyleConfig = {
  parts: ['figure', 'link', 'caption'],
  baseStyle: {
    figure: {
      width: {
        base: '300px',
        sm: '100%',
        md: '100%',
      },
    },
    link: {
      textDecoration: 'none',
      color: 'currentColor',
    },
    caption: {
      marginBottom: 2.5,
    },
  },
}
