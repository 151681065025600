import { z } from 'zod'
import { Basket, ExperienceLanguageGuideSchema } from '../basket'
import { TotalBalance } from '../pricebreakdown/types'
import {
  TravelerInformationContactSchema,
  TravelerInformation,
  TravelerInformationAddressSchema,
} from './traveler-information'

export const MAX_SPECIAL_REQUIREMENTS_LENGTH = 250

export type TotalCashAndAvios = Pick<TotalBalance, 'cashTotal' | 'aviosSpent'>

export type AdditionalDetailsParams = {
  basket: Basket
  totalBalance: TotalCashAndAvios
  travelerInformation?: TravelerInformation
}

export const AccountHolderDataCollectionMethodSchema = z.enum([
  'COLLECT_PRIMARY_DRIVER_EMAIL_ADDRESS',
  'COLLECT_PRIMARY_DRIVER_CONTACT_DETAILS',
  'COLLECT_MAIN_ACCOUNT_HOLDER_CONTACT_DETAILS',
  'COLLECT_NOTHING',
])

export type AccountHolderDataCollectionMethod = z.infer<
  typeof AccountHolderDataCollectionMethodSchema
>

export const resolveAccountHolderDataCollectionMethod = ({
  basket,
  totalBalance,
  travelerInformation,
}: AdditionalDetailsParams): AccountHolderDataCollectionMethod => {
  const isFullAviosPayment = Boolean(totalBalance.aviosSpent > 0 && totalBalance.cashTotal.eq(0))
  const hasCarHireBasketItem = Boolean(basket.items.CAR_HIRE?.length > 0)

  const isMainTravelerAccountHolder = Boolean(travelerInformation?.mainTraveler.isAccountHolder)

  if (isFullAviosPayment) {
    if (hasCarHireBasketItem && isMainTravelerAccountHolder) {
      return AccountHolderDataCollectionMethodSchema.Enum.COLLECT_PRIMARY_DRIVER_EMAIL_ADDRESS
    }
    if (hasCarHireBasketItem) {
      return AccountHolderDataCollectionMethodSchema.Enum.COLLECT_PRIMARY_DRIVER_CONTACT_DETAILS
    }
    if (!isMainTravelerAccountHolder) {
      return AccountHolderDataCollectionMethodSchema.Enum
        .COLLECT_MAIN_ACCOUNT_HOLDER_CONTACT_DETAILS
    }
  }

  if (hasCarHireBasketItem) {
    return AccountHolderDataCollectionMethodSchema.Enum.COLLECT_PRIMARY_DRIVER_EMAIL_ADDRESS
  }

  return AccountHolderDataCollectionMethodSchema.Enum.COLLECT_NOTHING
}

const EmailAddressSchema = z.string().trim().email().min(1)

const ContactDetailsSchema = TravelerInformationAddressSchema.merge(
  z.object({
    emailAddress: EmailAddressSchema,
    phone: TravelerInformationContactSchema.shape.phone,
  })
)

export type ContactDetails = z.infer<typeof ContactDetailsSchema>

export const AccountHolderDataCollectionSchema = z.discriminatedUnion('collectionMethod', [
  z.object({
    collectionMethod: z.enum([
      AccountHolderDataCollectionMethodSchema.Enum.COLLECT_MAIN_ACCOUNT_HOLDER_CONTACT_DETAILS,
      AccountHolderDataCollectionMethodSchema.Enum.COLLECT_PRIMARY_DRIVER_CONTACT_DETAILS,
    ]),
    data: ContactDetailsSchema,
  }),
  z.object({
    collectionMethod: z.literal(
      AccountHolderDataCollectionMethodSchema.Enum.COLLECT_PRIMARY_DRIVER_EMAIL_ADDRESS
    ),
    data: z.object({ emailAddress: EmailAddressSchema }),
  }),
  z.object({
    collectionMethod: z.literal(AccountHolderDataCollectionMethodSchema.Enum.COLLECT_NOTHING),
    data: z.preprocess(() => undefined, z.undefined()),
  }),
])

export type AccountHolderDataCollection = z.infer<typeof AccountHolderDataCollectionSchema>

const createHotelAdditionalDetailsSchema = (params: AdditionalDetailsParams) => {
  const hasHotelItem = Boolean(params.basket.items.HOTEL?.length > 0)
  if (!hasHotelItem) {
    return z.preprocess(() => undefined, z.undefined()).optional()
  }

  return z.object({
    specialRequirements: z.string().max(MAX_SPECIAL_REQUIREMENTS_LENGTH).optional(),
  })
}

const createCarHireAdditionalDetailsSchema = (params: AdditionalDetailsParams) => {
  const hasCarHireItem = Boolean(params.basket.items.CAR_HIRE?.length > 0)
  if (!hasCarHireItem) {
    return z.preprocess(() => undefined, z.undefined())
  }

  return z.object({
    flightNumber: z
      .string()
      .trim()
      .toUpperCase()
      .regex(/^[A-Z]{2}\d{1,6}$/)
      .optional()
      .or(z.literal('')),
    avisPreferredMembershipNumber: z
      .string()
      .trim()
      .toUpperCase()
      .regex(/^[A-Za-z0-9]{1,6}$/)
      .optional()
      .or(z.literal('')),
  })
}

const createExperienceAdditionalDetailsSchema = (params: AdditionalDetailsParams) => {
  const { EXPERIENCE } = params.basket.items
  const hasExperienceItem = Boolean(EXPERIENCE?.length > 0)
  if (!hasExperienceItem) {
    return z.preprocess(() => undefined, z.undefined()).optional()
  }
  const hasLanguageGuide = EXPERIENCE.at(0)?.languageGuides?.some(({ type, language }) =>
    Boolean(type && language)
  )

  return z.object({
    specialRequirements: z.string().max(MAX_SPECIAL_REQUIREMENTS_LENGTH).optional(),
    languageGuide: hasLanguageGuide
      ? ExperienceLanguageGuideSchema
      : ExperienceLanguageGuideSchema.optional(),
  })
}

export const createAdditionalDetailsSchema = (params: AdditionalDetailsParams) => {
  const collectionMethod = resolveAccountHolderDataCollectionMethod(params)
  return z
    .object({
      hotel: createHotelAdditionalDetailsSchema(params),
      carHire: createCarHireAdditionalDetailsSchema(params),
      experience: createExperienceAdditionalDetailsSchema(params),
    })
    .merge(
      z.object({
        accountHolder: AccountHolderDataCollectionSchema.refine(
          (val) => val.collectionMethod === collectionMethod
        ),
      })
    )
}

export type AdditionalDetails = z.infer<ReturnType<typeof createAdditionalDetailsSchema>>
