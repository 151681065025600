// Disable not-notation auto-fix for eslint to avoid noPropertyAccessFromIndexSignature TS error
/* eslint-disable dot-notation */

import { useVariableValue } from '@devcycle/react-client-sdk'
import { Auth0Provider, Auth0ProviderOptions, useAuth0 } from '@auth0/auth0-react'
import { useRouter } from 'next/router'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { z } from 'zod'
import { isUnauthenticatedPage } from './authUtils'
import { Login } from './Login'

type AuthenticatedBoundaryProps = PropsWithChildren<{
  spinner: JSX.Element
  onTokenReceived: (token: string) => void
  isLoading?: boolean
}>

// This may or may not over time expand to validate the entire token contents
export const AuthTokenSubjectSchema = z.string().startsWith('auth0|')

export const useAuthToken = (onTokenReceived?: (token: string) => void) => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0()
  const [accessToken, setAccessToken] = useState<string>()

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((token) => {
        if (onTokenReceived) {
          onTokenReceived(token)
        }
        setAccessToken(token)
      })
    }
  }, [accessToken, onTokenReceived, getAccessTokenSilently, isAuthenticated])

  return {
    isPending: isLoading || (isAuthenticated && !accessToken),
    hasAccessToken: !!(isAuthenticated && accessToken && accessToken.length),
  }
}

const allowedUnauthorizedFeatureFlag = 'hotel-search-allow-unauthorized'

export const AuthenticatedOnly = ({
  children,
  spinner,
  onTokenReceived,
  isLoading = false,
}: AuthenticatedBoundaryProps): JSX.Element | null => {
  const router = useRouter()
  const { isPending, hasAccessToken } = useAuthToken(onTokenReceived)
  const isUnauthorizedEnabled = useVariableValue(allowedUnauthorizedFeatureFlag, false)

  if (process.env['NEXT_PUBLIC_DISABLE_AUTH'] === 'true') {
    // eslint-disable-next-line no-console
    console.warn(
      '🔓 Authentication is disabled, parts of the app might not work correctly! To re-enable, delete `NEXT_PUBLIC_DISABLE_AUTH=true` from your environment variables 🔓'
    )
    return children as JSX.Element
  }

  if (isPending || !router || isLoading) {
    return spinner
  }

  if (hasAccessToken) {
    return children as JSX.Element
  }

  if (!isUnauthenticatedPage(router?.pathname, router?.query['tab'], isUnauthorizedEnabled)) {
    return <Login />
  }

  return null
}

export const UnauthenticatedPage = ({
  children,
  spinner,
}: {
  children: React.ReactNode
  spinner: JSX.Element
}): JSX.Element | null => {
  const { isLoading, user } = useAuth0()
  const router = useRouter()
  const { isPending, hasAccessToken } = useAuthToken()
  const isUnauthorizedEnabled = useVariableValue(allowedUnauthorizedFeatureFlag, false)

  if (process.env['NEXT_PUBLIC_DISABLE_AUTH'] === 'true') {
    // eslint-disable-next-line no-console
    console.warn(
      '🔓 Authentication is disabled, parts of the app might not work correctly! To re-enable, delete `NEXT_PUBLIC_DISABLE_AUTH=true` from your environment variables 🔓'
    )
    return null
  }

  if (isPending || !router || isLoading) {
    return spinner
  }

  if (hasAccessToken) {
    return null
  }

  if (
    !isLoading &&
    isUnauthenticatedPage(router?.pathname, router?.query['tab'], isUnauthorizedEnabled)
  ) {
    return children as JSX.Element
  }

  return null
}

export const AuthProvider = ({
  children,
  ...auth0
}: React.PropsWithChildren<Auth0ProviderOptions>): JSX.Element => {
  // Redirect callback is skipped so we can manually handle error cases and/or redirect on success conditionally on Callback page
  return (
    <Auth0Provider {...auth0} skipRedirectCallback>
      {children}
    </Auth0Provider>
  )
}

// Later, we might support several auth providers. For now, can use auth0 hook as it is.
export const useAuth = useAuth0
