import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const baseLineStyles = {
  display: 'flex',
  justifyContent: 'space-between',
}

export const priceBreakdownRowItemHelper = createMultiStyleConfigHelpers([
  'priceBreakdownRow',
  'priceBreakdownRowTitleLine',
  'priceBreakdownRowLine',
  'arrowDownRight',
  'priceBreakdownSurchargeButton',
])

export const PriceBreakdownRowItem = priceBreakdownRowItemHelper.defineMultiStyleConfig({
  baseStyle: priceBreakdownRowItemHelper.definePartsStyle({
    priceBreakdownRow: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 2,
      fontSize: 'sm',
    },
    priceBreakdownRowTitleLine: {
      ...baseLineStyles,
    },
    priceBreakdownRowLine: {
      '>div': {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
      },
      button: {
        ...baseLineStyles,
        width: '100%',
        svg: {
          width: 3,
          marginLeft: 2,
        },
      },
    },
    priceBreakdownCollapsible: {
      button: {
        ...baseLineStyles,
        svg: {
          width: 3,
          marginLeft: 2,
        },
      },
    },
  }),
})
