import { AuthTokenSubjectSchema } from '@reward-platform/auth'
import { useStateStorage, StorageKeys, clearClientStateStorage } from '@reward-platform/utils'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import useCorrelationId from '~/hooks/useCorrelationId/useCorrelationId'

const useLastKnownUser = () => {
  const { storage: knownSubject, setStorage: setKnownSubject } = useStateStorage({
    key: StorageKeys.USER_ID,
    parser: (value: unknown) => {
      const res = AuthTokenSubjectSchema.safeParse(value)
      return res.success ? res.data : undefined
    },
  })

  const { resetCorrelationId } = useCorrelationId()

  return (token: string) => {
    /**
     * Get token subject and clear statestorage when it's different from the one we know.
     */
    const { sub } = jwtDecode(token) as JwtPayload
    if (sub !== knownSubject) {
      clearClientStateStorage()
      resetCorrelationId()
      setKnownSubject(sub)
    }
  }
}

export default useLastKnownUser
