import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'container',
  'displayGrid',
  'displayInline',
  'destinationCard',
  'destinationLocation',
  'thumbnail',
  'chip',
])

export const PopularDestinations = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      background: 'brand.teal5',
      '> h4': {
        fontSize: 'lg',
        padding: {
          base: 'var(--chakra-sizes-6) var(--chakra-sizes-6) 0 0',
          lg: 'var(--chakra-sizes-6) var(--chakra-sizes-6) 0 var(--chakra-sizes-6)',
        },
        fontWeight: 'medium',
      },
    },
    displayGrid: {
      display: {
        base: 'flex',
        lg: 'grid',
      },
      gridTemplateColumns: {
        base: 'unset',
        lg: '1fr 1fr',
      },
      flexDirection: {
        base: 'column',
        lg: 'unset',
      },
      li: {
        width: 'auto',
      },
    },
    displayInline: {
      display: 'flex',
      li: {
        width: '100%',
      },
    },
    destinationCard: {
      transition: 'all 0.4s ease-in-out',
      cursor: 'pointer',
      borderRadius: '2xl',
      boxShadow: '0px 6px 9px 0px #0000001A',
      background: 'brand.white',
      margin: {
        base: '0 0 var(--chakra-sizes-6) 0',
        lg: 6,
      },
      padding: 4,
      '> div': {
        display: 'flex',
        justifyContent: 'space-between',
        marginLeft: {
          base: 6,
          lg: 'unset',
        },
        width: {
          base: '100%',
          lg: 'unset',
        },
        '> div:first-of-type': {
          display: 'flex',
          flexDirection: 'column',
        },
        '> div:last-of-type': {
          marginLeft: {
            base: 'auto',
            lg: 'unset',
          },
        },
      },
      h4: {
        marginBottom: 1,
        fontSize: 'lg',
        fontWeight: 'medium',
      },
      p: {
        marginBottom: {
          base: 0,
          lg: 6,
        },
        fontSize: 'sm',
        fontWeight: 'normal',
      },
      display: 'flex',
      flexDirection: {
        base: 'row-reverse',
        lg: 'column',
      },
      justifyContent: {
        base: 'flex-start',
        lg: 'unset',
      },
    },
    destinationLocation: {
      display: 'flex',
      height: '100%',
      justifyContent: {
        base: 'flex-end',
        lg: 'flex-start',
      },
      marginBottom: {
        base: '0 !important',
        lg: 'var(--chakra-space-3) !important',
      },
    },
    thumbnail: {
      width: '100%',
      pos: 'relative',
      borderRadius: 'lg',
      mt: 'auto',
      w: {
        base: '73px',
        lg: '100%',
      },
      h: {
        base: '73px',
        lg: '130px',
      },
    },
  },
})
