import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const tooltipHelper = createMultiStyleConfigHelpers([
  'infoButton',
  'wrapper',
  'containerBg',
  'containerBorder',
  'containerMaxWidth',
  'topPos',
])

export const Tooltip = tooltipHelper.defineMultiStyleConfig({
  baseStyle: tooltipHelper.definePartsStyle({
    containerBg: {
      bg: 'white',
    },
    containerBorder: {
      border: '2px solid',
    },
    iconButton: {
      cursor: 'pointer',
      w: 6,
      h: 6,
      ml: 2,
      minWidth: 'auto',
      border: 0,
      background: 'none',
      svg: {
        w: 6,
        _hover: {
          fill: 'gray',
        },
      },
    },
    wrapper: {
      display: 'flex',
      flexDir: 'column',
      padding: {
        base: 4,
      },
      '> div': {
        padding: 0,
        p: {
          textTransform: 'none',
          fontSize: '1rem',
        },
      },
      '> button': {
        cursor: 'pointer',
        position: 'relative',
        top: '0',
        left: '0',
        width: '100%',
        borderRadius: 16,
        textTransform: 'uppercase',
        marginTop: 4,
        padding: 4,
        fontSize: 'sm',
      },
    },
    topPos: {
      top: '1.5px !important',
    },
  }),
})
