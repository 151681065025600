import { useQuery } from 'react-query'
import { getAssetContent } from '~/services/contentService'
import { usePartner } from '~/context/partner'
import { useLocale } from '../useLocale/useLocale'

export const useAssetContent = (contentfulIdentifier: string) => {
  const { theme } = usePartner()
  const userLocale = useLocale()
  const { data, isLoading } = useQuery(
    ['imageContent', contentfulIdentifier, userLocale],
    () => getAssetContent(theme, contentfulIdentifier, userLocale),
    { enabled: Boolean(userLocale) }
  )

  return {
    url: data?.image?.url,
    title: data?.image?.title,
    isLoading: isLoading || !userLocale,
  }
}
