import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useRouter } from 'next/router'

export const Login = () => {
  const { loginWithRedirect } = useAuth0()
  const { asPath } = useRouter()
  useEffect(() => {
    if (loginWithRedirect) {
      loginWithRedirect({ appState: { returnTo: asPath } })
    }
  }, [loginWithRedirect, asPath])

  return null
}
