import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const replaceItemModalHelper = createMultiStyleConfigHelpers([
  'promptText',
  'option',
  'optionContainer',
  'optionHeading',
  'optionLinkWrapper',
  'buttonContainer',
  'instructions',
  'card',
  'indicator',
  'arrow',
])

export const ReplaceItemModal = replaceItemModalHelper.defineMultiStyleConfig({
  baseStyle: replaceItemModalHelper.definePartsStyle({
    arrow: {
      _hover: {
        boxShadow: 'none',
      },
    },
    indicator: {
      width: 2,
      height: 2,
      borderRadius: '50%',
    },
    promptText: {
      mt: 2,
      mb: 2,
      fontSize: { base: 'sm', md: 'md' },
    },
    instructions: {
      padding: { base: 2 },
      paddingTop: { base: 0 },
    },
    optionContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      overflowX: { base: 'scroll', md: 'visible' },
      overflowY: 'visible',
      justifyContent: 'space-between',
      width: '100%',
      gap: 4,
      padding: { base: 2 },
      paddingTop: { base: 0 },
      '> div': {
        display: 'flex',
        flexDirection: 'column',
        width: { base: '340px' },
      },
    },
    optionHeading: {
      fontSize: 'lg',
      mb: 4,
      height: '2rem',
      mt: 4,
      fontWeight: 'medium',
    },
    optionLinkWrapper: {
      textDecor: 'unset',
      cursor: 'pointer',
      minWidth: '330px',
      flex: '1 0 auto',
      '> div': {
        width: '100%',
      },
    },
    buttonContainer: {
      w: '100%',
      button: {
        m: 4,
        w: '90%',
      },
    },
    card: {
      height: 'full',
      minHeight: '548px',
      display: 'flex',
      justifyContent: 'space-between',
      flexDir: 'column',
    },
  }),
  sizes: {
    sm: {
      optionContainer: {
        maxHeight: '625px',
      },
    },
    // md: {
    //   optionContainer: {
    //     mb: 8,
    //   },
    // },
  },
})
