import { z } from 'zod'
import { PackageRulesSchema } from '@reward-platform/shared-schemas'
import {
  AddressSchema,
  AgeGroupSchema,
  CarHireItemType,
  CarHireLocationSchema,
  DateSchema,
  ISODateStringSchema,
  ExperienceItemType,
  HotelItemType,
  MonetaryAmountSchema,
  RoomSchema,
  TravelerGroupSchema,
  VehicleSchema,
  FlightItemType,
  FlightDetailsSchema,
  SurchargesMonetaryAmountSchema,
  TaxDetailsSchema,
  CarHirePolicySchema,
  ExperienceCancellationPolicySchema,
  ExperienceDurationSchema,
  ExperienceLocationSchema,
} from '../common'

export const BasketItemTypeSchema = z.union([
  z.literal(HotelItemType),
  z.literal(ExperienceItemType),
  z.literal(CarHireItemType),
  z.literal(FlightItemType),
])

export type BasketItemType = z.infer<typeof BasketItemTypeSchema>

const PaymentOptionSchema = z.object({
  id: z.string(),
  cashValue: MonetaryAmountSchema.optional(),
  aviosValue: z.number(),
  cashBaseValue: MonetaryAmountSchema.optional(),
  cashTaxValue: MonetaryAmountSchema.optional(),
  taxDetails: z.array(TaxDetailsSchema).optional(),
})
export type PaymentOption = z.infer<typeof PaymentOptionSchema>

/**
 * There are 4 cash values that come from the API for the cash only option
 * `baseAmount`, `totalAmount` and `totalTaxAmount`. There are mapped in the following way:
 * `totalAmount` -> `cashTotalValue`
 * `totalTaxAmount` -> `cashTaxValue`
 * `baseAmount` -> `cashBaseValue`
 * `taxDetails   -> `taxDetails`
 *
 */
const OnlyCashPaymentOptionSchema = z.object({
  id: z.string(),
  cashTotalValue: MonetaryAmountSchema,
  cashBaseValue: MonetaryAmountSchema,
  cashTaxValue: MonetaryAmountSchema.optional(),
  aviosCollectValue: z.number(),
  salesTax: MonetaryAmountSchema.optional(),
  taxDetails: z.array(TaxDetailsSchema).optional(),
})
export type OnlyCashPaymentOption = z.infer<typeof OnlyCashPaymentOptionSchema>

const CancelCashPaymentSchema = z.object({
  id: z.string(),
  cashPayment: MonetaryAmountSchema.optional(),
  billingAddress: AddressSchema,
})

export type CancelCashPayment = z.infer<typeof CancelCashPaymentSchema>

export const PaymentOptionsSchema = z.object({
  onlyCashPaymentOption: OnlyCashPaymentOptionSchema.nullish(),
  options: z.array(PaymentOptionSchema),
  defaultSelectedId: z.string().optional(),
  isSalesTaxType: z.boolean(),
})
export type PaymentOptions = z.infer<typeof PaymentOptionsSchema>

export interface FormattedSurchargesBreakdown {
  feeName?: string
  amount: string
}

export interface FormattedSurchargesSchema {
  total: string | undefined
  breakdown?: FormattedSurchargesBreakdown[]
}

export type FormattedSurcharges = FormattedSurchargesSchema

const BaseBasketItemSchema = z.object({
  id: z.string(),
  bundleSelectionId: z.string(),
  type: BasketItemTypeSchema,
  startDate: ISODateStringSchema,
  imageUrl: z.string().optional(),
  paymentOptions: PaymentOptionsSchema,
  surcharges: z.array(SurchargesMonetaryAmountSchema),
})
export type BaseBasketItem = z.infer<typeof BaseBasketItemSchema>

const TravelerSchema = z.object({
  id: z.string(),
  age: z.number().optional(),
  ageGroup: AgeGroupSchema,
})

export type Traveler = z.infer<typeof TravelerSchema>

export const FlightTravelerSchema = TravelerSchema
export type FlightTraveler = z.infer<typeof FlightTravelerSchema>

export const HotelTravelerSchema = TravelerSchema

export type HotelTraveler = z.infer<typeof HotelTravelerSchema>

const HotelTravelersSchema = z.array(z.array(HotelTravelerSchema))
export type HotelTravelers = z.infer<typeof HotelTravelersSchema>

export const FormattedDateTimeSchema = z.string()

export const HotelBasketItemSchema = BaseBasketItemSchema.extend({
  name: z.string(),
  type: z.literal(HotelItemType),
  room: RoomSchema,
  endDate: ISODateStringSchema,
  address: AddressSchema,
  travelers: HotelTravelersSchema,
  packageRules: PackageRulesSchema,
  amenities: z.array(z.string()).optional(),
})
export type HotelBasketItem = z.infer<typeof HotelBasketItemSchema>

const PerTravelerQuestionGroup = 'PER_TRAVELER'
const PerBookingQuestionGroup = 'PER_BOOKING'

export const BookingQuestionGroupSchema = z.enum([
  PerTravelerQuestionGroup,
  PerBookingQuestionGroup,
])

export type BookingQuestionGroup = z.infer<typeof BookingQuestionGroupSchema>

export const SPECIAL_REQUIREMENTS_BOOKING_QUESTION_ID = 'SPECIAL_REQUIREMENTS' as const

export const perBookingQuestionIds = [SPECIAL_REQUIREMENTS_BOOKING_QUESTION_ID] as const

export const PerBookingQuestionIdSchema = z.enum(perBookingQuestionIds)

export type PerBookingQuestionId = z.infer<typeof PerBookingQuestionIdSchema>

export const perTravelerQuestionIds = ['FULL_NAMES_FIRST', 'FULL_NAMES_LAST', 'AGEBAND'] as const

export const PerTravelerQuestionIdSchema = z.enum(perTravelerQuestionIds)

export type PerTravelerQuestionId = z.infer<typeof PerTravelerQuestionIdSchema>

export const BookingQuestionIdSchema = z.union([
  PerBookingQuestionIdSchema,
  PerTravelerQuestionIdSchema,
])

export type BookingQuestionId = z.infer<typeof BookingQuestionIdSchema>

export const BookingQuestionSchema = z.object({
  id: BookingQuestionIdSchema,
  type: z.enum(['STRING', 'NUMBER_AND_UNIT', 'DATE', 'TIME', 'LOCATION_REF_OR_FREE_TEXT']),
  allowedAnswers: z.array(z.string()).optional(),
  answerIsRequired: z.enum(['MANDATORY', 'OPTIONAL', 'CONDITIONAL']),
  group: BookingQuestionGroupSchema,
  maxLength: z.number(),
  title: z.string().optional(),
  hint: z.string().optional(),
  units: z.array(z.string()).optional(),
})

export type BookingQuestion = z.infer<typeof BookingQuestionSchema>

export type PerTravelerQuestion = BookingQuestion & {
  id: PerTravelerQuestionId
  group: typeof PerTravelerQuestionGroup
}

export type PerBookingQuestion = BookingQuestion & {
  id: PerBookingQuestionId
  group: typeof PerBookingQuestionGroup
}

export const isPerBookingQuestion = (arg: BookingQuestion): arg is PerBookingQuestion =>
  arg.group === PerBookingQuestionGroup &&
  Object.values(PerBookingQuestionIdSchema.Enum).some((id) => id === arg.id)

export const isPerTravelerQuestion = (arg: BookingQuestion): arg is PerTravelerQuestion =>
  arg.group === PerTravelerQuestionGroup &&
  Object.values(PerTravelerQuestionIdSchema.Enum).some((id) => id === arg.id)

export const ExperienceLanguageGuideTypeSchema = z.enum(['GUIDE', 'AUDIO', 'WRITTEN'])
export type ExperienceLanguageGuideType = z.infer<typeof ExperienceLanguageGuideTypeSchema>

export const ExperienceLanguageGuideSchema = z.object({
  type: ExperienceLanguageGuideTypeSchema,
  language: z.string(),
})

export type ExperienceLanguageGuide = z.infer<typeof ExperienceLanguageGuideSchema>

export const ExperienceBasketItemSchema = BaseBasketItemSchema.extend({
  name: z.string(),
  type: z.literal(ExperienceItemType),
  description: z.string(),
  endDate: ISODateStringSchema,
  duration: ExperienceDurationSchema,
  days: z.number().optional(),
  experienceType: z.string().optional(),
  bookingQuestions: z.array(BookingQuestionSchema),
  travelers: TravelerGroupSchema.array(),
  allowCustomTravelerPickup: z.boolean(),
  startTime: z.string().optional(),
  languageGuides: z.array(ExperienceLanguageGuideSchema).optional(),
  cancellationPolicy: ExperienceCancellationPolicySchema,
  additionalInfo: z.array(z.string()).optional(),
  startLocations: ExperienceLocationSchema.array().optional(),
  endLocations: ExperienceLocationSchema.array().optional(),
})
export type ExperienceBasketItem = z.infer<typeof ExperienceBasketItemSchema>

export const Avis = 'Avis'
export const Budget = 'Budget'

const CarHireVendorSchema = z.union([z.literal(Avis), z.literal(Budget)])

export type CarHireVendor = z.infer<typeof CarHireVendorSchema>

const CarHireExtraSchema = z.object({
  serviceRef: z.string().optional(),
  serviceSubType: z.string().optional(),
  name: z.string().optional(),
  serviceCategory: z.string().optional(),
  description: z.string().optional(),
  selectedQuantity: z.number().optional(),
  maxAllowedQuantity: z.number().optional(),
})

export type CarHireExtra = z.infer<typeof CarHireExtraSchema>

export const CarHireBasketItemSchema = BaseBasketItemSchema.extend({
  type: z.literal(CarHireItemType),
  vendorName: CarHireVendorSchema,
  endDate: ISODateStringSchema,
  pickUpLocation: CarHireLocationSchema,
  dropOffLocation: CarHireLocationSchema,
  locationPolicy: CarHirePolicySchema,
  rentalPolicy: CarHirePolicySchema,
  vehicle: VehicleSchema,
  extras: z.array(CarHireExtraSchema),
  included: z.array(z.string()).optional(),
  transmission: z.string().optional(),
  doors: z.string().optional(),
  seats: z.string().optional(),
  bags: z.string().optional(),
  under30DriverDateOfBirth: DateSchema.optional(),
  unlimitedMileage: z.boolean().optional(),
  airCondition: z.string().optional(),
})
export type CarHireBasketItem = z.infer<typeof CarHireBasketItemSchema>

export const FlightBasketItemSchema = BaseBasketItemSchema.extend({
  inboundFlightDetails: FlightDetailsSchema.optional(),
  outboundFlightDetails: FlightDetailsSchema,
  vendorId: z.string().optional(),
  vendorName: z.string().optional(),
  travelers: FlightTravelerSchema.array(),
  isSecure: z.boolean().optional(),
})

export type FlightBasketItem = z.infer<typeof FlightBasketItemSchema>

const BasketItemSchema = z.union([
  HotelBasketItemSchema,
  ExperienceBasketItemSchema,
  CarHireBasketItemSchema,
  FlightBasketItemSchema,
])

export type BasketItem = z.infer<typeof BasketItemSchema>

export const BasketItemsSchema = z.object({
  [HotelItemType]: z.array(HotelBasketItemSchema),
  [ExperienceItemType]: z.array(ExperienceBasketItemSchema),
  [CarHireItemType]: z.array(CarHireBasketItemSchema),
  [FlightItemType]: z.array(FlightBasketItemSchema),
})

export type BasketItems = z.infer<typeof BasketItemsSchema>

export const BasketSchema = z.object({
  correlationId: z.string(),
  timestamp: z.string().transform((str) => new Date(str)),
  items: BasketItemsSchema,
  currency: z.string(),
})
export type Basket = z.infer<typeof BasketSchema>

export const isHotelBasketItem = (basketItem: BaseBasketItem): basketItem is HotelBasketItem => {
  return basketItem.type === HotelItemType
}

export const isExperienceBasketItem = (
  basketItem: BaseBasketItem
): basketItem is ExperienceBasketItem => {
  return basketItem.type === ExperienceItemType
}

export const isCarBasketItem = (basketItem: BaseBasketItem): basketItem is CarHireBasketItem => {
  return basketItem.type === CarHireItemType
}

export const isFlightBasketItem = (basketItem: BaseBasketItem): basketItem is FlightBasketItem => {
  return basketItem.type === FlightItemType
}

export const BasketOfferBaseSchema = z.object({
  bundleId: z.string(),
  recommendationId: z.string(),
  correlationId: z.string(),
  priceApplicabilityId: z.string(),
})

export const CarHireBasketOfferSchema = BasketOfferBaseSchema
export type CarHireBasketOffer = z.infer<typeof CarHireBasketOfferSchema>

export const HotelBasketOfferSchema = BasketOfferBaseSchema.extend({
  bedGroupId: z.string(),
})
export type HotelBasketOffer = z.infer<typeof HotelBasketOfferSchema>

export const ExperienceBasketOfferSchema = BasketOfferBaseSchema.extend({
  experienceStartTime: z.string().optional(),
})
export type ExperienceBasketOffer = z.infer<typeof ExperienceBasketOfferSchema>

export const FlightBasketOfferSchema = BasketOfferBaseSchema.extend({
  vendorCode: z.enum(['EI']), // based on CarrierSchema in flight schema
  shopperGroup: z.object({
    shopperRef: z.array(z.string()),
  }),
})

export type FlightBasketOffer = z.infer<typeof FlightBasketOfferSchema>
