export const isClient = () => typeof window !== 'undefined'

export const isProduction = () => process.env.NEXT_PUBLIC_DEPLOYMENT === 'prd'
export const isStaging = () => process.env.NEXT_PUBLIC_DEPLOYMENT === 'stg'
export const isTest = () => process.env.NEXT_PUBLIC_DEPLOYMENT === 'tst'
export const isDevelopment = () => process.env.NEXT_PUBLIC_DEPLOYMENT === 'development'
export const isLocal = () =>
  process.env.NEXT_PUBLIC_DEPLOYMENT === 'local' ||
  process.env.NEXT_PUBLIC_DEPLOYMENT === 'localhost' ||
  (!isProduction() && !isStaging() && !isTest() && !isDevelopment())

export const isStorybook = () => !!process.env.STORYBOOK_CLIENT_ENABLED
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isCypress = () => !!(window as any)?.Cypress
