import { z } from 'zod'
import { PartnerTagSchema } from './partner'

export const ContentfulEntriesRequestParamsSchema = z.object({
  partner: PartnerTagSchema,
  locale: z.string().min(2).max(5),
  preview: z.coerce.boolean().optional(),
})

export type ContentfulEntriesRequestParams = z.infer<typeof ContentfulEntriesRequestParamsSchema>

export const ContentfulEntryRequestParamsSchema = ContentfulEntriesRequestParamsSchema.extend({
  identifier: z.string(),
})

export type ContentfulEntryRequestParams = z.infer<typeof ContentfulEntryRequestParamsSchema>
