import { z, ZodIssueCode } from 'zod'
import {
  createTravelerInformationNameSchema,
  TravelerInformationAddressSchema,
  TravelerInformationContactSchema,
} from './traveler-information'

export const MicroformFieldStateSchema = z.enum(['EMPTY', 'INVALID', 'VALID'])
export type MicroformFieldState = z.infer<typeof MicroformFieldStateSchema>

const BaseCreditCardDetailsSchema = z.object({
  expiryMonth: z.string().min(1),
  expiryYear: z.string().min(1),
  isCreditCardNumberValid: z.literal(true),
  isSecurityCodeValid: z.literal(true),
})

const isCreditCardExpireDateValid = (
  expiryMonth: string,
  expiryYear: string,
  ctx: z.RefinementCtx
) => {
  const today = new Date()
  const cardDate = new Date(Number(expiryYear), +Number(expiryMonth) - 1, 1)

  if (cardDate.getFullYear() === today.getFullYear() && cardDate.getMonth() < today.getMonth()) {
    ctx.addIssue({
      code: ZodIssueCode.invalid_date,
      path: ['expiryMonth'],
    })
  }
}

const CreditCardDetailsSchema = BaseCreditCardDetailsSchema.extend({
  creditCardToken: z.string().min(1),
  captureContext: z.string().min(1),
}).superRefine((val, ctx) => {
  const { expiryMonth, expiryYear } = val
  isCreditCardExpireDateValid(expiryMonth, expiryYear, ctx)
})

export const CreditCardHolderNameSchema = createTravelerInformationNameSchema({
  isDateOfBirthRequired: false,
  isGenderRequired: false,
})

export const CreditCardHolderContactSchema = TravelerInformationContactSchema
export const BillingAddressSchema = TravelerInformationAddressSchema

type CreatePaymentInformationTermsAndConditionsParams = {
  isPTLTAStatementRequired: boolean
  isFeesPoliciesAndTermsAndConditionsRequired: boolean
  isFeesPoliciesAndTermsAndConditionsExternalRequired: boolean
}

export const createPaymentInformationTermsAndConditions = ({
  isFeesPoliciesAndTermsAndConditionsRequired,
  isFeesPoliciesAndTermsAndConditionsExternalRequired,
  isPTLTAStatementRequired,
}: CreatePaymentInformationTermsAndConditionsParams) => ({
  PTLTAStatement: isPTLTAStatementRequired ? z.literal(true) : z.undefined(),
  feesPoliciesAndTermsAndConditions: isFeesPoliciesAndTermsAndConditionsRequired
    ? z.literal(true)
    : z.undefined(),
  feesPoliciesAndTermsAndConditionsExternal: isFeesPoliciesAndTermsAndConditionsExternalRequired
    ? z.literal(true)
    : z.undefined(),
})

export const paymentInformationTermsAndConditions = {
  PTLTAStatement: z.literal(true),
  feesPoliciesAndTermsAndConditions: z.literal(true),
  feesPoliciesAndTermsAndConditionsExternal: z.literal(true),
}

export const CreditCardHolderSchema = CreditCardHolderNameSchema.and(
  CreditCardHolderContactSchema
).and(BillingAddressSchema)

export const BaseCreditCardInformationSchema = z.object({
  creditCardHolder: CreditCardHolderSchema,
  creditCardDetails: BaseCreditCardDetailsSchema.extend({
    isCreditCardNumberValid: z.literal(true),
    isSecurityCodeValid: z.literal(true),
  }).superRefine((val, ctx) => {
    const { expiryMonth, expiryYear } = val
    isCreditCardExpireDateValid(expiryMonth, expiryYear, ctx)
  }),
})

export type BaseCreditCardInformation = z.infer<typeof BaseCreditCardInformationSchema>

export const ExtendedCreditCardInformationSchema = z.object({
  creditCardHolder: CreditCardHolderSchema,
  creditCardDetails: CreditCardDetailsSchema,
})

export type CreatePaymentInformationFormSchemaParams = {
  showCashPaymentForm: boolean
} & CreatePaymentInformationTermsAndConditionsParams

export const createPaymentInformationFormSchema = ({
  showCashPaymentForm,
  ...rest
}: CreatePaymentInformationFormSchemaParams) =>
  z.object({
    creditCardInformation: showCashPaymentForm ? BaseCreditCardInformationSchema : z.undefined(),
    ...createPaymentInformationTermsAndConditions(rest),
  })

export type PaymentInfoSubmitOrderForm = z.infer<
  ReturnType<typeof createPaymentInformationFormSchema>
>

export type PaymentInformationForm = z.infer<ReturnType<typeof createPaymentInformationFormSchema>>

export const createExtendedPaymentInformationSchema = (
  params: CreatePaymentInformationFormSchemaParams
) =>
  createPaymentInformationFormSchema(params).extend({
    creditCardInformation: params.showCashPaymentForm
      ? ExtendedCreditCardInformationSchema
      : z.undefined(),
  })

export const createBasePaymentInformationSchema = (
  params: CreatePaymentInformationFormSchemaParams
) =>
  createPaymentInformationFormSchema(params).extend({
    creditCardInformation: params.showCashPaymentForm
      ? BaseCreditCardInformationSchema
      : z.undefined(),
  })

export type ExtendedPaymentInformation = z.infer<
  ReturnType<typeof createExtendedPaymentInformationSchema>
>

export type BasePaymentInformation = z.infer<ReturnType<typeof createBasePaymentInformationSchema>>

export type GetPaymentInformationParams = {
  data: PaymentInformationForm
  showCashPaymentForm: boolean
}
