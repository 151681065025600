import router from 'next/router'
import {
  Box,
  Button,
  Icon,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  chakra,
  useDisclosure,
  useMultiStyleConfig,
} from '@chakra-ui/react'
import { IconName } from '@reward-platform/ancillaries-schemas/common'
import { convertIconSetToChakraSVG } from '~/utils/Icon.utils'
import useIntl from '~/hooks/useIntl/useIntl'

export default function ChatWidgetMock() {
  const intl = useIntl()
  const styles = useMultiStyleConfig('ChatWidgetMock')
  const { isOpen, onOpen, onClose } = useDisclosure()

  const t = {
    chatWidgetCtaPart1: intl.formatMessage({
      id: 'chat-widget-cta-part-1',
      defaultMessage: 'Please',
    }),
    chatWidgetCtaPart2: intl.formatMessage({
      id: 'chat-widget-cta-part-2',
      defaultMessage: 'login',
    }),
    chatWidgetCtaPart3: intl.formatMessage({
      id: 'chat-widget-cta-part-3',
      defaultMessage: 'to access the chat',
    }),
  }

  const handleToggleChat = () => (isOpen ? onClose() : onOpen())

  const handleLogin = () => {
    router.push(`/login&openChat=true`)
  }

  return (
    <Popover placement="left" isOpen={isOpen}>
      <PopoverTrigger>
        <IconButton
          sx={styles.chatButton}
          aria-label="fake-chatbot-button"
          icon={<Icon as={convertIconSetToChakraSVG(IconName.Enum.chatbot)} sx={styles.chatIcon} />}
          onClick={handleToggleChat}
        />
      </PopoverTrigger>
      <PopoverContent sx={styles.popoverContent}>
        <Box>
          <Box>
            <chakra.p>{t.chatWidgetCtaPart1}</chakra.p>
            <Button variant="link" onClick={handleLogin}>
              {t.chatWidgetCtaPart2}
            </Button>
            <chakra.p>{t.chatWidgetCtaPart3}</chakra.p>
          </Box>
        </Box>
      </PopoverContent>
    </Popover>
  )
}
