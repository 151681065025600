import { ComponentMultiStyleConfig } from '@chakra-ui/react'

export const ListBox: ComponentMultiStyleConfig = {
  parts: ['icon', 'list', 'item'],
  baseStyle: {
    icon: {
      color: 'black',
    },
    list: {
      position: 'absolute',
      w: '100%',
      overflow: 'auto',
      border: '1px solid black',
    },
    item: {
      p: 4,
      bg: 'white',
      borderBottom: '1px solid #f1f1f1',
      _hover: {
        cursor: 'pointer',
        bg: '#f1f1f1',
      },
      _selected: {
        bg: '#f1f1f1',
      },
      _disabled: {
        opacity: 1,
        pointerEvents: 'none',
        // cursor: 'not-allowed',
      },
      fontWeight: 500,
    },
  },
}
