import { helpers } from './base'

export const RecommendedSection = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {},
    content: {},
    cards: {
      img: {
        borderRadius: 'lg',
      },
    },
    heading: {},
  },
})
