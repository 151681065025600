import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

export const Logout = ({
  callbackUrl,
  onLogout,
}: {
  callbackUrl: string | undefined
  onLogout?: () => void
}) => {
  const { logout } = useAuth0()

  useEffect(() => {
    if (logout) {
      onLogout?.()
      logout({ logoutParams: { returnTo: callbackUrl } })
    }
  }, [callbackUrl, logout, onLogout])

  return null
}
