import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'title',
  'showMoreContainer',
  'showMoreButton',
  'sectionCenter',
  'errorContainer',
])

export const HotelDetails = helpers.defineMultiStyleConfig({
  baseStyle: {
    title: {
      marginBottom: 7,
      textTransform: 'lowercase',
      marginTop: 0,

      '::first-letter': {
        textTransform: 'uppercase',
      },
    },
    showMoreContainer: {
      marginTop: 4,
      flexDirection: 'row',
      justifyContent: 'center',
    },
    showMoreButton: {
      width: 'auto',
      fontSize: 'sm',
    },
    sectionCenter: {
      marginBottom: 12,
      scrollMarginTop: 14,
      outline: 'none',
      gridColumn: '2/3',
    },
    errorContainer: {
      display: 'grid',
      gridGap: 4,
      scrollMarginTop: 2,
      margin: 'auto',
      marginTop: 12,
      justifyItems: 'flex-start',

      p: {
        fontSize: 'md',
        marginBottom: 4,
      },
    },
  },
})
