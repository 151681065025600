import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const paymentFormContainerHelper = createMultiStyleConfigHelpers([
  'paymentForm',
  'buttonContainer',
  'expirationDateContainer',
  'termsAndConditionsWrapper',
  'paymentFormParentWrapper',
])

export const PaymentFormContainer = paymentFormContainerHelper.defineMultiStyleConfig({
  baseStyle: paymentFormContainerHelper.definePartsStyle({
    paymentFormParentWrapper: {},

    termsAndConditionsWrapper: {
      'div[role="group"] > label': {
        alignItems: 'start',
      },
      button: {
        display: 'inline',
        textAlign: 'left',
      },
      'button, a': {
        pr: 1,
      },
      'button > span, a > span': {
        whiteSpace: 'normal',
        pr: 1,
      },
    },
    paymentForm: {
      '> * + *': {
        mt: 'lg',
      },
    },
    expirationDateContainer: {
      display: 'flex',
      flexDirection: { base: 'column', md: 'row' },
      width: '100%',
      columnGap: { base: 'none', md: '2rem' },
      rowGap: { base: '1rem', md: 'none' },
      '> *': {
        flex: 2,
      },
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: 4,
      mt: 10,
      button: {
        display: { base: 'none', md: 'flex' },
        w: { base: '100%', md: '30%' },
        pl: 4,
        pr: 4,
      },
    },
  }),
})
