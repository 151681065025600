import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'container',
  'buttonContainer',
  'backToSearchButton',
  'childContainer',
  'priceBreakdownRowWrapper',
  'priceSummaryContainer',
  'title',
  'summaryTotalWrapper',
  'summaryTotal',
  'collectedAvios',
])

export const BookingStatus = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    buttonContainer: {
      padding: 'auto',
      position: { base: 'inherit' },
      width: { base: 'inherit', md: '100%' },
      button: {
        width: '100%',
      },
    },
    backToSearchButton: {
      mt: 4,
    },
    childContainer: {
      mt: 0,
      mb: { base: 6, md: 10 },
      '>*': {
        mt: 5,
      },
      button: {
        fontSize: { base: 'md', lg: 'lg' },
      },
    },
    priceSummaryContainer: {
      width: { base: 'auto', md: '75%', lg: '45%' },
      maxWidth: 96,
      display: 'flex',
      flexDirection: 'column',
    },
    priceBreakdownRowWrapper: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 4,
      height: '100%',
      bg: 'red',
      rowGap: 6,
      padding: 6,
      mb: { base: 6, md: 10 },
      mt: 0,
      hr: {
        margin: 0,
      },
    },
    collectedAvios: {
      width: '80%',
      alignSelf: 'flex-end',
      p: { mb: 4, textAlign: 'right', color: 'unset' },
      span: {
        p: 1,
        pl: 2,
        pr: 2,
      },
    },
    summaryTotalWrapper: {
      display: 'flex',
      rowGap: 2,
      mt: 4,
    },
    summaryTotal: {
      alignItems: 'end',
      width: '50%',
      div: {
        _last: {
          mt: 1,
        },
      },
    },
    statusTooltip: {
      display: 'flex',
      flexDirection: 'row',
      gap: 3,
      '> svg': {
        width: { base: 6, md: 8 },
        height: { base: 6, md: 8 },
        padding: 1.5,
        borderRadius: 'full',
      },
      '> * > h4': {
        fontWeight: 'medium',
      },
    },
  }),
})
