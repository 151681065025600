/* eslint-disable sonarjs/cognitive-complexity */
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useRef } from 'react'
import router from 'next/router'
import { LOGIN_ERROR_PATH, LOGIN_PATH } from './unauthenticatedPages'

export const Callback = () => {
  const { handleRedirectCallback } = useAuth0()
  // This fixes an issue with getting "Invalid state" error
  // as described here https://community.auth0.com/t/error-invalid-state-when-calling-handleredirectcallback-on-react-app/95329/4
  const shouldRedirectRef = useRef(true)

  useEffect(() => {
    if (shouldRedirectRef.current) {
      shouldRedirectRef.current = false
      handleRedirectCallback()
        .then(({ appState }) => {
          let { returnTo } = appState || {}
          if (returnTo && !returnTo.includes(LOGIN_PATH)) {
            if (returnTo.includes('/login/')) {
              returnTo = returnTo.replace('/login', '')
            }
            const params = new URLSearchParams(returnTo)
            const openChat = params.get('openChat')
            if (openChat) {
              sessionStorage.setItem('openChat', openChat ? 'true' : 'false')
              returnTo = '/'
            }
            router.replace(returnTo)
          } else {
            router.replace('/')
          }
        })
        .catch(() => {
          router.replace(LOGIN_ERROR_PATH)
        })
    }
  }, [handleRedirectCallback])

  return null
}
