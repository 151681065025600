import { css } from '@emotion/react'

export const reset = css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  ul:where([class]),
  ol:where([class]) {
    padding: 0;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd,
  ul:where([class]),
  ol:where([class]) {
    margin: 0;
  }

  body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
  }

  ul[class],
  ol[class] {
    list-style: none;
  }

  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  img {
    max-width: 100%;
    display: block;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }
`

export default reset
