import { GenericClientError } from './GenericClientError'
import { ERROR_CODES } from './clientErrors'

const BUSINESS_MESSAGE = 'Fatal error has occurred, please refresh the application.'

export class PlatformError extends GenericClientError {
  constructor(message: string) {
    const error = new Error(message)
    error.name = 'PlatformError'
    super(ERROR_CODES.PLATFORM, error)

    this.developerMessage = message
    this.businessMessage = BUSINESS_MESSAGE
    this.fatal = true
  }
}
