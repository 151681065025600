import axios, { AxiosError, AxiosResponse } from 'axios'
import { Iata } from '@reward-platform/orders-api-client'
import { createDatadogMetaContext } from '@reward-platform/logger/utils'
import { createDatadogLogger } from '~/utils/logger'
import { API_URL } from '~/utils/configuration'
import { BFFRequestError } from '../../utils/errors'

const HEADER_KEYS = {
  partner: 'x-partner',
  correlationId: 'x-correlation-id',
  countryCode: 'x-country-code',
  currencyCode: 'x-currency-code',
}

export const bffClient = axios.create({
  baseURL: API_URL,
  timeout: 60000,
  validateStatus(status) {
    return status > 199 && status < 300 // Everything more than 299 will throw a error. Also, if unwanted redirects happen (?)
  },
})

const getAdditionalHeaders = () => {
  const partner = bffClient.defaults.headers.common[HEADER_KEYS.partner] as Iata
  const correlationId = bffClient.defaults.headers.common[HEADER_KEYS.correlationId] as string
  return { partner, correlationId }
}

const logAxiosResponseToDatadog = (response: AxiosResponse) => {
  const logger = createDatadogLogger('bff-client-response', { level: 'info' })
  const additionalHeaders = getAdditionalHeaders()
  const logMeta = createDatadogMetaContext(additionalHeaders)
  const logContext = {
    data: response.data,
    status: response.status,
    statusText: response.statusText,
    headers: response.headers,
    http: { ...response.config, method: response.config.method?.toUpperCase() },
    ...logMeta,
  }
  logger.info(`AxiosResponse: ${response.config.url}`, logContext)
}

bffClient.interceptors.response.use(
  (response: AxiosResponse) => {
    logAxiosResponseToDatadog(response)

    return response
  },
  (axiosError: AxiosError) => {
    const error = new BFFRequestError(axiosError)
    const additionalHeaders = getAdditionalHeaders()
    error.logToDatadog(additionalHeaders)

    throw error
  }
)

export const setAuthorizationToken = (token: string) => {
  bffClient.defaults.headers.common.Authorization = `Bearer ${token}`
}

export const deleteAuthorizationToken = () => {
  delete bffClient.defaults.headers.common.Authorization
}

export const setPartner = (partner: Iata) => {
  bffClient.defaults.headers.common[HEADER_KEYS.partner] = partner
}

export const setCorrelationId = (correlationId: string) => {
  bffClient.defaults.headers.common[HEADER_KEYS.correlationId] = correlationId
}

export const deleteAdditionalHeaders = () => {
  delete bffClient.defaults.headers.common[HEADER_KEYS.correlationId]
  delete bffClient.defaults.headers.common[HEADER_KEYS.partner]
}

export const hasCountryHeader = () => !!bffClient.defaults.headers.common[HEADER_KEYS.countryCode]

export const hasCurrencyHeader = () => !!bffClient.defaults.headers.common[HEADER_KEYS.currencyCode]

export const setCountryHeader = (countryCode: string) => {
  bffClient.defaults.headers.common[HEADER_KEYS.countryCode] = countryCode
}
export const setCurrencyHeader = (currencyCode: string) => {
  bffClient.defaults.headers.common[HEADER_KEYS.currencyCode] = currencyCode
}
