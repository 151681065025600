import { ExperimentFlag } from '@reward-platform/devcycle'
import { Room, ProductType, ProductTypeSchema } from '@reward-platform/ancillaries-schemas/common'
import { HotelDetailsResult, HotelSearchResult } from '@reward-platform/ancillaries-schemas/hotel'
import {
  CarHireDetailsDataTransferObject,
  CarHireSearchOffer,
} from '@reward-platform/ancillaries-schemas/car-hire'
import {
  pushEventToDataLayer,
  pushToDataLayer,
  DataLayerObject,
  PromotionLayerObject,
  compareAndPushEventInDataLayer,
  Navigation,
} from '~/utils/googleTagManager'
import { FilterList, Filter, FilterOptions } from '~/components/shared/Filters/Filters.types'
import {
  AirportLocation,
  CabinClass,
  CabinName,
  FlightResult,
} from '@reward-platform/flights-schemas'
import { UserContextValue } from '~/context/user'
import { NextRouter } from 'next/router'
import { SelectedBound } from '~/components/search-and-results/FlightPriceFooter/BoundDetails/BoundDetails'
import { isProduction } from '~/utils/envChecks'
import { Partner } from '~/services/partnerService'
import { Promotion, PromotionProductMap } from '~/context/promotion'
import { Option } from '@reward-platform/lift/components/ChakraListBox/common'
import { getIsRefundable } from '@iagl/reward-platform-cancellation-policies'
import { gtmGA3, gtmGA4 } from './googleTagManager.types'
import { formatMinutesToHours } from '../datetime'
import formatAirportName from '../formatAirportName'
import { GTMChannels } from './googleTagManagerHelpers'

const ga3ImpressionClick = 'ga3.impressionClick'
const ga3ImpressionView = 'ga3.impressionView'
const ga3Add = 'ga3.add'
const ga3Detail = 'ga3.detail'

export const flightResultsInitialLoad = (
  flights: FlightResult[],
  user: UserContextValue,
  partner: Partner
) => {
  const bookingCurrency = user.userCurrency
  const memberIdentifier = user.user?.memberIdentifier?.identifier
  const userBalance = user.account?.balance.amount

  pushEventToDataLayer('view_item_list', {
    memberID: memberIdentifier,
    booking_currency: bookingCurrency,
    currency: bookingCurrency,
    balance: userBalance,
    domain_environment: isProduction() ? 'prod' : 'non_prod',
    programmeCode: partner.slug,
    pageLocation: window.location.href,
    channel: GTMChannels.FLIGHTS,
    ecommerce: {
      items: flights.map((flight: FlightResult) => ({
        item_name: 'search',
        item_id: flight.operatingCarrier.flightNumber,
        item_brand: flight.operatingCarrier,
        item_category: GTMChannels.FLIGHTS,
        item_list_name: 'search',
        item_list_id: 'S1',
        index: flights.indexOf(flight) + 1,
      })),
    },
  } as gtmGA3)
}

export const flightDetailsInitialLoad = (
  flight: FlightResult,
  origin: AirportLocation,
  destination: AirportLocation,
  user: UserContextValue,
  cabins: Record<CabinName, CabinClass>,
  partner: Partner
) => {
  pushEventToDataLayer(ga3ImpressionClick, {
    event: 'navigation',
    channel: GTMChannels.FLIGHTS,
    nav_type: 'cta',
    nav_action: 'flight details',
    flight_from: `${formatAirportName(origin)}`,
    flight_to: `${formatAirportName(destination)}`,
    flight_code: `${flight.operatingCarrier.carrier} ${flight.operatingCarrier.flightNumber}`,
    flight_time: formatMinutesToHours(flight.duration),
    flight_route: `${flight.departureLocation}-${flight.arrivalLocation}`,
    domain_environment: isProduction() ? 'prod' : 'non_prod',
    programmeCode: partner.slug,
    page_location: window.location.href,
  } as DataLayerObject)

  const firstFlightValue = flight.cabinClasses.Economy?.price ?? flight.cabinClasses.Business?.price
  pushEventToDataLayer('view_item', {
    event: 'view_item',
    channel: GTMChannels.FLIGHTS,
    memberID: user?.user?.memberIdentifier?.identifier,
    booking_currency: user.userCurrency,
    balance: user.account?.balance.amount,
    domain_environment: isProduction() ? 'prod' : 'non_prod',
    programmeCode: partner.slug,
    pageLocation: window.location.href,
    collected_avios: user.account?.balance.amount,
    currency: user.userCurrency,
    value: firstFlightValue,
    avios_value: firstFlightValue,
    cash_value: 0,
    avios_percentage: 100,
    items: Object.keys(cabins)
      .map((cabinClassName, index) => {
        return {
          index: index + 1,
          item_name: `Details for flight ${flight.operatingCarrier.carrier}${flight.operatingCarrier.flightNumber}`,
          item_id: flight.operatingCarrier.flightNumber,
          item_brand: flight.operatingCarrier.carrier,
          item_category: 'flights',
          item_list_name: `Cabin ${cabinClassName}`,
          item_list_id: 'S1',
          price: flight.cabinClasses[cabinClassName as CabinName]?.price ?? 0,
          quantity: 1,
        }
      })
      .filter((item) => item.price !== 0),
  })
}

export const flightSelectInitialLoad = (
  flight: FlightResult,
  user: UserContextValue,
  url: string
) => {
  pushToDataLayer({
    event: 'Initial_setup',
    channel: GTMChannels.FLIGHTS,
    pageLocation: window.location.href,
    currency: user.userCurrency,
    referrer: url,
    departure_date: flight.departureTime.slice(0, 10),
    arrival_date: flight.arrivalTime.slice(0, 10),
    origin: flight.departureLocation,
    destination: flight.arrivalLocation,
  })
}

export const flightContinueButtonClickInitialLoad = (
  selectedFlights: SelectedBound[],
  totalFlightsPrice: number,
  cabinClasses: any[],
  user: UserContextValue,
  partner: Partner
) => {
  pushEventToDataLayer('add_to_cart', {
    event: 'add_to_cart',
    memberID: user?.user?.memberIdentifier?.identifier,
    booking_currency: user.userCurrency,
    currency: user.userCurrency,
    balance: user.account?.balance.amount,
    domain_environment: isProduction() ? 'prod' : 'non_prod',
    programmeCode: partner.slug,
    pageLocation: window.location.href,
    channel: GTMChannels.FLIGHTS,
    value: totalFlightsPrice,
    avios_value: totalFlightsPrice,
    cash_value: 0,
    avios_percentage: 100,
    items: selectedFlights.map((flight, index) => {
      const journeyType = index === 0 ? 'outbound' : 'inbound'
      const dateOnly = flight?.departureTime.slice(0, 10)
      const cabinClassName = cabinClasses[index]
      const lowercaseCabinClassName = cabinClassName.toLowerCase()
      const capitalisedCabinClass = cabinClassName.charAt(0) + cabinClassName.slice(1).toLowerCase()
      const flightAviosValue =
        flight?.cabinClasses[capitalisedCabinClass as keyof typeof CabinClass]?.price ?? 0
      const flightOperatingCarrier = flight?.operatingCarrier.carrier
      const flightOperatingCarrierNumber = flight?.operatingCarrier.flightNumber
      return {
        item_id: `${flightOperatingCarrier}${flightOperatingCarrierNumber}${dateOnly}${lowercaseCabinClassName}${journeyType}`,
        item_name: `${journeyType} flight ${flightOperatingCarrier}${flightOperatingCarrierNumber} with cabin ${capitalisedCabinClass}`,
        item_brand: flightOperatingCarrier,
        item_category: 'Flight selection',
        avios_value: flightAviosValue,
        cash_value: 0,
        collectedAvios: 0,
        price: flightAviosValue,
        avios_percentage: 100,
        quantity: 1,
      }
    }),
  })
}

export const hotelDetailsInitialLoad = (hotel: HotelDetailsResult) => {
  pushEventToDataLayer(ga3Detail, {
    ecommerce: {
      detail: {
        actionField: {
          list: 'Search Results',
        },
        products: [
          {
            avios_value: hotel?.aviosPrice,
            collected_avios: hotel?.collectAmount,
            id: hotel?.vendorId,
            name: hotel?.vendorName,
            category: hotel?.category || 'Hotel',
            brand: hotel?.brand,
            price: hotel?.cashPrice.toString(),
            variant: undefined,
          },
        ],
      },
    },
  } as gtmGA3)

  pushEventToDataLayer('view_item', {
    channel: 'HOTEL',
    value: hotel?.cashPrice,
    currency: hotel.rooms[0].roomRates[0].currencyCode,
    ecommerce: {
      items: [
        {
          avios_value: hotel?.aviosPrice,
          collected_avios: hotel?.collectAmount,
          item_name: hotel?.vendorName,
          item_id: hotel?.vendorId,
          item_brand: hotel?.brand,
          item_variant: undefined,
          item_category: hotel?.category || 'Hotel',
          item_list_name: 'Search',
          item_list_id: 'S1',
          price: hotel?.cashPrice,
          quantity: 1,
        },
      ],
    },
  } as gtmGA4)
}

export const carHireDetailsInitialLoad = (carHire: CarHireDetailsDataTransferObject) => {
  pushEventToDataLayer(ga3Detail, {
    ecommerce: {
      detail: {
        actionField: {
          list: 'Search Results',
        },
        products: [
          {
            id: carHire?.result?.bundleId,
            name: carHire?.result?.name,
            variant: carHire?.result?.vehicleClassName,
            category: 'Car Hire',
            brand: carHire?.result?.vendorName.toUpperCase(),
            quantity: '1',
            avios_value: carHire?.result?.aviosPrice,
            collected_avios: carHire?.result?.collectAmount,
            price: carHire?.result?.cashPrice.toString(),
          },
        ],
      },
    },
  } as gtmGA3)

  pushEventToDataLayer('view_item', {
    channel: 'CAR_HIRE',
    value: carHire?.result?.cashPrice,
    currency: carHire.result.currencyCode,
    ecommerce: {
      items: [
        {
          item_name: carHire?.result?.name,
          item_variant: carHire?.result?.vehicleClassName,
          item_id: carHire?.result?.bundleId,
          price: carHire?.result?.cashPrice,
          avios_value: carHire?.result?.aviosPrice,
          collected_avios: carHire?.result?.collectAmount,
          item_brand: carHire?.result?.vendorName.toUpperCase(),
          item_category: 'Car Hire',
          quantity: 1,
        },
      ],
    },
  } as gtmGA4)
}

export const hotelResultsInitialLoad = (hotels: HotelSearchResult[]) => {
  pushEventToDataLayer(ga3ImpressionView, {
    ecommerce: {
      impressions: hotels.map((hotel: HotelSearchResult) => ({
        id: hotel.vendorId,
        name: hotel.vendorName,
        category: hotel.category || 'Hotel',
        position: hotels.indexOf(hotel) + 1,
      })),
    },
  } as gtmGA3)
  pushEventToDataLayer('view_item_list', {
    channel: 'HOTEL',
    ecommerce: {
      items: hotels.map((hotel: HotelSearchResult) => ({
        item_name: hotel.vendorName,
        item_id: hotel.vendorId,
        item_brand: hotel.brand,
        item_category: hotel.category || 'Hotel',
        item_list_name: 'Search',
        item_list_id: 'S1',
        index: hotels.indexOf(hotel) + 1,
      })),
    },
  } as gtmGA4)
}

export const carHireResultsInitialLoad = (carHires: CarHireSearchOffer[]) => {
  pushEventToDataLayer(ga3ImpressionView, {
    ecommerce: {
      impressions: carHires.map((carHire: CarHireSearchOffer) => ({
        id: carHire.bundleId,
        name: carHire.name,
        variant: carHire.vehicleClassName,
        category: carHire.category || 'Car Hire',
        list: 'Search',
        position: carHires.indexOf(carHire) + 1,
      })),
    },
  } as gtmGA3)
  pushEventToDataLayer('view_item_list', {
    channel: 'CAR_HIRE',
    ecommerce: {
      items: carHires.map((carHire: CarHireSearchOffer) => ({
        item_name: carHire.name,
        item_variant: carHire.vehicleClassName,
        item_id: carHire.bundleId,
        price: carHire.cashPrice,
        item_brand: carHire.vendorName.toUpperCase(),
        item_category: carHire.category || 'Car Hire',
        item_list_name: 'Search',
        item_list_id: 'S1',
        index: carHires.indexOf(carHire) + 1,
        quantity: 1,
      })),
    },
  } as gtmGA4)
}

export const hotelResultsCardClick = (hotel: HotelSearchResult) => {
  pushEventToDataLayer(ga3ImpressionClick, {
    ecommerce: {
      click: {
        actionField: {
          list: 'Search',
        },
        products: [
          {
            avios_value: hotel.aviosPrice,
            collected_avios: hotel.collectAmount,
            id: hotel.vendorId,
            name: hotel.vendorName,
            category: hotel.category || 'Hotel',
            price: hotel.cashPrice.toString(),
          },
        ],
      },
    },
  } as gtmGA3)
  pushEventToDataLayer('select_item', {
    ecommerce: {
      items: [
        {
          avios_value: hotel.aviosPrice,
          collected_avios: hotel.collectAmount,
          item_name: hotel.vendorName,
          item_id: hotel.vendorId,
          price: hotel.cashPrice,
          item_brand: hotel.brand,
          item_category: hotel.category || 'Hotel',
          item_list_name: 'Search',
          item_list_id: 'S1',
        },
      ],
    },
  } as gtmGA4)
}

export const carHireResultsCardClick = (
  carHire: CarHireSearchOffer,
  position: number | undefined
) => {
  pushEventToDataLayer(ga3ImpressionClick, {
    ecommerce: {
      click: {
        actionField: {
          list: 'Search',
        },
        products: [
          {
            id: carHire.bundleId,
            name: carHire.name,
            variant: carHire.vehicleClassName,
            category: carHire.category || 'Car Hire',
            list: 'Search',
            avios_value: carHire.aviosPrice,
            collected_avios: carHire.collectAmount,
            position: position ? position + 1 : undefined,
          },
        ],
      },
    },
  } as gtmGA3)
  pushEventToDataLayer('select_item', {
    ecommerce: {
      items: [
        {
          item_name: carHire.name,
          item_variant: carHire.vehicleClassName,
          item_id: carHire.bundleId,
          price: carHire.cashPrice,
          avios_value: carHire.aviosPrice,
          collected_avios: carHire.collectAmount,
          item_brand: carHire.vendorName.toUpperCase(),
          item_category: carHire.category || 'Car Hire',
          item_list_name: 'Search',
          item_list_id: 'S1',
          index: position,
          quantity: 1,
        },
      ],
    },
  } as gtmGA4)
}

export const hotelAddToBasket = (hotel: HotelDetailsResult, room: Room, roomRate: string) => {
  const selectedRate = room.roomRates.find((rate) => rate.id === roomRate)
  if (selectedRate) {
    pushEventToDataLayer(ga3Add, {
      ecommerce: {
        position: hotel.rooms.indexOf(room) + 1,
        add: {
          actionField: {
            list: 'Search',
          },
          products: [
            {
              avios_value: selectedRate.aviosPrice,
              collected_avios: selectedRate.collectAmount,
              id: hotel.vendorId,
              name: hotel.vendorName,
              category: hotel.category || 'Hotel',
              brand: hotel.brand,
              price: selectedRate.cashPrice.toString(),
              variant: room.name,
              amenities: selectedRate.optionInfo,
              room_size: room.dimensions?.sqMeters,
              refundable: getIsRefundable(selectedRate.packageRules).toString(),
              bed_size: room.maxOccupants,
            },
          ],
        },
      },
    } as gtmGA3)
    pushEventToDataLayer('add_to_cart', {
      value: selectedRate.cashPrice,
      currency: selectedRate.currencyCode,
      ecommerce: {
        items: [
          {
            avios_value: selectedRate.aviosPrice,
            collected_avios: selectedRate.collectAmount,
            item_name: hotel.vendorName,
            item_id: hotel.vendorId,
            item_brand: hotel.brand,
            item_category: hotel.category || 'Hotel',
            price: selectedRate.cashPrice,
            item_variant: room.name,
            quantity: 1,
            amenities: selectedRate.optionInfo,
            room_size: room.dimensions?.sqMeters,
            refundable: getIsRefundable(selectedRate.packageRules).toString(),
            bed_size: room.maxOccupants,
          },
        ],
      },
    })
  }
}
export const carHireAddToBasket = (carHire: CarHireDetailsDataTransferObject) => {
  pushEventToDataLayer(ga3Add, {
    ecommerce: {
      add: {
        actionField: {
          list: 'Search',
        },
        products: [
          {
            id: carHire?.result?.bundleId,
            name: carHire?.result?.name,
            variant: carHire?.result?.vehicleClassName,
            category: 'Car Hire',
            brand: carHire?.result?.vendorName.toUpperCase(),
            quantity: '1',
            price: carHire?.result?.cashPrice.toString(),
            avios_value: carHire?.result?.aviosPrice,
            collected_avios: carHire?.result?.collectAmount,
            seats: carHire?.result?.seats,
            transmission: carHire?.result?.vehicleTransmission,
            mileage_allowance: '',
            door_count: carHire?.result?.doors,
            air_con: carHire?.result?.aircon,
            luggage_capacity: carHire?.result?.bags,
            insurance_cover: '',
            additional_driver: '',
            child_seat: '',
          },
        ],
      },
    },
  } as gtmGA3)
  pushEventToDataLayer('add_to_cart', {
    value: carHire?.result?.cashPrice,
    currency: carHire.result.currencyCode,
    ecommerce: {
      items: [
        {
          item_name: carHire?.result?.name,
          item_variant: carHire?.result?.vehicleClassName,
          item_id: carHire?.result?.bundleId,
          price: carHire?.result?.cashPrice,
          item_brand: carHire?.result?.vendorName.toUpperCase(),
          item_category: 'Car Hire',
          quantity: 1,
          avios_value: carHire?.result?.aviosPrice,
          collected_avios: carHire?.result?.collectAmount,
          seats: carHire?.result?.seats,
          transmission: carHire?.result?.vehicleTransmission,
          mileage_allowance: '',
          door_count: carHire?.result?.doors,
          air_con: carHire?.result?.aircon,
          luggage_capacity: carHire?.result?.bags,
          insurance_cover: '',
          additional_driver: '',
          child_seat: '',
        },
      ],
    },
  })
}

export const pushExperimentToDataLayer = (data: ExperimentFlag) => {
  let variantLabel = !data.value ? 'control' : 'variant'
  if (typeof data.value !== 'boolean' && !!data.value) {
    variantLabel += `-${data.value}`
  }

  compareAndPushEventInDataLayer('experiment', {
    experiment_id: data.key,
    experiment_name: data.name.toLowerCase(),
    experiment_variant: variantLabel,
  })
}

export const pushInitialFilterToDataLayer = (productType: ProductType) => {
  if (productType === ProductTypeSchema.enum.HOTEL) {
    pushEventToDataLayer('serp_filter', {
      property_type: '',
      guest_rating: 'any',
      star_rating: '',
      filter_state: 'initial',
    })
  }
  if (productType === ProductTypeSchema.enum.CAR_HIRE) {
    pushEventToDataLayer('serp_filter', {
      fuel_type: '',
      gearbox: 'any',
      vendor: 'any',
      budget_avios: 'any',
      budget_cash: 'any',
      size: '',
      filter_state: 'initial',
    })
  }
}

export const pushRefinedFilterToDataLayer = (
  productType: ProductType,
  selectedFilters: Record<string, string | string[]>,
  filters: FilterList
) => {
  const { guestRating, starRatings, fuelType, transmissionType, pointRange, priceRange, carSize } =
    selectedFilters

  switch (productType) {
    case ProductTypeSchema.enum.HOTEL:
      pushEventToDataLayer('serp_filter', {
        property_type: findPropertyTypeName(selectedFilters, filters),
        guest_rating: guestRating === '0' ? 'any' : (guestRating as string),
        star_rating: starRatings ? (starRatings as string[]).join(', ') : '',
        filter_state: 'refined',
      })
      break
    case ProductTypeSchema.enum.CAR_HIRE:
      pushEventToDataLayer('serp_filter', {
        fuel_type: fuelType ? (fuelType as string[]).join(', ') : '',
        gearbox: transmissionType ? (transmissionType as string) : 'any',
        vendor: findCarVendorName(selectedFilters, filters),
        budget_avios: (pointRange as string) ?? 'any',
        budget_cash: (priceRange as string) ?? 'any',
        size: carSize ? (carSize as string[]).join(', ') : '',
        filter_state: 'refined',
      })
      break
    default:
      break
  }
}

function findCarVendorName(
  selectedFilters: Record<string, string | string[]>,
  filters: FilterList
) {
  // get selected vendors as string
  const selectedVendorType: string | null =
    selectedFilters.carCompany === undefined || selectedFilters.carCompany === null
      ? null
      : (selectedFilters.carCompany as string)
  const vendorTypeNames: Filter | null = filters
    ? (filters.find(({ key }) => key === 'carCompany') as Filter)
    : null
  const selectedVendorTypeName: string =
    vendorTypeNames === null || selectedVendorType === null
      ? 'any'
      : (vendorTypeNames.options?.find(({ key }) => key === selectedVendorType) as FilterOptions)
          .name
  return selectedVendorTypeName
}

type Flight = {
  origin?: AirportLocation
  destination?: AirportLocation
  returnDate?: string
  departureDate: string
  oneWayOnly: boolean
  passengerCount: number
  adultCount: number
  childCount: number
  childrenAges: number[]
}

export const pushSearchEventData = (
  user: UserContextValue,
  partner: Partner,
  flight: Flight,
  allFlights: number
) => {
  const memberIdentifier = user.user?.memberIdentifier?.identifier
  const bookingCurrency = user.userCurrency
  const userBalance = user.account?.balance.amount
  const { origin, destination, departureDate, returnDate, passengerCount } = flight
  const data = {
    memberID: memberIdentifier,
    booking_currency: bookingCurrency,
    currency: bookingCurrency,
    balance: userBalance,
    domain_environment: isProduction() ? 'prod' : 'non_prod',
    programmeCode: partner.slug,
    channel: GTMChannels.FLIGHTS,
    pageLocation: '/flights',
    booking_start_location: `${origin?.cityName}, ${origin?.countryCode}`,
    booking_end_location: `${destination?.cityName}, ${destination?.countryCode}`,
    from_date: departureDate,
    to_date: returnDate,
    traveller_count: passengerCount,
    search_state: 'initial',
    result_count: allFlights,
  }

  pushEventToDataLayer('searchFlights', data)
}

export const pushSearchFlightEventData = (
  user: UserContextValue,
  partner: Partner,
  flight: Flight,
  allFlights: number
) => {
  const memberIdentifier = user.user?.memberIdentifier?.identifier
  const bookingCurrency = user.userCurrency
  const userBalance = user.account?.balance.amount
  const { adultCount, childCount, childrenAges, origin, destination, departureDate, returnDate } =
    flight

  const youngAdultsForAnalytics = childrenAges.filter((age) => age > 15)

  const adultCountForAnalytics = adultCount + youngAdultsForAnalytics.length
  const childCountForAnalytics = childCount - youngAdultsForAnalytics.length

  const updatedChildrenAgesForAnalytics = childrenAges.filter((age) => age <= 15)
  const childrenAgesNode = {
    childrenAges:
      childCount > 0
        ? updatedChildrenAgesForAnalytics.map((childAge, index) => ({
            child: `{child_${index + 1}}`,
            age: childAge,
          }))
        : [],
  }

  const data = {
    memberID: memberIdentifier,
    booking_currency: bookingCurrency,
    currency: bookingCurrency,
    balance: userBalance,
    domain_environment: isProduction() ? 'prod' : 'non_prod',
    programmeCode: partner.slug,
    channel: GTMChannels.FLIGHTS,
    pageLocation: '/flights',
    booking_start_location: `${origin?.cityName}, ${origin?.countryCode}`,
    booking_end_location: `${destination?.cityName}, ${destination?.countryCode}`,
    departure_date: departureDate,
    arrival_date: returnDate,
    flightsNumber: allFlights || 0, // if {0} means no results
    adults: adultCountForAnalytics,
    children: childCountForAnalytics,
    traveller_count: adultCountForAnalytics + childCountForAnalytics,
    ...childrenAgesNode,
  }

  pushEventToDataLayer('searchFlights', data)
}

function findPropertyTypeName(
  selectedFilters: Record<string, string | string[]>,
  filters: FilterList
) {
  // get selected properties as string
  const selectedPropertyTypes: string[] = selectedFilters.propertyTypes
    ? (selectedFilters.propertyTypes as string[])
    : []
  const propertyTypeNames: Filter | null = filters
    ? (filters.find(({ key }) => key === 'propertyTypes') as Filter)
    : null
  const names =
    propertyTypeNames === null
      ? ([] as string[])
      : selectedPropertyTypes.map(
          (selectedElement) =>
            (propertyTypeNames.options?.find(({ key }) => key === selectedElement) as FilterOptions)
              .name
        )
  return names.join(', ')
}
export const gtmSearchSubmit = (searchType: string) =>
  pushEventToDataLayer('navigation', { nav_type: 'cta', nav_action: `search ${searchType}` })

export const refineSearchSessionStorage = (routerPathname: string) => {
  switch (routerPathname) {
    case '/hotels/results':
      sessionStorage.setItem('clicked_back_hotels', 'true')
      break
    case '/cars/results':
      sessionStorage.setItem('clicked_back_car_hire', 'true')
      break
    default:
  }
}

export const flightPageDetails = (
  router: NextRouter,
  user: UserContextValue,
  partner: Partner,
  flightDetails?: { origin?: AirportLocation; destination?: AirportLocation }
) => {
  const bookingCurrency = user.userCurrency
  const memberIdentifier = user.user?.memberIdentifier?.identifier
  const userBalance = user.account?.balance.amount

  const origin = flightDetails?.origin
    ? formatAirportName(flightDetails.origin)
    : router.query.fromAirportCode

  const destination = flightDetails?.destination
    ? formatAirportName(flightDetails.destination)
    : router.query.toAirportCode

  return {
    memberID: memberIdentifier,
    booking_currency: bookingCurrency,
    currency: bookingCurrency,
    balance: userBalance,
    domain_env: isProduction() ? 'prod' : 'non_prod',
    programmeCode: partner.slug,
    page_location: window.location.href,
    channel: GTMChannels.FLIGHTS,
    origin,
    destination,
    outbound_date: router.query.departureDate as string,
    return_date: router.query.returnDate as string,
    referrer: router.route,
  }
}

const buildAnalyticsPromotionObject = (promotion: Promotion | undefined) => {
  if (!promotion) {
    return null
  }
  return {
    promoCode: promotion.data.id,
    promoName: promotion.content?.name ?? promotion.data.id,
    promoType: promotion.data.type,
    promoProduct: promotion.data.product,
  }
}

export const promotionAnalyticsDetails = (promotionMap: PromotionProductMap) => {
  Object.keys(promotionMap).forEach((key) => {
    const promotionAnalyticsObject = buildAnalyticsPromotionObject(promotionMap[key as ProductType])
    if (promotionAnalyticsObject) {
      pushEventToDataLayer('promotion', promotionAnalyticsObject as PromotionLayerObject)
    }
  })
}

export const sendHotelUpgradeComponentCtaEvent = (action: string) => {
  compareAndPushEventInDataLayer('navigation', {
    nav_element: 'confirm upgrade',
    nav_type: 'cta',
    nav_action: action,
  } as Navigation)
}

export const sendHotelUpgradeComponentOptionsEvent = (
  options: Option[],
  isNonRefundable: boolean,
  isBreakfastNotAvailable: boolean
) => {
  const breakfastAndLastMinuteOption = options.find(
    (option) => option.value === 'breakfastAndLastMinute'
  )
  const optionData = {
    nav_element: 'upgrade options',
    nav_type: 'banner',
    nav_action: '3 all upgrade options available',
  } as Navigation
  if (isNonRefundable && isBreakfastNotAvailable) {
    const breakfastOption = options.find((option) => option.value === 'breakfast')
    const lastMinuteOption = options.find((option) => option.value === 'lastMinute')

    if (breakfastAndLastMinuteOption) {
      optionData.nav_action = '3 all upgrade options available'
    } else if (lastMinuteOption) {
      optionData.nav_action = '2 last min cancellation upgrade available'
    } else if (breakfastOption) {
      optionData.nav_action = '1 breakfast upgrade available'
    }
    compareAndPushEventInDataLayer('navigation', optionData)
  } else if (isNonRefundable) {
    optionData.nav_action = '2 last min cancellation upgrade available'
    compareAndPushEventInDataLayer('navigation', optionData)
  } else if (isBreakfastNotAvailable) {
    optionData.nav_action = '1 breakfast upgrade available'
    compareAndPushEventInDataLayer('navigation', optionData)
  }
}
