import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'carCardWrapper',
  'carResultsMainWrapper',
  'imageWrapper',
  'vendorLogo',
  'heading',
  'name',
  'mainAttributeList',
  'secondaryAttributeList',
  'attributeListItem',
  'footerContainer',
  'policiesContainer',
  'cancellationButton',
  'chooseButton',
  'policyLink',
  'duration',
  'durationAndPaymentContainer',
  'cancellationFeesAndPoliciesContainer',
  'secondaryAttributeWrapper',
  'secondaryAttributeIcon',
  'secondaryAttributeHeading',
  'fuelBadge',
  'electricBagde',
  'hybridBadge',
])

export const CarCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    carCardWrapper: {
      display: 'grid',
      gridTemplate: {
        base: `'image' 'content' 'pricing' 'footer'`,
        md: `'image content pricing' auto
        'image footer footer' auto / 30% 1fr max-content`,
      },
      borderRadius: 'lg',
      padding: { base: 4, md: 6 },
      gridGap: { base: 4, md: 6 },
      mb: '10',
      position: 'relative',
    },
    imageWrapper: {
      gridArea: 'image',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: { base: 4, md: 'unset' },
    },
    vendorLogo: {
      height: 4,
      marginTop: 4,
      objectFit: 'contain',
    },
    carResultsMainWrapper: {
      gridArea: 'content',
      color: 'inherit',

      '> * + *': {
        marginTop: 6,
      },
    },
    heading: {
      lineHeight: 'normal',
      marginBottom: 1,
    },
    name: {
      marginTop: 0,
      fontSize: 'xs',
    },
    mainAttributeList: {
      columns: '2',
      padding: '0',
    },
    attributeListItem: {
      display: 'flex',
      marginBottom: 4,
      fontSize: 'sm',
      lineHeight: '5',
      fontWeight: 'normal',
    },
    secondaryAttributeList: {
      '> :last-child': {
        margin: 0,
      },
      padding: 0,
      gridArea: 'attributes',
      marginTop: 2,
    },
    durationAndPaymentContainer: {
      rowGap: '1em',
    },
    duration: {
      fontSize: 'sm',
      fontWeight: 'normal',
      whiteSpace: 'nowrap',
    },
    footerContainer: {
      gridArea: 'footer',
      justifyContent: { base: 'space-between', md: 'flex-end' },
    },
    cancellationButton: {
      textAlign: 'left',
      padding: '1px 0',
      _hover: {
        textDecoration: 'none',
      },
    },
    policiesContainer: {
      flexDirection: 'column',
      marginTop: 2,
    },
    policyLink: {
      marginBottom: 2,
    },
    secondaryAttributeWrapper: {
      gridTemplate: `'icon heading' auto
        'icon attributes' auto / max-content max-content`,
      backgroundColor: 'grey',
      padding: 4,
      borderRadius: 'lg',
    },

    secondaryAttributeIcon: {
      gridArea: 'icon',
    },

    secondaryAttributeHeading: {
      gridArea: 'heading',
      fontSize: 'sm',
      fontWeight: 'semibold',
      lineHeight: '5',
    },

    fuelBadge: {
      px: 2,
      py: 1,
      borderRadius: 2,
      ' > div': {
        columnGap: 2,
      },
    },
  },

  variants: {
    results: {},
    details: {
      carCardWrapper: {
        gridTemplate: {
          base: `'image' 'content' 'pricing' 'footer'`,
          md: `'image content pricing' auto
          'image content footer' auto / 30% 1fr auto`,
        },
      },
      durationAndPaymentContainer: {
        '& > span': {
          textAlign: { base: 'left', md: 'right' },
        },
      },
      cancellationButton: {
        whiteSpace: 'break-space',
        textAlign: 'right',
      },
      attributeListItem: {
        marginBottom: 1,
      },
    },
  },
})
