import { Global } from '@emotion/react'
import { Partner } from '~/services/partnerService'
import britishAirwaysFontFaceStr from './britishAirways'
import { aerLingusFontFaceStr } from './fonts.css'

const fontsMap: Record<Partner['theme'], string> = {
  aer_lingus: aerLingusFontFaceStr,
  vueling: '',
  british_airways: britishAirwaysFontFaceStr,
}

type EmotionFontsProps = {
  partner: Partner['theme']
}
const EmotionFonts = ({ partner }: EmotionFontsProps) => <Global styles={`${fontsMap[partner]}`} />

export default EmotionFonts
