import { FC, ReactElement, useMemo } from 'react'
import {
  DevCycleProvider as DevCycleSDKProvider,
  useIsDevCycleInitialized,
} from '@devcycle/react-client-sdk'
import { Partner } from '~/services/partnerService'
import { getDefaultDevCycleUser } from './getDefaultDevCycleUser'
import { useIdentifyDevCycleUser } from './useIdentifyDevCycleUser'
import { useUserQuery } from '../user'

type CallbackArgs = {
  isInitialized: boolean
}
type ChildrenCallback = (args: CallbackArgs) => ReactElement
type ConsumerProps = {
  children: ReactElement | ChildrenCallback
}
type ProviderProps = ConsumerProps & {
  partner: Partner
}

const DevCycleConsumer: FC<ConsumerProps> = ({ children }) => {
  const isInitialized = useIsDevCycleInitialized()

  return typeof children === 'function' ? children({ isInitialized }) : children
}

const DevCycleProvider: FC<ProviderProps> = ({ children, partner }) => {
  const dvcConfig = useMemo(() => {
    const user = getDefaultDevCycleUser(partner)
    return {
      envKey: process.env.NEXT_PUBLIC_DEVCYCLE_CLIENT_KEY ?? '',
      user,
    }
  }, [partner])

  return (
    <DevCycleSDKProvider config={dvcConfig}>
      <DevCycleConsumer>{children}</DevCycleConsumer>
    </DevCycleSDKProvider>
  )
}

export const IdentifyDevCycleUser = () => {
  const { data } = useUserQuery()
  const { memberIdentifier, person } = data || {}

  // Sets DevCycle user identity from user data
  const dvcUser = useMemo(
    () =>
      memberIdentifier
        ? {
            user_id: memberIdentifier.identifier,
            email: person?.emailAddress?.email,
            language: person?.locale,
            country: person?.countryOfResidence.identifier,
          }
        : null,
    [memberIdentifier, person]
  )

  useIdentifyDevCycleUser(dvcUser)

  return null
}

export default DevCycleProvider
