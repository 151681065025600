import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'navList',
  'navLink',
  'navItem',
  'navBar',
  'navItemReviews',
  'navLinkReviews',
  'experimentBorder',
])

export const SectionNavigation = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    navList: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      overflow: 'auto',
      // below browser hacks may not be required in future
      msOverflowStyle: 'none' /* IE and Edge */,
      scrollbarWidth: 'none' /* Firefox */,
      '::-webkit-scrollbar': {
        display: 'none' /* Chrome, Safari and Opera */,
      },
      px: '6',
    },
    navLink: {
      fontSize: 'sm',
      fontWeight: 'normal',
      textDecoration: 'none',
      whiteSpace: 'nowrap',
      color: 'red',

      _active: {
        fontWeight: 'semibold',
        color: 'purple',
      },
    },
    navItem: {
      '& + &': {
        paddingLeft: '6',

        '&::before': {
          content: '""',
          height: '1.333em',
          marginRight: 6,
          borderRight: '1px solid purple',
        },
      },
    },
  }),
})
