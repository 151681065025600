import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const zIndex = 30
const flexStart = 'flex-start'
const flexEnd = 'flex-end'
const pricingEasing = 'cubic-bezier(0.22, 0.61, 0.36, 1)'
const pricingListTransitionIn = `0.2s opacity ${pricingEasing}, 0.2s visibility step-start`

export const pricingOptionsHelpers = createMultiStyleConfigHelpers([])

export const PricingOptions = pricingOptionsHelpers.defineMultiStyleConfig({})

export const pricingHelpers = createMultiStyleConfigHelpers([
  'container',
  'headerContainer',
  'headerNote',
  'headerLabel',
  'priceContainer',
  'price',
  'priceNote',
  'originalPrice',
  'expandButton',
  'pricingOptionsContainer',
  'pricingOptionsBefore',
  'pricingOptionsList',
  'columnAlignment',
  'rowAlignment',
])

export const Pricing = pricingHelpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      paddingX: 6,
      paddingTop: 4,
      paddingBottom: 0,
      marginX: -6,
      marginTop: -4,
      marginBottom: 0,
      background: 'transparent',
      transition: '0.2s z-index step-end',
    },
    headerContainer: {
      position: 'relative',
      zIndex,
      display: 'flex',
      flexDirection: 'column',
      div: {
        fontSize: 'sm',
      },
    },
    headerNote: {
      position: 'relative',
      top: 1,
    },
    headerLabel: {
      fontWeight: 'normal',
      fontSize: 'sm',
      marginY: 1,
    },
    priceContainer: {
      display: 'flex',
      alignItems: flexStart,
      position: 'relative',
      zIndex,
    },
    price: {
      display: 'block',
    },
    priceNote: {
      display: 'block',
      fontSize: 'xs',
      lineHeight: 1,
      textWrap: 'nowrap',
    },
    expandButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      right: -5,
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      borderRadius: '50%',
      width: 4,
      height: 4,
      padding: 0,
      margin: 2,
      marginRight: 0,
      svg: {
        width: 2,
        height: 2,
      },
    },
    pricingOptionsContainer: {
      position: 'absolute',
      paddingY: 4,
      marginX: 0,
      marginY: 0,
      zIndex: zIndex - 10,
      width: '200px',
      transition: `${pricingListTransitionIn}`,
      pointerEvents: 'none',
    },
    pricingOptionsBefore: {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      background: 'inherit',
    },
    pricingOptionsList: {
      position: 'relative',
      zIndex,
      lineHeight: 1.5,
      fontSize: 'sm',
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },
  },
  variants: {
    left: {
      rowAlignment: {
        justifyContent: flexStart,
      },
      columnAlignment: {
        alignItems: flexStart,
      },
    },
    right: {
      rowAlignment: {
        justifyContent: flexEnd,
      },
      columnAlignment: {
        alignItems: flexEnd,
      },
      expandButton: {
        position: 'absolute',
      },
    },
  },
  sizes: {
    large: {},
    small: {},
  },
})
