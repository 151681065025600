import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'container',
  'content',
  'cards',
  'heading',
  'caption',
])

export const RecommendedSection = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      '--recommended-image-min-size': '10rem',
      '--recommended-image-max-size': '18.75rem',
      display: { base: 'block', md: 'grid' },
      gridTemplateAreas: { md: `'content' 'images'` },
      gridTemplateColumns: { md: '1fr' },
      gridTemplateRows: { md: 'auto 1fr' },
      gap: '2rem 0px',
      marginBottom: { base: 12, md: 24 },
      marginTop: { base: '0', md: 3 },
      paddingX: 2,
    },
    content: {
      gridArea: 'content',
      marginBottom: { base: 6, md: 0 },
      h3: {
        fontSize: '1.75rem',
        fontWeight: 'medium',
      },
      'p + p': {
        marginTop: 4,
      },
    },
    cards: {
      gridArea: 'images',
      overflow: 'auto',
      display: { base: 'block', md: 'grid' },
      gridAutoFlow: { base: 'row', md: 'column' },
      gridGap: { base: 'var(--layout-gutter)', md: 6 },
      gridAutoColumns: {
        base: 'fit-content(80vw)',
        sm: 'minmax(var(--recommended-image-min-size), var(--recommended-image-max-size))',
      },

      '::-webkit-scrollbar': {
        display: { base: 'none' },
      },
      '> *': {
        marginBottom: { base: 6, md: 0 },
      },
      '> figure': {
        width: '100%',
      },
    },
    heading: {
      fontSize: 'lg',
      fontWeight: 'semibold',
      margin: '1.5rem 0 0 0',
    },
    subHeading: {
      fontSize: 'lg',
      fontWeight: 'medium',
      margin: '0 0 0.75rem 0',
    },
  },
})
