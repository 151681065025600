import React from 'react'
import Link from 'next/link'
import {
  chakra,
  fadeConfig,
  Box,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  useMultiStyleConfig,
  VisuallyHidden,
  Button,
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { convertIconSetToChakraSVG } from '~/utils/Icon.utils'
import { IconName } from '@reward-platform/ancillaries-schemas/common'
import { IconSizes } from '@reward-platform/lift/theme/iconSize'
import { pushEventToDataLayer } from '~/utils/googleTagManager'
import { useIsAuthenticatedUser } from '~/context/user'
import HeaderMenuItem, { MenuOption } from './HeaderMenuItem'

export type HeaderMenuProps = {
  children: MenuOption[]
}

const HeaderMenu = ({ children }: HeaderMenuProps): JSX.Element => {
  const styles = useMultiStyleConfig('Menu', { variant: 'header' })

  const userIsAuthenticated = useIsAuthenticatedUser()

  if (!userIsAuthenticated && children.length === 1) {
    const { href, label, opensInNewTab } = children[0]
    return (
      <Link href={href} target={opensInNewTab ? '_blank' : undefined} passHref>
        <Button variant="primary" sx={styles.headerButton}>
          <Icon as={convertIconSetToChakraSVG(IconName.Enum.login)} boxSize={IconSizes.lg} />
          <chakra.span mr="auto">{label}</chakra.span>
          {opensInNewTab && (
            <Icon
              as={convertIconSetToChakraSVG(IconName.Enum['external-link'])}
              boxSize={IconSizes.xxs}
            />
          )}
        </Button>
      </Link>
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const motionProps: any = fadeConfig

  return (
    <Menu variant="header">
      {({ isOpen }) => (
        <>
          {isOpen && <Box as={motion.div} {...motionProps} __css={styles.overlay} cursor="auto" />}
          <MenuButton>
            <VisuallyHidden>Toggle menu</VisuallyHidden>
            <Icon
              as={convertIconSetToChakraSVG(IconName.Enum.hamburger)}
              boxSize={IconSizes.sm}
              color="currentColor"
            />
          </MenuButton>
          <MenuList>
            {children.map((menuOption, i) => (
              <React.Fragment key={menuOption.label}>
                {i > 0 && <MenuDivider />}
                <HeaderMenuItem
                  onClick={() => {
                    return pushEventToDataLayer('navigation', {
                      nav_element: 'header navigation',
                      nav_type: 'cta',
                      nav_action: `header ${menuOption.label.toLowerCase()}`,
                      menu_level: `${i + 1}`,
                    })
                  }}
                >
                  {menuOption}
                </HeaderMenuItem>
              </React.Fragment>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  )
}

export default HeaderMenu
