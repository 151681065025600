export const LOGIN_PATH = '/auth/login'
export const LOGOUT_PATH = '/auth/logout'
export const CALLBACK_PATH = '/auth/callback'
export const LOGIN_ERROR_PATH = '/auth/error'
export const GENERAL_ERROR_PATH = '/error'
export const PAYMENT_COMPLETE_PATH = '/orders/payment-complete'
export const TERMS_AND_CONDITIONS_PATH = '/terms-and-conditions'
export const LANDING_PAGE_PATH = '/'
export const HOTEL_RESULTS_PATH = '/hotels/results'
export const HOTEL_DETAILS_PATH = '/hotels/details'
export const SHARE_URL_PATH = '/s/[slug]'
