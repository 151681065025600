import { SearchTabQuery } from '@reward-platform/shared-schemas'
import { BasketItem, isFlightBasketItem } from '@reward-platform/order-schemas/basket'
import { OrderItem } from '@reward-platform/order-schemas/order'

import { ProductType, PromotionValidationItem } from '~/context/promotion'
import { SelectedPaymentOptions } from '@reward-platform/order-schemas/form-data'

export const mapSearchTabToPromotionProduct = (
  product: SearchTabQuery
): ProductType | undefined => {
  switch (product) {
    case 'hotels':
      return 'HOTEL'
    case 'carhire':
      return 'CAR_HIRE'
    case 'experiences':
      return 'EXPERIENCE'
    case 'flights':
      return 'FLIGHT'
    default:
      return undefined
  }
}

export const mapBasketItemToPromotionItem = (
  item?: BasketItem,
  selectedPaymentOption?: SelectedPaymentOptions['HOTEL']
): PromotionValidationItem | undefined => {
  if (!item) {
    return item
  }

  const { startDate, paymentOptions } = item
  const endDate = !isFlightBasketItem(item) ? item.endDate : startDate
  let currencyCode
  let cashAmount
  let aviosAmount

  if (selectedPaymentOption) {
    const { paymentOptionId } = selectedPaymentOption
    const selectedPaymentOptions = paymentOptions.options.find(
      (paymentOption) => paymentOption.id === paymentOptionId
    )
    currencyCode = selectedPaymentOptions?.cashValue
      ?.currency as PromotionValidationItem['currencyCode']
    cashAmount = selectedPaymentOptions?.cashValue?.amount ?? 0
    aviosAmount = selectedPaymentOptions?.aviosValue ? 1 : 0
  } else {
    currencyCode = paymentOptions.onlyCashPaymentOption?.cashTotalValue
      ?.currency as PromotionValidationItem['currencyCode']
    cashAmount = paymentOptions.onlyCashPaymentOption?.cashTotalValue?.amount ?? 0
    aviosAmount = paymentOptions.onlyCashPaymentOption ? 0 : 1
  }

  return {
    currencyCode,
    cashAmount,
    aviosAmount,
    startDate,
    endDate,
  }
}

export const mapOrderItemToPromotionItem = (
  item?: OrderItem
): PromotionValidationItem | undefined => {
  if (!item) {
    return item
  }

  if (item.type === 'FLIGHT' || item.type === 'EXPERIENCE') {
    return undefined
  }

  const { orderPriceBreakdown, endDate } = item
  const currencyCode = orderPriceBreakdown?.totalCashIncludingTax
    ?.currency as PromotionValidationItem['currencyCode']
  const cashAmount = orderPriceBreakdown?.totalCashIncludingTax?.amount ?? 0
  const aviosAmount = orderPriceBreakdown?.aviosSpent

  return {
    currencyCode,
    cashAmount,
    aviosAmount,
    endDate,
  }
}
