import { ZodIssue, z } from 'zod'

export const BffErrorResponseSchema = z.object({
  code: z.string(),
  businessMessage: z.string(),
  developerMessage: z.string(),
  validationErrors: z
    .any()
    .transform((x) => x as ZodIssue[])
    .optional(),
  unrecoverable: z.boolean(),
})
