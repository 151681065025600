import { Box, useMultiStyleConfig } from '@chakra-ui/react'

export type ChakraDefinitionGroupProps = {
  id?: string
  term?: string | JSX.Element
  definition?: string
  variant?: string
}

export const ChakraDefinitionGroup = ({
  id,
  term,
  definition,
  variant,
}: ChakraDefinitionGroupProps) => {
  const style = useMultiStyleConfig('ChakraDefinitionGroup', { variant })
  return (
    <Box __css={style.chakraDefinitionGroupWrapper}>
      <dl>
        <dt>{term}</dt>
        <dd>{definition}</dd>
      </dl>
    </Box>
  )
}
export default ChakraDefinitionGroup
