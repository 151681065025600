import { useRouter } from 'next/router'
import { usePathname, useSearchParams } from 'next/navigation'
import {
  useCheckoutAnalyticsChannel,
  getCheckoutAnalyticsChannel,
} from '~/hooks/useCheckoutAnalyticsChannel/useCheckoutAnalyticsChannel'
import useOrder from '~/hooks/useOrder/useOrder'

export const useChannel = () => {
  const router = useRouter()
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const bookingId = searchParams && searchParams.get('bookingId')?.toString()
  const {
    orderData: { data: order },
  } = useOrder(bookingId)

  const checkoutRoutes = [
    '/orders/payment-options',
    '/orders/traveler-details',
    '/orders/additional-details',
    '/orders/payment',
    '/orders/boooking-status',
  ]
  const isCheckout = checkoutRoutes.includes(router.pathname)
  const isViewOrderPage = pathname && pathname.includes('view-booking')

  const checkoutChannel = useCheckoutAnalyticsChannel({ isEnabled: isCheckout })
  const viewOrderChannel = getCheckoutAnalyticsChannel(order?.items)

  if (isViewOrderPage) {
    return viewOrderChannel
  }

  if (isCheckout) {
    return checkoutChannel
  }

  const isFlights = router.pathname.startsWith('/flights')

  if (isFlights) {
    return 'flights'
  }

  const isOrderHistory = router.pathname.startsWith('/order-history')
  if (isOrderHistory) {
    return ''
  }

  return ((router.query.searchType ?? router.query.tab) as string) ?? undefined
}
