import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'wrapper',
  'heading',
  'duration',
  'description',
  'payment',
  'footer',
  'iconWrapper',
  'button',
])

export const CarAdditionalProductCard = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    wrapper: {
      maxWidth: { lg: '650px' },
      borderRadius: 'lg',

      '.chakra-card': {
        display: 'grid',
        padding: 6,
        height: 'full',
        gridTemplate: {
          base: `
                'heading duration' auto
                'description description' auto
                'payment payment' auto
                'footer footer' auto
                / 1fr max-content`,
          lg: `
                'heading duration' auto
                'description payment' auto
                'footer footer' auto
                / 1fr 1fr`,
        },
        rowGap: 3.5,
        columnGap: 8,
        minHeight: '3xs',
      },
    },

    heading: {
      color: 'black',
      fontSize: { base: 'md', lg: 'lg' },
      lineHeight: { base: '4', lg: '5' },
      fontWeight: 500,
      margin: 0,
    },

    duration: {
      gridArea: 'duration',
      color: 'grey',
      fontSize: 'xs',
      fontWeight: 400,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },

    description: {
      gridArea: 'description',
      marginTop: 0,
      color: 'black',
      fontSize: 'sm',
      fontWeight: 400,
      lineHeight: '5',
    },

    payment: {
      justifyContent: 'flex-end',
    },

    footer: {
      gridArea: 'footer',
      gridTemplate: `
            'confirmation action' auto
            / 1fr 1fr`,
    },

    iconWrapper: {
      width: 6,
      height: 6,
      background: 'black',
      padding: 1,
      borderRadius: '50%',
      color: 'white',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 2,
      fontSize: { base: 'sm', lg: 'md' },
      fontWeight: 500,
    },

    button: {
      width: { base: 'full', lg: '120px' },
      height: '32px',
      fontSize: 'sm',
    },

    picker: {
      '& > div': {
        alignItems: 'center',
      },
    },
  }),
  variants: {
    modal: {
      wrapper: {
        maxWidth: { lg: '850px' },
        '.chakra-card': {
          padding: 8,
          rowGap: 6,
          columnGap: 16,
          minHeight: '3xs',
        },
      },
      heading: {
        fontSize: { base: '3xl', lg: '4xl' },
        lineHeight: { base: '9', lg: '48px' },
      },
      description: {
        marginBottom: 4,
      },
      button: {
        width: 'full',
        height: '55px',
        fontSize: 'md',
      },
    },
  },
})
