import {
  Basket,
  BasketSchema,
  CarHireBasketOffer,
  HotelBasketOffer,
  ExperienceBasketOffer,
  FlightBasketOffer,
} from '@reward-platform/order-schemas/basket'
import { createServiceSchemaValidator } from './utils'
import { bffClient, hasCountryHeader, hasCurrencyHeader } from './clients/bffClient'
import { PlatformError } from '../utils/errors'

const validateBasket = createServiceSchemaValidator(BasketSchema)

export const getBasket = async (resolveLocations = false): Promise<Basket> => {
  if (!hasCurrencyHeader() || !hasCountryHeader()) {
    return Promise.reject(new PlatformError(`The request headers are not properly set.`))
  }

  const urlSearchParams = new URLSearchParams()

  if (resolveLocations) {
    urlSearchParams.append('resolveLocations', resolveLocations.toString())
  }

  const query = urlSearchParams.toString()
  const queryString = query.length ? `?${query}` : ''

  const { data } = await bffClient.get(`/basket${queryString}`)
  return validateBasket(data)
}

export const addHotelToBasket = async (offer: HotelBasketOffer) => {
  await bffClient.post(`/basket/hotel`, offer)
}

export const addCarHireToBasket = async (offer: CarHireBasketOffer) => {
  await bffClient.post(`/basket/car-hire`, offer)
}

export const addExperienceToBasket = async (offer: ExperienceBasketOffer) => {
  await bffClient.post(`/basket/experience`, offer)
}

export const addFlightToBasket = async (offer: FlightBasketOffer) => {
  await bffClient.post(`/basket/flight`, offer)
}

export const removeItemFromBasket = async (recommendationId: string) => {
  await bffClient.delete(`/basket/items/${recommendationId}`)
}

export const clearItemsFromBasket = async () => {
  await bffClient.delete(`/basket/items`)
}
