import React, { useContext } from 'react'
import { PlatformError } from '~/utils/errors'
import { Partner } from '~/services/partnerService'

const PartnerContext = React.createContext<Partner | undefined>(undefined)

export const usePartner = () => {
  const partner = useContext(PartnerContext)

  if (!partner) {
    throw new PlatformError('usePartner must be used within PartnerProvider')
  }

  return partner
}

export const PartnerProvider = ({
  children,
  partner,
}: React.PropsWithChildren<{ partner: Partner }>) => (
  <PartnerContext.Provider value={partner}>{children}</PartnerContext.Provider>
)
