import { ComponentStyleConfig } from '@chakra-ui/react'
import merge from 'lodash/merge'
import { ActivityMenu as base } from './base'

const coolgrey10 = 'brand.coolgrey10'

export const ActivityMenu: ComponentStyleConfig = merge({}, base, {
  baseStyle: {
    container: {},
    heading: {},
    title: {},
    type: {},
    body: {
      color: coolgrey10,
    },
  },
})
