import { ComponentSingleStyleConfig } from '@chakra-ui/react'

export const Title: ComponentSingleStyleConfig = {
  baseStyle: {
    mt: 6,
    mb: 6,
    h1: {
      lineHeight: 1.25,
    },
  },
}
