/* eslint-disable sonarjs/no-duplicate-string */
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helper = createMultiStyleConfigHelpers([
  'orderListTableGrid',
  'orderListTableItemGrid',
  'textBold',
  'aviosCollected',
  'accented',
  'lineBalance',
  'lineName',
  'lighter',
  'lineDetails',
])

export const OrderListTable = helper.defineMultiStyleConfig({
  baseStyle: helper.definePartsStyle({
    orderListTableGrid: {
      boxShadow: '0 2px 4px  rgba(0, 0, 0, 0.1)',
    },
    aviosCollected: {
      'p:first-of-type': {
        button: {
          width: 'auto',
          height: 'auto',
          ml: { base: '2px', md: 0 },
          svg: {
            w: 'auto',
          },
        },
      },
    },
  }),
})
