/* eslint-disable no-console */
import { IncomingHttpHeaders } from 'http'
import { z } from 'zod'
import { ThemeMap } from '~/themes/chakra'
import { PlatformError } from '../utils/errors'

type Iata = 'VY' | 'EI' | 'BA'
type Domain = 'aerlingus' | 'vueling' | 'britishairways'

export interface Partner {
  code: Iata
  slug: string
  provider: string
  name: string
  domain: Domain
  whitelist: string[]
  theme: keyof ThemeMap
}

const parseWhitelist = (listString?: string): string[] =>
  z
    .string()
    // TODO: Make mandatory, remove the default value, and add a minimum length
    .optional()
    .default('')
    .parse(listString)
    .split(',')
    .map((domain) => domain.trim())

export const partners: Partner[] = [
  {
    code: 'VY',
    slug: 'VUELING',
    provider: 'RP_VY',
    name: 'Vueling',
    domain: 'vueling',
    whitelist: [''],
    theme: 'vueling',
  },
  {
    code: 'EI',
    slug: 'AER_LINGUS',
    provider: 'RP_EI',
    name: 'Aer Lingus',
    domain: 'aerlingus',
    whitelist: parseWhitelist(process.env.NEXT_PUBLIC_EI_DOMAIN_WHITELIST),
    theme: 'aer_lingus',
  },
  {
    code: 'BA',
    slug: 'BRITISH_AIRWAYS',
    provider: 'RP_BA',
    name: 'British Airways',
    domain: 'britishairways',
    whitelist: parseWhitelist(process.env.NEXT_PUBLIC_BA_DOMAIN_WHITELIST),
    theme: 'british_airways',
  },
]

export const getPartner = (headers?: IncomingHttpHeaders) => {
  const host = headers?.host ?? window?.location?.host
  if (!host) {
    const hostError = new PlatformError('No host found')
    console.error(JSON.stringify(hostError))
    throw hostError
  }

  const maybePartner = partners.find((partner) => partner.whitelist.includes(host))
  if (!maybePartner) {
    const partnerError = new PlatformError('No partner found')
    console.error(JSON.stringify(partnerError))
    throw partnerError
  }

  return maybePartner
}
